import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { MatDialog } from "@angular/material";
import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";
import { SharedServiceService } from "../../services/shared-service.service";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-search-result-popup",
  templateUrl: "./search-result-popup.component.html",
  styleUrls: ["./search-result-popup.component.scss"],
})
export class SearchResultPopupComponent implements OnInit {
  allAnswers = [];
  error: any;
  query: any;
  collection: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private apiService: ApiService,
    private router: Router,
    public sharedServiceService: SharedServiceService,
    private notifyService: NotificationService
  ) {}

  ngOnInit() {
    this.allAnswers = this.data.allAnswers;
    this.query = this.data.query;
    this.collection = this.data.collection;
  }

  highlightedText(context: string, answer: string) {
    if (!answer && !context) {
      return context;
    }
    return context.replace(new RegExp(answer, "gi"), (match) => {
      return (
        '<span class="highlightedAnswer font-weight-bold">' + match + "</span>"
      );
    });
  }

  gotToParagraph(aid, aname, pid) {
    if (aid) {
      this.apiService.getArticleID(aid).subscribe(
        async (data: any) => {
          if (data) {
            this.closePopup();
            if (aname) {
              this.router.navigate(["/article", aid, aname]);
            }
            if (pid) {
              this.sharedServiceService.scrollSectionId = pid;
            }
          }
        },
        (error) => {
          this.error = error;
          this.notifyService.showError("Article Not found.");
        }
      );
    } else {
      this.notifyService.showError("Article Not found.");
    }
  }

  closePopup() {
    this.dialog.closeAll();
  }
}
