import { Component, OnInit, AfterViewInit, ViewEncapsulation, } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { from } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { HttpParams, HttpClient } from "@angular/common/http";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'add-article-page',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit {

  fileToUpload: File = null;
  submitBtn: boolean = false;
  error: any;

  constructor(private apiService: ApiService,
    public dialog: MatDialog,
    public sharedServiceService: SharedServiceService,
    private spinner: NgxSpinnerService,
    private routes: Router,
    private notifyService: NotificationService) {
  }

  ngOnInit() {
  }

  handleFileInput(files: FileList) {
    this.submitBtn = false;
    this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
    const formData: FormData = new FormData();
    this.spinner.show();
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.type === "application/pdf" || this.fileToUpload.type === "application/epub+zip" || this.fileToUpload.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      if (this.fileToUpload.type === "application/pdf") {
        formData.append('pdf', this.fileToUpload);
        this.apiService.convertPdftoHtml(formData).subscribe((res: any) => {
          if (res) {
            let html = ""
            res.forEach(element => {
              html += element.split("\n").join("<br/>\n");
            });
            this.sharedServiceService.htmlconvert = html;
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
          this.submitBtn = false;
          this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
        })
      } else if (this.fileToUpload.type === "application/epub+zip") {
        formData.append('filename', this.fileToUpload);
        this.apiService.converteEpubtoHtml(formData).subscribe((res: any) => {
          if (res) {
            let html = ""
            res['massage'].forEach(element => {
              html += element
            });
            this.sharedServiceService.htmlconvert = html
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
          this.submitBtn = false;
        })
      } else if (this.fileToUpload.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        formData.append('doc', this.fileToUpload);
        this.apiService.converteDocxtoHtml(formData).subscribe(res => {
          if (res) {
            this.sharedServiceService.htmlconvert = res;
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
          this.submitBtn = false;
          this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
        })
      }
      // else if(this.fileToUpload.type === " application/msword,") {
      //   formData.append('doc', this.fileToUpload);
      //   this.apiService.converteDoctoHtml(formData).subscribe(res =>{
      //     if(res){
      //       this.sharedServiceService.htmlconvert = res;
      //       this.spinner.hide();
      //     }
      //   }, error =>{
      //     this.error = error;
      //     this.spinner.hide();
      // this.submitBtn = false;
      // this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
      //   })
      // }

      if (this.fileToUpload.type === "application/pdf" || this.fileToUpload.type === "application/epub+zip" || this.fileToUpload.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        this.sharedServiceService.articleFileUpload = this.fileToUpload;
        this.submitBtn = true;
        this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
      }
    } else {
      this.spinner.hide();
      this.submitBtn = false;
      this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
      this.notifyService.showError('Please select file as PDF, docx, epub');
    }
  }


  uploadFileToActivity() {
    this.submitBtn = true;
    this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
    const files = { "files": this.sharedServiceService.articleFileUpload };
    const dialogRef = this.dialog.open(CreateArticleDiaload, {
      height: '454px',
      width: '857px',
      data: { "files": this.sharedServiceService.articleFileUpload }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  searchPublicBook() {
    const dialogRef = this.dialog.open(SearchBookPublicLibrary);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  close() {
    const dialogRef = this.dialog.closeAll();
  }
  backClicked() {
    this.routes.navigate(['/home']);
  }

}

@Component({
  selector: 'app-add-article-editor',
  templateUrl: './add-article-editor.html',
  styleUrls: ['./add-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateArticleDiaload implements OnInit {

  public crerateArticleCKForm: FormGroup = this.formBuilder.group({
    description: ['']
  });

  id: any;
  dataSource: Object;
  pdfFIle: any
  textpdf: any;
  http: HttpClient;
  editor: any;
  public Editor = ClassicEditor;

  constructor(private routes: Router, private apiService: ApiService, public dialog: MatDialog, private route: ActivatedRoute,
    private formBuilder: FormBuilder, public sanitizer: DomSanitizer, private sharedServiceService: SharedServiceService) {
    this.route.params.subscribe(
      params => {
        this.id = +params['id'];
      });
  }

  ngOnInit() {
    // this.sharedServiceService.initializeCkeditorUploadArticle();
    if (this.sharedServiceService.htmlconvert) {
      this.crerateArticleCKForm.controls['description'].setValue(this.sharedServiceService.htmlconvert);
    }

    if (this.id) {
      this.crerateArticleCKForm.controls['description'].setValue('fff');
      this.apiService.getArticleID(this.id).subscribe(data => {
        if (data) {
          this.dataSource = data;
          this.crerateArticleCKForm.controls['description'].setValue(
            this.sanitizer.bypassSecurityTrustHtml(this.dataSource['parsed_text']));
        }
      });
    }
  }

  onChange(event: ChangeEvent): void {
    this.sharedServiceService.editor = event.editor.getData();
  }

  onReady($event) {
    $event.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, this.http);
    };
  }

  sumbit() {
    if (this.formBuilder) {
      this.routes.navigate(["/add-article-info-page"]);
      const dialogRef = this.dialog.closeAll();
    }
  }

  close() {
    const dialogRef = this.dialog.closeAll();
  }

}

@Component({
  selector: 'app-search-public-book-library',
  templateUrl: './search-public-book-library.html',
  styleUrls: ['./add-article.component.scss']
})

export class SearchBookPublicLibrary implements OnInit {

  allBooksArticle: any;
  error: any;
  submitBtn: boolean = false;

  constructor(private routes: Router, private apiService: ApiService, private sharedServiceService: SharedServiceService, public dialog: MatDialog,
    private spinner: NgxSpinnerService) {
    this.submitBtn = false;
    this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
  }

  ngOnInit() {
    this.spinner.show();
    this.apiService.getBooksPublicLibrary().subscribe(data => {
      if (data) {
        this.allBooksArticle = data;
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }

  addArticle(title) {
    const formData: FormData = new FormData();
    formData.append('file_name', title);
    this.spinner.show();
    this.apiService.getBooksPublicLibraryID(formData).subscribe((data: any) => {
      if (data) {
        this.sharedServiceService.htmlconvert = data.data;
        this.submitBtn = true;
        this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
        this.sharedServiceService.bookName = title
        const dialogRef = this.dialog.closeAll();
        this.spinner.hide()
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
      this.submitBtn = false;
      this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
    });
  }

  close() {
    const dialogRef = this.dialog.closeAll();
  }
}

export class UploadAdapter {
  constructor(
    private loader,
    // public url: string,
    private http: HttpClient
  ) {
  }
  //the uploadFile method use to upload image to your server
  // uploadFile(file, url?: string, user?: string) {
  // let name = '';
  // url = 'http://45.79.213.64:8080/api/article_image/';
  // let formData: FormData = new FormData();
  // let headers = new Headers();
  // name = file.name;
  // formData.append('image', file, name);
  // const dotIndex = name.lastIndexOf('.');
  // const fileName = dotIndex > 0 ? name.substring(0, dotIndex) : name;
  // formData.append('name', fileName);
  // formData.append('source', user);

  // headers.append('Content-Type', 'multipart/form-data');
  // headers.append('Accept', 'application/json');
  // console.log('formData', formData);
  // let params = new HttpParams();
  // const options = {
  //   params: params,
  //   reportProgress: true,
  // };
  //http post return an observer
  //so I need to convert to Promise
  // return this.http.post(url, formData);
  // }
  //implement the upload 
  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        var myReader = new FileReader();
        myReader.onloadend = (e) => {
          resolve({ default: myReader.result });
        }
        myReader.readAsDataURL(file);
      }));
  };

  abort() {
  }
}