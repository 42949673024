import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { DomSanitizer } from '@angular/platform-browser'
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { MatDialog } from '@angular/material';
import { ReportGroupDialogComponent } from './report-group-dialog/report-group-dialog.component';
import { AdminMessageDialogComponent } from './admin-message-dialog/admin-message-dialog.component';
import { ManageMembersListComponent } from '../members/members.component';
import { InviteFriendsDialogComponent } from '../members/invite-friends-dialog/invite-friends-dialog.component';

export interface DialogData {
  id: number;
}

declare let $: any;

@Component({
  selector: 'app-group-page',
  templateUrl: './group-page.component.html',
  styleUrls: ['./group-page.component.scss']
})
export class GroupPageComponent implements OnInit {

  dataSource: any;
  id: number;
  commentReplyForm: boolean = false;

  public articleCommentsReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ['', Validators.required],
    is_private: [true],
  });
  public publicCommentsReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ['', Validators.required],
    is_private: [true],
  });
  saveArticle: any;
  articleIndex: any = 0;
  userId: number;
  error: any;
  commentLike: any;
  noComments: any;
  count: any = [];
  JSON: any;
  editcomments: boolean = false;
  isShowManageMembers = false;
  isShowAddText = false;
  manageMembers: any
  addText: any;
  login_id: any;
  isShowLogo = false;
  dialogId: any;
  displayArticlePage: any;
  homePageArticle: any;
  selectedAricle: any
  filterAllPost = [];
  privateComment = [];
  publicComment = [];
  Articlename: any;
  page: number = 1;

  constructor(private apiService: ApiService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    private router: Router,
    public dialog: MatDialog) {
    this.route.params.subscribe(
      params => {
        this.id = params['id'];
        this.getData(this.id);
      });
    this.login_id = localStorage.getItem('userId');
    this.JSON = JSON;
    this.userId = parseInt(localStorage.getItem('userId'));
    this.editcomments = false;
    this.sharedServiceService.footerHide = true;
    this.sharedServiceService.topicHide = true;
  }

  public publicCommentForm: FormGroup = this.formBuilder.group({
    comment: ['', [Validators.required, Validators.maxLength(256)]],
  });

  get pForm() {
    return this.publicCommentForm.controls;
  }

  ngOnInit() {
  }

  selected(event) {
    let target = event.source.selected._element.nativeElement;
    this.selectedAricle = {
      value: event.value,
      text: target.innerText.trim()
    };
  }

  getData(id) {
    this.spinner.show();
    this.apiService.getGroupIDList(id).subscribe(data => {
      this.privateComment = [];
      this.publicComment = [];
      if (data) {
        this.dataSource = data;
        if ((this.dataSource.logo == "")) {
          this.isShowLogo = false;
        }
        else {
          this.isShowLogo = true;
        }
        this.filterAllPost = this.dataSource['articles'].map(element => {
          element['comments'].forEach(ele => {
            if (ele.is_post == true) {
              this.publicComment.push(ele);
            }
            else {
              this.privateComment.push(ele);
            }
          });
        });
        //Comment like      
        if (this.publicComment && this.publicComment.length > 0) {
          for (let i = 0; i < this.publicComment.length; i++) {
            this.publicComment.map(commnent => {
              let data = commnent.likedBy.findIndex(x => x.owner.id = this.userId);
              if (data === -1) {
                commnent.is_like = false;
              } else {
                commnent.is_like = true;
              }
              return commnent;
            });
          }
        }
        if (this.privateComment && this.privateComment.length > 0) {
          for (let i = 0; i < this.privateComment.length; i++) {
            this.privateComment.map(commnent => {
              let data = commnent.likedBy.findIndex(x => x.owner.id = this.userId);
              if (data === -1) {
                commnent.is_like = false;
              } else {
                commnent.is_like = true;
              }
              return commnent;
            });
          }
        }
        //article like
        for (let i = 0; i < this.dataSource['articles'].length; i++) {
          if (this.dataSource['articles'][i].likes && this.dataSource['articles'][i].likes.length > 0) {
            this.dataSource['articles'][i].is_like = false;
            this.dataSource['articles'][i].likes.forEach(element => {
              if (element.user_id == this.userId) {
                this.dataSource['articles'][i].is_like = true;
              }
            });
          }
        }
        this.dataSource['articles'].forEach(element => {
          this.noComments = element.comments
          element['comments'].forEach((key) => {
            key["is_replied"] = false;
          })
        });
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }

  SaveArticle(id) {
    this.spinner.show()
    let responsJSON = {
      "user": this.userId,
      "article": id
    }
    if (responsJSON) {
      this.apiService.saveArticle(responsJSON).subscribe((data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data['message']);
          // this.saveArticle = data.message
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
  }

  likeArticle(id, i) {
    this.spinner.show()
    let responsJSON = {
      "user": this.userId,
      "article": id
    }
    this.apiService.likeArticle(responsJSON).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getData(this.id);
        setTimeout(() => {
          this.spinner.hide();
        }, 3000)
      } else {
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide()
    });
  }

  deleteComment(id) {
    if (id) {
      this.apiService.deleteArticleComments(id).subscribe((data: any) => {
        this.notifyService.showSuccess(data['message']);
        this.getData(this.id);
      }, error => {
        this.error = error;
        this.spinner.hide()
      });
    }
  }

  replyPrivate(aId, cId, i) {
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    this.articleIndex = this.dataSource['articles'].findIndex(x => x.id == aId);
    let reply = !this.dataSource['articles'][this.articleIndex]['comments'][i].is_replied;
    this.dataSource['articles'].forEach(element => {
      element['comments'].map(element => {
        element.is_replied = false;
      });
    });
    this.articleIndex = this.dataSource['articles'].findIndex(x => x.id == aId);
    this.dataSource['articles'][this.articleIndex]['comments'][i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor1_' + i);
    }
  }

  replyPublic(i) {
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.publicComment[i].is_replied;
    this.publicComment.map(x => {
      x.is_replied = false;
      return x;
    });
    this.publicComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor2_' + i);
    }
  }

  replyPublicComments(id, i) {
    this.spinner.show();
    let responseJson = {
      "text": this.sharedServiceService.editor.getData(),
      "comment": id,
      "user": this.userId
    }
    let editarticle = {
      "text": this.sharedServiceService.editor.getData(),
      "section_id": this.publicComment[i].section_id,
      "article": this.publicComment[i].article_id,
      "Comment_likes": []
    }
    if (this.editcomments) {
      this.apiService.commentsUpdate(id, editarticle).subscribe((data: any) => {
        if (data) {
          this.publicComment[i].is_replied = false;
          this.sharedServiceService.editor.setData('');
          this.getData(this.id);
          this.count = [];
          this.editcomments = false;
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    } else {
      this.apiService.commentsReply(id, responseJson).subscribe((data: any) => {
        if (data) {
          this.publicComment[i].is_replied = false;
          this.count = [];
          this.getData(this.id);
          this.editcomments = false;
        }
      }, error => {
        this.error = error;
        this.spinner.hide()
      });
    }
  }

  replyPrivateComments(id, i) {
    this.spinner.show();
    let responseJson = {
      "text": this.sharedServiceService.editor.getData(),
      "comment": id,
      "user": this.userId
    }
    let editarticle = {
      "text": this.sharedServiceService.editor.getData(),
      "section_id": this.privateComment[i].section_id,
      "article": this.privateComment[i].article_id,
      "Comment_likes": []
    }
    if (this.editcomments) {
      this.apiService.commentsUpdate(id, editarticle).subscribe((data: any) => {
        if (data) {
          this.privateComment[i].is_replied = false;
          this.sharedServiceService.editor.setData('');
          this.getData(this.id);
          this.count = [];
          this.editcomments = false;
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    } else {
      this.apiService.commentsReply(id, responseJson).subscribe((data: any) => {
        if (data) {
          this.privateComment[i].is_replied = false;
          this.count = [];
          this.getData(this.id);
          this.editcomments = false;
        }
      }, error => {
        this.error = error;
        this.spinner.hide()
      });
    }
  }

  editPublicComments(i, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.publicComment[i].is_replied;
    this.publicComment.map(element => {
      element.is_replied = false;
    });
    this.publicComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor2_' + i);
      this.publicCommentsReplyForm.controls['articleReply'].setValue(text);
    }
  }

  editPrivateComments(aId, id, i, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    this.articleIndex = this.dataSource['articles'].findIndex(x => x.id == aId);
    let reply = !this.privateComment[i].is_replied;
    // this.dataSource['articles'].forEach(element => {
    this.privateComment.map(element => {
      element.is_replied = false;
    });
    // });
    this.articleIndex = this.dataSource['articles'].findIndex(x => x.id == aId);
    this.privateComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor1_' + i);
      this.articleCommentsReplyForm.controls['articleReply'].setValue(text);
    }
  }


  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) {
    }
  }
  closeBtn(i) {
    // this.dataSource['articles'][articleIndex]['comments'][i].is_replied = false;
    this.publicComment[i].is_replied = false;
    this.privateComment[i].is_replied = false;
  }

  deleteArtcle(id) {
    this.spinner.show();
    this.apiService.deleteArticle(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getData(this.id);
        this.spinner.hide();
      }
    })
  }
  
  deleteReplyComment(id) {
    this.spinner.show();
    this.apiService.deleteReplyComment(id).subscribe((res: any) => {
      if (res) {
        this.notifyService.showSuccess(res['message']);
        this.spinner.hide();
        this.getData(this.id);
      }
    }, error => {
      this.error = error;
      this.spinner.hide()
    })
  }

  likeComment(id) {
    this.spinner.show();
    let responsJSON = {
      "user": this.userId,
      "comment": id
    }
    this.apiService.commentsLike(responsJSON).subscribe((data: any) => {
      if (data) {
        this.commentLike = data;
        this.notifyService.showSuccess(data['message']);
        this.spinner.hide();
        this.getData(this.id);
      }
    });
  }

  redirectToArticle1(id, name) {
    this.Articlename = name.replace(/ +/g, "");
    this.router.navigate(['/article', id, this.Articlename]);
  }

  redirectToEditArticle(id, name) {
    this.Articlename = name.replace(/ +/g, "");
    this.router.navigate(['/add-article-editor', id, this.Articlename]);
  }

  redirectToArticle(aId, pId, name) {
    this.Articlename = name.replace(/ +/g, "");
    this.router.navigate(['/article', aId, this.Articlename]);
    setTimeout(() => {
      $('#' + pId).addClass('paraHighlight');
      var elmnt = document.getElementById(pId);
      elmnt.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest"
      });
    }, 5000);
  }

  deleteGroup(id) {
    this.spinner.show();
    this.apiService.deleteGroup(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.spinner.hide();
        this.router.navigate(['/home']);
      }
    })
  }

  pushHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayArticlePage = this.dataSource['articles'].map(element => {
      if (element.is_displayed_on_home == false) {
        return showArticle = true;
      }
    });
    let requestedJson = {
      "is_displayed_on_home": showArticle
    }
    this.apiService.addRemoveArticle(id, requestedJson).subscribe((data: any) => {
      if (data.message) {
        this.notifyService.showSuccess(data['message']);
        this.getData(this.id);
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }

  postCommnet() {
    if (this.publicCommentForm.invalid) {
      return
    } else {
      let requestedJson = {
        "text": this.pForm.comment.value,
        "is_private": true,
        "is_post": true,
        "owner": this.sharedServiceService.loggedUser,
        "article": this.selectedAricle.value,
      }
      this.apiService.addArticleComments(requestedJson).subscribe(data => {
        if (data) {
          this.publicCommentForm.reset();
          this.getData(this.id);
          this.selectedAricle = [];
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
  }

  OpenReportGroupDialog(id) {
    this.dialogId = id;
    const dialogRef = this.dialog.open(ReportGroupDialogComponent, { panelClass: 'custom-modalbox', data: this.dialogId });
  }

  OpenAdminMessageDialog(id) {
    this.dialogId = id;
    const dialogRef = this.dialog.open(AdminMessageDialogComponent, { panelClass: 'custom-modalbox', data: this.dialogId });
  }

  openManageMembersDialog() {
    const dialogRef = this.dialog.open(ManageMembersListComponent,
      { width: '40%', panelClass: 'custom-modalbox' });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openInviteDialog(id) {
    this.dialogId = id;
    const dialogRef = this.dialog.open(InviteFriendsDialogComponent, { panelClass: 'custom-modalbox', data: this.dialogId });
  }

  onChange(event) {
    this.page = event;
  }

}