import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { InviteFriendsDialogComponent } from '../members/invite-friends-dialog/invite-friends-dialog.component';
declare let $: any;
@Component({
  selector: 'app-last-visited',
  templateUrl: './last-visited.component.html',
  styleUrls: ['./last-visited.component.scss']

})
export class LastVisitedComponent implements OnInit {

  result: any;
  constructor(private apiService: ApiService,public dialog: MatDialog,private router: Router) { }

  ngOnInit() {
    if(localStorage.getItem('isLoggedin')){
      $('body').removeClass('loaded');
      this.apiService.getLastVisitedArticles().subscribe(data => {
        if (data) {
          this.result = data;
          $('body').addClass('loaded');
        }
      });
    }
  }

  openInviteDialog() {
    const dialogRef = this.dialog.open(InviteFriendsDialogComponent, { panelClass: 'custom-modalbox' });
  }
  redirectToArticle(aId, name) {
    $('body').addClass('loaded');
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(['/article', aId, Articlename]);
  }
}

