import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultPopupComponent } from './search-result-popup/search-result-popup.component';
import { MaterialModule } from '../modules/material/material-module';



@NgModule({
  declarations: [SearchResultPopupComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    SearchResultPopupComponent
  ],
  entryComponents: [
    SearchResultPopupComponent
  ]
})
export class ComponentsModule { }
