import { Component, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData, } from '../../group-page/group-pagecomponent';
import { SharedServiceService } from '../../../shared/services/shared-service.service';
import { ArticleComponent } from '../article.component';

let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss']
})
export class InviteFriendsComponent implements OnInit {

  isValid = false;
  id: any
  dataSource: any;
  error: any;
  value = window.location.href;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    public dialogRef: MatDialogRef<ArticleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.id = this.data;
    this.getData(this.data);
  }

  public inviteFriendsForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(reg)]]
  });

  get iForm() {
    return this.inviteFriendsForm.controls;
  }

  ngOnInit() {
  }

  getData(id) {
    if (id) {
      this.spinner.show();
      this.apiService.getArticleID(id).subscribe(data => {
        if (data) {
          this.dataSource = data;
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
    else {
    }
  }

  clipBoard() {
  }

  InviteFriends() {
    if (this.id) {
      if (this.inviteFriendsForm.invalid) {
        return this.isValid = true;
      } else {
        this.isValid = false;
        let message = {
          "invite_email": this.iForm.email.value
        }
        this.spinner.show();
        this.apiService.InviteForArticle(this.id, message).subscribe((data: any) => {
          if (data) {
            this.notifyService.showSuccess(data['message']);
            this.spinner.hide();
          }
        });
        this.dialogRef.close();
      }
    }
  }

}
