import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser'
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { config } from 'src/app/config';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTabChangeEvent } from '@angular/material/tabs';
declare let $: any;
@Component({
  selector: 'app-my-comment',
  templateUrl: './my-comment.component.html',
  styleUrls: ['./my-comment.component.scss']
})
export class MyCommentComponent implements OnInit {

  commentLike: any;
  userId: number;
  error: any;
  dataSource: any;
  id: any;
  public articleTab = 0;
  baseUrl;
  noComments: any;
  page: number = 1;
  savedComments: any;
  savedPage: number = 1;

  public articleCommentsReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ['', Validators.required],
    is_private: [true],
  });

  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    private spinner: NgxSpinnerService,
    private router: Router) {

    $('body').removeClass('loaded');
    this.userId = parseInt(localStorage.getItem('userId'));
  }

  ngOnInit() {
    this.getAllCommensts(this.userId);
    this.getAllSavedCommensts();

  }

  getAllCommensts(id) {
    if (id) {
      this.apiService.getAllCommentByUser(id).subscribe(data => {
        if (data) {
          this.dataSource = data;
          $('body').addClass('loaded');
          // this.spinner.hide();
        }
      });
    }
  }

  getAllSavedCommensts() {
    // this.spinner.show();
    this.apiService.GetAllSaveComments().subscribe(data => {
      if (data) {
        this.savedComments = data;
        setTimeout(x=>{ $('body').addClass('loaded');},1000)
        // this.spinner.hide();
      }
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) {
      //do something here.
    }
  }

  backClicked() {
    this.router.navigate(['/home']);
  }

  redirectToArticle(id) {
    this.router.navigate(['/article', id]);
  }

  public editComment(i, text) {
    this.dataSource.map(x => {
      x.is_replied = false;
      return x;
    });
    this.dataSource[i].is_replied = true;
    this.sharedServiceService.initializeCkeditorDynamic('editor1_' + i);
    if (text) {
      this.articleCommentsReplyForm.controls.articleReply.setValue(text)
    }
  }

  replyComments(id, i) {
    this.spinner.show()
    let responseJson = {
      "text": this.sharedServiceService.editor.getData(),
      "comment": id,
      "user": this.userId,
    }
    this.apiService.commentsReply(id, responseJson).subscribe((data: any) => {
      if (data) {
        this.dataSource[i].is_replied = false;
        this.sharedServiceService.editor.setData('');
        this.ngOnInit();
        this.spinner.hide();
      }
    })
  }

  updateComment(id, i) {
    this.spinner.show()
    let responseJson = {
      "text": this.sharedServiceService.editor.getData(),
      "comment": id,
      "user": this.userId,
    }
    this.apiService.commentsUpdate(id, responseJson).subscribe((data: any) => {
      if (data) {
        this.dataSource[i].is_replied = false;
        this.sharedServiceService.editor.setData('');
        this.ngOnInit();
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    })
  }

  deleteComment(id) {
    this.spinner.show()
    if (id) {
      this.apiService.deleteArticleComments(id).subscribe((data: any) => {
        if (data) {
          this.notifyService.showSuccess(data['message']);
          this.ngOnInit();
          setTimeout(() => {
            this.spinner.hide();
          }, 1000);
        }
      })
    }
  }

  closeBtn(i) {
    this.dataSource[i].is_replied = false;
  }

  likeComment(id) {
    let responsJSON = {
      "user": this.userId,
      "comment": id
    }
    this.apiService.commentsLike(responsJSON).subscribe((data: any) => {
      if (data) {
        this.commentLike = data;
        this.notifyService.showSuccess(data['message']);
        this.ngOnInit();
      }
    });
  }

  deleteReplyComment(id) {
    this.spinner.show();
    this.apiService.deleteReplyComment(id).subscribe((res: any) => {
      if (res) {
        this.notifyService.showSuccess(res['message']);
        this.ngOnInit();
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide()
    })
  }

  onChange(event) {
    this.page = event;
  }

  onSavedChange(event) {
    this.savedPage = event;
  }

}
