import { Component, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData, GroupPageComponent } from '../group-pagecomponent';

@Component({
  selector: 'app-admin-message-dialog',
  templateUrl: './admin-message-dialog.component.html',
  styleUrls: ['./admin-message-dialog.component.scss']
})
export class AdminMessageDialogComponent implements OnInit {

  isValid = false;
  id: any

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private notifyService: NotificationService,
    public dialogRef: MatDialogRef<GroupPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.id = this.data;
  }

  public adminMessageForm: FormGroup = this.formBuilder.group({
    adminMessage: ['', [Validators.required, Validators.maxLength(2000)]]
  });

  get aForm() {
    return this.adminMessageForm.controls;
  }

  ngOnInit() {
  }

  adminMessage() {
    if (this.adminMessageForm.invalid) {
      return this.isValid = true;
    } else {
      this.isValid = false;
      let message = {
        "message": this.aForm.adminMessage.value
      }
      this.spinner.show();
      this.apiService.adminMessage(this.id, message).subscribe((data: any) => {
        if (data) {
          this.notifyService.showSuccess(data['message']);
          this.spinner.hide();
        }
      });
      this.dialogRef.close();
    }
  }


}
