import { Component, OnInit, AfterViewInit, ViewEncapsulation, } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { from } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { HttpParams, HttpClient } from "@angular/common/http";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-add-pdf',
  templateUrl: './add-pdf.component.html',
  styleUrls: ['./add-pdf.component.scss']
})
export class AddPdfComponent implements OnInit {
  fileToUpload: File = null;
  submitBtn: boolean = false;
  error: any;

  constructor(private apiService: ApiService,
    public dialog: MatDialog,
    public sharedServiceService: SharedServiceService,
    private spinner: NgxSpinnerService,
    private routes: Router,
    private notifyService: NotificationService) {
  }

  ngOnInit() {
  }
  handleFileInput(files: FileList) {
    this.submitBtn = false;
    this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
    const formData: FormData = new FormData();
    this.spinner.show();
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.type === "application/pdf") {
      if (this.fileToUpload.type === "application/pdf") {
        formData.append('pdf', this.fileToUpload);
        this.apiService.convertPdftoHtml(formData).subscribe((res: any) => {
          if (res) {
            let html = ""
            res.forEach(element => {
              html += element.split("\n").join("<br/>\n");
            });
            this.sharedServiceService.htmlconvert = html;
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
          this.submitBtn = false;
          this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
        })
      }
      if (this.fileToUpload.type === "application/pdf") {
        this.sharedServiceService.PdfFileUpload = this.fileToUpload;
        this.submitBtn = true;
        this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
      }
    } else {
      this.spinner.hide();
      this.submitBtn = false;
      this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
      this.notifyService.showError('Please select file as PDF');
    }
  }


  uploadFileToActivity() {
    this.submitBtn = true;
    this.sharedServiceService.addArticleSubmitBtn = this.submitBtn;
    const files = { "files": this.sharedServiceService.PdfFileUpload };
    const dialogRef = this.dialog.open(CreatePDFDiaload, {
      height: '454px',
      width: '857px',
      data: { "files": this.sharedServiceService.PdfFileUpload }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  close() {
    const dialogRef = this.dialog.closeAll();
  }
  backClicked() {
    this.routes.navigate(['/home']);
  }

}

@Component({
  selector: 'app-add-pdf-editor',
  templateUrl: './add-pdf-editor.html',
  styleUrls: ['./add-pdf.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreatePDFDiaload implements OnInit {

  public creratePDFCKForm: FormGroup = this.formBuilder.group({
    description: ['']
  });

  id: any;
  dataSource: Object;
  pdfFIle: any
  textpdf: any;
  http: HttpClient;
  editor: any;
  public Editor = ClassicEditor;

  constructor(private routes: Router, private apiService: ApiService, public dialog: MatDialog, private route: ActivatedRoute,
    private formBuilder: FormBuilder, public sanitizer: DomSanitizer, private sharedServiceService: SharedServiceService) {
    this.route.params.subscribe(
      params => {
        this.id = +params['id'];
      });
  }

  ngOnInit() {
    if (this.sharedServiceService.htmlconvert) {
      this.creratePDFCKForm.controls['description'].setValue(this.sharedServiceService.htmlconvert);
    }

    if (this.id) {
      this.creratePDFCKForm.controls['description'].setValue('fff');
      this.apiService.getArticleID(this.id).subscribe(data => {
        if (data) {
          this.dataSource = data;
          this.creratePDFCKForm.controls['description'].setValue(
            this.sanitizer.bypassSecurityTrustHtml(this.dataSource['parsed_text']));
        }
      });
    }
  }

  onChange(event: ChangeEvent): void {
    this.sharedServiceService.editor = event.editor.getData();
  }

  onReady($event) {
    $event.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, this.http);
    };
  }

  sumbit() {
    if (this.formBuilder) {
      this.routes.navigate(["/add-pdf-info-page"]);
      const dialogRef = this.dialog.closeAll();
    }
  }

  close() {
    const dialogRef = this.dialog.closeAll();
  }

}
export class UploadAdapter {
  constructor(
    private loader,
    private http: HttpClient
  ) {
  }
  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        var myReader = new FileReader();
        myReader.onloadend = (e) => {
          resolve({ default: myReader.result });
        }
        myReader.readAsDataURL(file);
      }));
  };

  abort() {
  }
}
