import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ApiService } from "../../shared/services/api.service";
import { SharedServiceService } from "../../shared/services/shared-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "../../shared/services/notification.service";
import { InviteFriendsDialogComponent } from "../members/invite-friends-dialog/invite-friends-dialog.component";
import { async } from "@angular/core/testing";
import { SearchResultPopupComponent } from "src/app/shared/components/search-result-popup/search-result-popup.component";
declare let $: any;

@Component({
  selector: "app-reading-hub-page",
  templateUrl: "./reading-hub-page.component.html",
  styleUrls: ["./reading-hub-page.component.scss"],
})
export class ReadingHubPageComponent implements OnInit {
  dataSource: any;
  id: any;
  userId: any;
  error: any;
  dialogId: any;
  noQuery4 = false;
  videoSrc: any;
  videoDescription: any;
  API_ENDPOINT: any;
  urlNotFound: boolean;
  videoList: any;
  paperList: any;
  searchQuestion = "";
  allAnswers: any[];
  noQuery = false;
  searchQuery = false;
  setQuestion:string = 'Ask the books...';
  setQuestions = [];
  setRandonQuestion = [];
  question:string;
  allAnswersList = [];
  showSpinner = false;
  @ViewChild('recordPopup', { static: true }) recordPopup: TemplateRef<any>;
  // paramId:number;

  sampleQuestions = [
    {id: 39, myQuestions:[
      {question:'How many states were divided in the northwestern India during Alexanders invasion?'},
      {question:'When was Vikrama Era began?'},
      {question:'When was the Saka Era began?'},
    ]},

    {id: 38, myQuestions:[
      {question:'How can we induce people to look after their health?'},
      {question:'How can humanity increase its collective wisdom?'},
      {question:'How do we reduce the skill gap between black and white people in America?'},
    ]},

    {id: 37, myQuestions:[
      {question:'Are you currently dreaming, and how do you know?'},
      {question:'Do memories exist even if you forget them?'},
      {question:'Does grass feel pain when you step on it?'},
    ]},

    {id: 20, myQuestions:[
      {question:'Freud was born in the small town of Freiberg, Moravia, in which year?'},
      {question:'Which best describes Freuds stance on religion?'},
      {question:' Before inventing psychoanalysis, Freud made a name for himself in which field?'},
    ]},

    {id: 35, myQuestions:[
      {question:'Are there European equivalents of JEP & JEL?'},
      {question:'Supply creates its own demand'},
      {question:'seeking subject matter expert nominees: future of human welfare and societal change'},
    ]},

    {id: 36, myQuestions:[
      {question:'What problem does your business solve?'},
      {question:'How does your business generate income?'},
      {question:'Which parts of your business are not profitable?'},
    ]},

    {id: 34, myQuestions:[
      {question:'Why is power sharing desirable in modern democracies?'},
      {question:'Which is more powerful for motivating subordinates: referent power or expert power?'},
      {question:'What is the income effect for inferior commodities?'},
    ]},

    {id: 21, myQuestions:[
      {question:'Is climate change caused by humans?'},
      {question:'What are the main threats of climate change?'},
      {question:'Is it climate change, global warming, or global climate crisis?'},
    ]},

    {id: 17, myQuestions:[
      {question:'What is the effect of the internet on societys attitudes towards femininity and masculinity?'},
      {question:' What impact will the introduction of smart phones have on the broader society in the short/long term?'},
      {question:'Has anyone known someone who was stalked, if so can you please describe?'},
    ]},

    {id: 16, myQuestions:[
      {question:'What is the best form of government?'},
      {question:'What is the relation between the government and individuals?'},
      {question:'How should the distribution of goods and services be arranged?'},
    ]},
  ];

  slideConfig1 = {
    slidesToShow: 6,
    slidesToScroll: 4,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    easing: 'easeOutElastic',
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          centerMode: true,
          focusOnSelect: true,
          dots: true,
        },
      },
    ],
  };

  constructor(
    private apiService: ApiService,
    public sharedServiceService: SharedServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public dialog: MatDialog
  ) {
    $("body").removeClass("loaded");
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      
      this.getArticle(this.id);
    });
    this.userId = localStorage.getItem("userId");
  }

  ngOnInit() {
    this.getArticle(this.id);
  }

  getArticle(id) {
    this.apiService.getGroupIDList(id).subscribe((data: any) => {
      if (data) {
        this.dataSource = data;
        setTimeout(function () {
          $("body").addClass("loaded");
        }, 100);
      }
      this.getImage();
    });
  }
  getImage() {
    this.urlNotFound = false;
    this.API_ENDPOINT = "https://app.hyperead.org";
    this.videoList = this.dataSource.group_video;
    this.paperList = this.dataSource.paper;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) {
    } else if (tabChangeEvent.index == 0) {
    }
  }

  redirectToArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/article", id, Articlename]);
  }
  redirectToPaper(id) {
    this.router.navigate(["pdf-viewer", id]);
  }

  redirectToEditArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/add-article-editor", id, Articlename]);
  }
  deleteGroup(id) {
    this.spinner.show();
    this.apiService.deleteGroup(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data["message"]);
        this.spinner.hide();
        this.router.navigate(["/home"]);
      }
    });
  }

  deleteArtcle(id) {
    this.spinner.show();
    this.apiService.deleteArticle(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data["message"]);
        this.getArticle(this.id);
        this.spinner.hide();
      }
    });
  }

  joinSelceted(id) {
    if (this.userId == null) {
      this.notifyService.showError("Sign in to join the group");
      return;
    }
    this.spinner.show();

    let requestedJson = {
      group_id: id,
    };
    this.apiService.userjoint(requestedJson).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getArticle(this.id);
          location.reload();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  pushHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    let displayArticlePage = this.dataSource["articles"].map((element) => {
      if (element.is_displayed_on_home == false) {
        return (showArticle = true);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveArticle(id, requestedJson).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getArticle(this.id);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }
  pushHomePagePaper(id) {
    this.spinner.show();
    let showPaper: any;
    let displayArticlePage = this.dataSource["paper"].map((element) => {
      if (element.is_displayed_on_home == false) {
        return (showPaper = true);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showPaper,
    };
    this.apiService.addRemovePaper(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess("Paper Push To HomePage");
          this.getArticle(this.id);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  openInviteDialog(id) {
    this.dialogId = id;
    const dialogRef = this.dialog.open(InviteFriendsDialogComponent, {
      panelClass: "custom-modalbox",
      data: this.dialogId,
    });
  }

  getSearchHubData(fileName) {
    let file_name = fileName;
    fileName = fileName.toLowerCase();
    if (fileName.includes(" ")) {
      fileName = fileName.split(" ").join("_");
    } else if (fileName.includes("-")) {
      fileName = fileName.split(" ").join("_");
    }
    this.noQuery = false;
    this.searchQuery = true;
    this.spinner.show();
    this.allAnswers = [];
    if (this.searchQuestion && fileName) {
      let requestJson = { 
        query: this.searchQuestion,
        file_name: fileName,
      };
      this.apiService.getSearchHubData(requestJson).subscribe(
        (data: any) => {
          this.allAnswers = data.answer_list;
          const dialogRef = this.dialog.open(SearchResultPopupComponent, {
            height: "90vh",
            width: "85vw",
            maxWidth: "85vw",
            data: {
              allAnswers: data.answer_list,
              query: this.searchQuestion,
              collection: file_name,
            },
          });
          // dialogRef.afterClosed().subscribe((result) => {
          //   this.searchQuestion = "";
          // });
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.notifyService.showError("No results found");
        }
      );
    } else {
      if (this.searchQuestion == "") {
        this.noQuery = true;
      } else {
        this.notifyService.showError("No results found");
      }
      this.spinner.hide();
    }
  }

  preventFirstSpace(event: KeyboardEvent): void {
    if (
      this.searchQuestion.length == 1 &&
      (event.key == " " || event.keyCode == 32)
    ) {
      this.searchQuestion = "";
    }
  }

  getQuestions(){
    let item:any = this.sampleQuestions.find(item => item.id == this.id);

    item.myQuestions.map(data =>{
      this.setRandonQuestion.push(data.question);
    })

    this.question = this.setRandonQuestion[
      Math.floor(Math.random() * this.setRandonQuestion.length)
    ];

   }

   getSearchHubData4(query, fileName) {
    
    this.allAnswersList = [];
    let requestJson = {
      query: query,
      file_name: fileName,
    };
    this.apiService.getSearchHubData(requestJson).subscribe(
      (data: any) => {
        this.dialog.open(this.recordPopup,{
          width: '80%',
          maxHeight: 'calc(100vh - 90px)',
          height : 'auto',
        });
        this.allAnswersList = data.answer_list;
        // this.showPinnerForSearch4 = false;
        this.showSpinner = false;
      },
      (error) => {
        this.showSpinner = false;
      }
    );
  }

  searchQuery4(value) {
    
    if(value == "" || value == undefined){
      this.noQuery4 = true;
      this.showSpinner = false;
      // this.spinner.hide();
    }else{
      // this.spinner.show();
      this.showSpinner = true;
      this.noQuery4 = false;
      this.getSearchHubData4(value, "main" )
    }
  }

  highlightedText(context: string, answer: string) {
    
    if (!answer && !context) {
      return context;
    }
    return context.replace(new RegExp(answer, "gi"), (match) => {
      return (
        '<span class="highlightedAnswer font-weight-bold">' + match + "</span>"
      );
    });
  }

  gotToParagraph(aid, aname, pid) {
    
    if (aid) {
      this.apiService.getArticleID(aid).subscribe(
        async (data: any) => {
          if (data) {
            if (aname) {
              this.router.navigate(["/article", aid, aname]);
            }
            if (pid) {
              this.sharedServiceService.scrollSectionId = pid;
            }
          }
        },
        (error) => {
          this.error = error;
          this.notifyService.showError("Article Not found.");
        }
      );
    } else {
      this.notifyService.showError("Article Not found.");
    }
  }
}
