import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { config } from 'src/app/config';
import { InviteFriendsDialogComponent } from './invite-friends-dialog/invite-friends-dialog.component';


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  displayedColumns: string[] = ['first_name'];
  dataSource = new MatTableDataSource()
  res: any;
  baseUrl;

  constructor(private apiService: ApiService,
    public dialog: MatDialog,
    public sharedServiceService: SharedServiceService) {
    this.baseUrl = config.API_ENDPOINT;
  }

  ngOnInit() {
    this.apiService.getAllUsers().subscribe(data => {
      if (data) {
        this.res = data
        this.dataSource = new MatTableDataSource(this.res);
        this.res.forEach(element => {
        });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ManageMembersListComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openInviteDialog() {
    const dialogRef = this.dialog.open(InviteFriendsDialogComponent, { panelClass: 'custom-modalbox' });
  }

}

@Component({
  selector: 'app-members-list-dialog',
  templateUrl: 'members-list-dialog.html',
  styleUrls: ['./members.component.scss']
})
export class ManageMembersListComponent implements OnInit {

  id: number;
  dataSource: any;

  constructor(private route: ActivatedRoute,
    private apiService: ApiService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService) {
  }

  ngOnInit() {
    this.getGroupDetails();
  }

  getGroupDetails() {
    this.apiService.getAllUsers().subscribe(data => {
      if (data) {
        this.dataSource = data;
      }
    });
  }

  // inviteFriends() {
  //   let responsJSON = {
  //     "invite_email": "test13samcom13@gmail.com"
  //   }
  //   this.apiService.commentsLike(responsJSON).subscribe((data: any) => {
  //     if (data) {
  //       this.notifyService.showSuccess("Email sent successfully");
  //       this.ngOnInit();
  //     }
  //   });
  // }
}