import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ReadingHubComponent } from "../reading-hub/reading-hub.component";
import { ApiService } from "src/app/shared/services/api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "src/app/shared/services/notification.service";
import { Router } from "@angular/router";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";

@Component({
  selector: "app-search-hub",
  templateUrl: "./search-hub.component.html",
  styleUrls: ["./search-hub.component.scss"],
})
export class SearchHubComponent implements OnInit {
  allAnswers = [];
  is_submit = false;
  error: any;
  isSearchQuery = false;
  sampleQuestions = [
    "What is the relation between happiness and good virtues?",
    "What are the risks with nuclear energy?",
    "What is the dream analysis?",
    "What is happiness?",
    "What is the induction problem?",
    "Does all our ideas ultimately derived from experience?",
    "Why the social contract is essential to modern society?",
    "what is political idiology?",
    "what is anxiety?",
 
  ];


  longText: any;
  
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    private router: Router,
    public sharedServiceService: SharedServiceService
  ) {}

  public searchForm: FormGroup = this.formBuilder.group({
    fileName: ["", [Validators.required]],
    searchQuestion: ["", [Validators.required]],
  });

  ngOnInit() {}

  openRedingHub() {
    const dialogRef = this.dialog.open(ReadingHubComponent, {
      panelClass: "custom-modalbox",
    });
  }

  getSearchHubData(query, fileName) {
    this.isSearchQuery = true;
    this.spinner.show();
    this.allAnswers = [];
    let requestJson = {
      query: query,
      file_name: fileName,
    };
    this.apiService.getSearchHubData(requestJson).subscribe(
      (data: any) => {
        this.allAnswers = data.answer_list;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  searchQuery() {
    this.is_submit = true;
    if (this.is_submit && this.searchForm.value.fileName == "") {
      this.searchForm.get("fileName").setValue("main");
    }
    if (this.is_submit && this.searchForm.valid) {
      this.getSearchHubData(
        this.searchForm.value.searchQuestion,
        this.searchForm.value.fileName
      );
    }
  }

  highlightedText(context: string, answer: string) {
    if (!answer && !context) {
      return context;
    }
    return context.replace(new RegExp(answer, "gi"), (match) => {
      return (
        '<span class="highlightedAnswer font-weight-bold">' + match + "</span>"
      );
    });
  }

  getSample1HubData(id) {
    const query = document.querySelector(`#${id} mat-card-title`).textContent;
    let fileName = document.querySelector(
      `#${id} mat-card-subtitle`
    ).textContent;
    fileName = fileName.substring(fileName.indexOf("'") + 1);
    fileName = fileName.substring(0, fileName.indexOf("'"));
    if (fileName.includes(" ")) {
      fileName = fileName.replace(" ", "_");
    } else if (fileName.includes("-")) {
      fileName = fileName.replace("-", "_");
    }
    fileName = fileName.toLocaleLowerCase();
    if (query.length > 0 && fileName.length > 0) {
      this.getSearchHubData(query, fileName);
    }
  }

  preventFirstSpace(event: KeyboardEvent): void {
    if (
      this.searchForm.value.searchQuestion.length == 1 &&
      (event.key == " " || event.keyCode == 32)
    ) {
      this.searchForm.controls["searchQuestion"].reset();
    }
  }

  gotToParagraph(aid, aname, pid) {
    if (aid) {
      this.apiService.getArticleID(aid).subscribe(
        async (data: any) => {
          if (data) {
            if (aname) {
              this.router.navigate(["/article", aid, aname]);
            }
            if (pid) {
              this.sharedServiceService.scrollSectionId = pid;
            }
          }
        },
        (error) => {
          this.error = error;
          this.notifyService.showError("Article Not found.");
        }
      );
    } else {
      this.notifyService.showError("Article Not found.");
    }
  }

  selectSampleQuestion() {
    const question =
      this.sampleQuestions[
        Math.floor(Math.random() * this.sampleQuestions.length)
      ];
      
    this.searchForm.get("searchQuestion").setValue(question);
    
  }
}
