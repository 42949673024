import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { ReadingHubComponent } from '../reading-hub/reading-hub.component';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  results: [];
  searchTerm$ = new Subject<string>();
  currentRoute: any;
  headerLocalStorage: any;
  constructor(private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    public authService: AuthService,
    public router: Router,
    public sharedServiceService: SharedServiceService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog) {

    if (this.searchTerm$) {
      this.apiService.search(this.searchTerm$)
        .subscribe(results => {
          this.results = results;
        });
    }

  }

  public searchForm: FormGroup = this.formBuilder.group({
    searchText: [''],
  });

  ngOnInit() {

    $(document).click(function (e) {
      if (e.target.id != 'searchArticle') {
        let searchData = document.getElementById('searchText');
        if (searchData) {
          (<HTMLInputElement>document.getElementById('searchText')).value = null;
        }
        let searchResult = document.getElementById('resultData');
        if (searchResult) {
          searchResult.classList.add('hideresult')
        }

        let noArticle = document.getElementById('noResult');
        if (noArticle) {
          noArticle.classList.add('hideresult')
        }
      }
    });

  }

  changeLang(language: string) {
    const dom: any = document.querySelector('body');
    if (language == 'en') {
      dom.classList.add('ltr');
      dom.classList.remove('rtl');
    } else if (language == 'he') {
      dom.classList.add('rtl');
      dom.classList.remove('ltr');
    }
    localStorage.setItem('lang', language);
    this.translate.use(language);
  }

  clickEvent() {
    $('.mian-sidenav').addClass('show_sidenav')
  }

  logout() {
    // this.spinner.show();
    $('body').addClass('loaded');
    this.sharedServiceService.headerdata = false
    this.sharedServiceService.withoutLoginHeaderData = false
    this.authService.logout();
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('lang');
    localStorage.removeItem('token');
    localStorage.removeItem('fullName');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    localStorage.removeItem('is_superuser');
    localStorage.removeItem('avatar');
    localStorage.clear();

    this.router.navigate(['/login'])
    location.reload();
    this.spinner.hide();
  }

  redirectArticle(id) {
    if (id) {
      this.searchForm.controls.searchText.setValue('');
      this.searchTerm$.next('');
      this.searchForm.reset();
      this.sharedServiceService.articleID = id
      this.router.navigate(['/article', id])
      setTimeout(() => {
        this.results = null;
        $('body').addClass('loaded');
      }, 100);
    }
  }

  openRedingHub() {
    const dialogRef = this.dialog.open(ReadingHubComponent, { panelClass: 'custom-modalbox' });
  }

  


}
