import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";
import { MatDialog } from "@angular/material";
import { ReadingHubComponent } from "../reading-hub/reading-hub.component";
import { ApiService } from "src/app/shared/services/api.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  constructor(
    private router: Router,
    public sharedServiceService: SharedServiceService,
    public dialog: MatDialog
  ) {
    this.sharedServiceService.logoHide = true;
  }

  slides = [
    {
      img: "../../../assets/img/introclimsci.jpeg",
      title: "Crime and Punishment / Chapter 7",
    },
    {
      img: "../../../assets/img/cover-1-683x1024.jpeg",
      title: "Alladin and the magic lump",
    },
    {
      img: "../../../assets/img/introclimsci.jpeg",
      title: "Crime and Punishment",
    },
    {
      img: "../../../assets/img/cover-1-683x1024.jpeg",
      title: "Crime and Punishment",
    },
    {
      img: "../../../assets/img/introclimsci.jpeg",
      title: "Crime and Punishment",
    },
  ];
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    dots: false,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          focusOnSelect: true,
        },
      },
    ],
  };

  slides1 = [
    {
      img: "../../../assets/img/rubrication.png",
      title: "Digital Rubrication with HypeRead",
    },
    {
      img: "../../../assets/img/Nietzsche-mask.png",
      title: "Find All Of Nietzsche's Writings",
    },
    {
      img: "../../../assets/img/PP.png",
      title: "Learn the classics of political philosophy",
    },
    {
      img: "../../../assets/img/illiad-hero.png",
      title: "Read the Illiad by Homar",
    },
    {
      img: "../../../assets/img/groups-cube.png",
      title: "Curated titles in dedicated reading hubs",
    },
    {
      img: "../../../assets/img/books-slide-hero.png",
      title: "Classics Books For Free",
    },
  ];

  slides3 = [
    {
      img: "../../../assets/img/try-hero.jpg",
      title: "Crime and Punishment",
      subtitle:
        "It was first published in the literary journal The Russian Messenger in twelve monthly installments during 1866. It was later published in a single volume.",
    },
    {
      img: "../../../assets/img/thus-spoke.jpg",
      title: "Thus Spoke Zarathustra",
      subtitle:
        "It was first published in the literary journal The Russian Messenger in twelve monthly installments during 1866. It was later published in a single volume.",
    },
    {
      img: "../../../assets/img/around-the-world.jpg",
      title: "Around the World in Eighty Days",
      subtitle:
        "It was first published in the literary journal The Russian Messenger in twelve monthly installments during 1866. It was later published in a single volume.",
    },
    {
      img: "../../../assets/img/illiad-hero.jpg",
      title: "The Iliada",
      subtitle:
        "It was first published in the literary journal The Russian Messenger in twelve monthly installments during 1866. It was later published in a single volume.",
    },
    {
      img: "../../../assets/img/The republic.jpg",
      title: "The Republic by Plato",
      subtitle:
        "It was first published in the literary journal The Russian Messenger in twelve monthly installments during 1866. It was later published in a single volume.",
    },
  ];

  slide3Config = {
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 9000,
    pauseOnHover: false,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          focusOnSelect: true,
        },
      },
    ],
  };

  ngOnInit() {}

  openRedingHub() {
    const dialogRef = this.dialog.open(ReadingHubComponent, {
      panelClass: "custom-modalbox",
    });
  }


  

  slickInit(event) {}
  breakpoint(event) {}
  afterChange(event) {}
  beforeChange(event) {}
}
