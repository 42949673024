import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../shared/services/api.service";
import { SharedServiceService } from "../../shared/services/shared-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "../../shared/services/notification.service";
import { MatTabChangeEvent } from "@angular/material";

declare let $: any;
@Component({
  selector: "app-reading-hub-groups",
  templateUrl: "./reading-hub-groups.component.html",
  styleUrls: ["./reading-hub-groups.component.scss"],
})
export class ReadingHubGroupsComponent implements OnInit {
  dataSource: any;
  eventData: any;
  error: any;
  displayGroupPage: any;
  id: any;
  userId: any;
  API_ENDPOINT: string;
  c: any;
 

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    public sharedServiceService: SharedServiceService,
    private router: Router,
    private route:ActivatedRoute,
    private notifyService: NotificationService
  ) {
    $("body").removeClass("loaded");
    
  }

  ngOnInit() {
    this.API_ENDPOINT = "https://app.hyperead.org";
    this.allGroupList();
    this.getAllEvents();
    this.userId = localStorage.getItem("userId");
  }

  allGroupList() {
    this.apiService.getAllGroupsList().subscribe(
      (data) => {
        if (data) {
          this.dataSource = data;
          
          setTimeout(function () {
            $("body").addClass("loaded");
          }, 100);
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) {
    } else if (tabChangeEvent.index == 0) {
    }
  }

  getAllEvents() {
    this.apiService.getAllEvents().subscribe((data) => {
      if (data) {
        this.eventData = data;
      }
    });
  }

  getArticle(id) {
    this.apiService.getGroupIDList(id).subscribe((data: any) => {
      if (data) {
        this.dataSource = data;
        
        setTimeout(function () {
          $("body").addClass("loaded");
        }, 100);
      }
    });
  }

  deleteEvent(id) {
    this.spinner.show();
    this.apiService.deleteEvent(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data["message"]);
        this.spinner.hide();
        this.router.navigate(["/home"]);
      }
    });
  }

  favoriteEvent(id, event) {
    let requestedJson = {
      is_favorite: event.is_favorite ? false : true,
    };
    this.spinner.show();
    this.apiService.favoriteEvent(id, requestedJson).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data["message"]);
        this.spinner.hide();
        this.router.navigate(["/home"]);
      }
    });
  }

  joinSelceted(id) {
    this.spinner.show();

    let requestedJson = {
      group_id: id,
    };
    this.apiService.userjoint(requestedJson).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getArticle(this.id);
          location.reload();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  pushHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayGroupPage = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == false) {
        return (showArticle = true);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveGroup(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess("Group status changed successfully");
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  redirectToReadingGroupPage(id, name) {
    let groupName = name.replace(/ +/g, "");
    this.router.navigate(["/reading-hub-page", id, groupName]);
  }

  redirectToCreateEvent() {
    this.router.navigate(["/upcoming-reading-events"]);
  }

  
}
