import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/services/api.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location, DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "src/app/shared/services/notification.service";
import * as moment from "moment";

@Component({
  selector: "app-upcoming-reading-events",
  templateUrl: "./upcoming-reading-events.component.html",
  styleUrls: ["./upcoming-reading-events.component.scss"],
})
export class UpcomingReadingEventsComponent implements OnInit {
  public createEvent: FormGroup = this.formBuilder.group({
    group: ["", Validators.required],
    event_name: [
      "",
      [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")],
    ],
    teacher_name: [
      "",
      [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")],
    ],
    link_location: [
      "",
      [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")],
    ],
    free: ["", Validators.required],
    meeting_date: ["", Validators.required],
    meeting_time: ["", Validators.required],
    note: ["", [Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
  });

  ownerID: any;
  allGroups: any;
  error: any;
  editableDial: any;
  minDate: Date;
  userId: any;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public sharedServiceService: SharedServiceService,
    private route: Router,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    private routes: ActivatedRoute
  ) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this.ownerID = localStorage.getItem("userId");
    this.apiService.getAllGroupsList().subscribe((data) => {
      if (data) {
        this.allGroups = data;
      }
    });
  }

  omit_number(event) {
    var key;
    key = event.charCode; //         key = event.keyCode;  (Both can be used)
    return (key > 47 && key < 58) || key == 45 || key == 46;
  }

  get createEventform() {
    return this.createEvent.controls;
  }

  OnDateChange(data) {}

  oncreateEvent() {
    if (this.createEvent.invalid) {
      return;
    }

    var datePipe = new DatePipe("en-US");
    let formData: FormData = new FormData();

    let APIData = {
      event_name: this.createEvent.controls["event_name"].value,
      group: this.createEvent.controls["group"].value,
      teacher_name: this.createEvent.controls["teacher_name"].value,
      link_location: this.createEvent.controls["link_location"].value,
      meeting_date: datePipe.transform(
        this.createEvent.controls["meeting_date"].value,
        "yyyy-MM-dd"
      ),
      meeting_time: moment(this.createEvent.controls["meeting_time"].value, [
        "h:mm A",
      ]).format("HH:mm:ss"),
      cost: this.createEvent.controls["free"].value,
      note: this.createEvent.controls["note"].value,
    };
    // formData.append('event_name', this.createEvent.controls['event_name'].value);
    // formData.append('group', this.createEvent.controls['group'].value);
    // formData.append('teacher_name', this.createEvent.controls['teacher_name'].value);
    // formData.append('link_location', this.createEvent.controls['link_location'].value);
    // formData.append('meeting_date', datePipe.transform(this.createEvent.controls['meeting_date'].value, 'yyyy-MM-dd'));
    // formData.append('meeting_time', moment(this.createEvent.controls['meeting_time'].value, ["h:mm A"]).format("HH:mm:ss"));
    // formData.append('cost', this.createEvent.controls['free'].value);
    // if(this.createEvent.controls['note'].value){
    //   formData.append('note', this.createEvent.controls['note'].value);
    // }
    this.spinner.show();
    this.apiService.createEvent(APIData).subscribe(
      (response: any) => {
        if (response.message) {
          this.notifyService.showError(response["message"]);
          this.spinner.hide();
          this.route.navigate(["reading-hub-gropus"]);
        } else {
          this.notifyService.showSuccess("Event created successfully.");
          this.spinner.hide();
          let Eventname = response["event_name"].replace(/ +/g, "");
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
        // this.notifyService.showError("Add Tags " + this.error.error.add_tag[0]);
      }
    );
  }
}
