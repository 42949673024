// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BASE_URL: "https://sandbook-api.sctestingsite.com/",
  BASE_URL: "http://localhost:4200/",
  // API_ENDPOINT : "http://3.228.7.122:8080/",
  API_ENDPOINT: "https://app.hyperead.org/",

  // API_ENDPOINT: "https://sandbook-api.sctestingsite.com/",

  API_ENDPOINT_NEW: "https://api.hyperead.io/",
  //  API_ENDPOINT: "http://3.228.7.122:8080/",
  // API_ENDPOINT: "https://3rd-source-api.mydomainabc.tk/",
  //  API_ENDPOINT_WITHOUTSLASH: "http://85.159.209.23:8080",
  API_ENDPOINT_WITHOUTSLASH: "https://app.hyperead.org",
  // API_ENDPOINT_WITHOUTSLASH: "https://3rd-source-api.mydomainabc.tk",
  API_ENDPOINT_LOGIN: "api/login/",
  API_ENDPOINT_SIGNUP: "api/registration/",
  API_ENDPOINT_GETALLUSER: "api/userslist/",
  API_ENDPOINT_TOPICS: "api/groups/",
  API_ENDPOINT_EDIT_TOPICS: "api/update_groups/",
  API_ENDPOINT_DELETE_TOPICS: "api/delete_groups/",
  API_ENDPOINT_DELETE_EVENT: "api/delete_event/",
  API_ENDPOINT_FAVORITE_EVENT: "api/favorite_event/",
  API_ENDPOINT_GET_FAVORITE_EVENT: "api/get_my_favorite_events/",
  API_ENDPOINT_ALLARTICLES: "api/articles/",
  API_ENDPOINT_ALLPDFS: "api/add_paper/",
  API_ENDPOINT_ALLPAPERHOMEPAGE: "api/get_paper_by_filter/",
  API_ENDPOINT_GETPDFID: "api/get_paper_by_id/",
  API_ENDPOINT_GETPDFPAPERCOMMENT: "api/get_comment_paperId/",
  API_ENDPOINT_ALLCOMMENST: "api/comments/",
  API_ENDPOINT_REPLYPAPERCOMMENT: "api/add_paper_comment_replies/",
  API_ENDPOINT_ADDCOMMEMTONPAPER: "api/add_paper_comment/",
  API_ENDPOINT_ALLCOMMENSTBYSECTIONID: "api/commentsBySection/",
  API_ENDPOINT_SENDEMAIL: "api/sendEmail/",
  API_ENDPOINT_BOOKS: "api/books/",
  API_ENDPOINT_USERS: "api/users/",
  API_ENDPOINT_CUSTOMERUSERS: "api/custom_users/",
  API_ENDPOINT_SAVEARTICLE: "api/save_articles/",
  API_ENDPOINT_LIKEARTICLE: "api/like_article/",
  API_ENDPOINT_SENDINVITATION: "api/sendInvition/",
  API_ENDPOINT_PDFTOHTML: "api/pdfTohtml/",
  API_ENDPOINT_DELETECOMMENT: "api/commentsdelete/",
  API_ENDPOINT_DELETEARTICLE: "api/article_delete/",
  API_ENDPOINT_SEARCHALLARTICLE: "api/searchArticle/?search=",
  API_ENDPOINT_DELETEREPLYCOMMENT: "api/deleteReply/",
  API_ENDPOINT_ALLCOMMENSTBYUSER: "api/commentsby_user/",
  API_ENDPOINT_EPUBTOHTML: "api/epubTohtml/",
  API_ENDPOINT_DOCXTOHTML: "api/docsTohtml/",
  API_ENDPOINT_ALL_TOPICS: "api/groups_all/",
  API_ENDPOINT_HIGHLIGHT: "api/article/",
  API_ENDPOINT_DELETEHIGHLIGHT: "api/deletehighlights/",
  API_ENDPOINT_PUBLIC_LIBRARY_BOOK: "api/public_library_book_to_html/",
  API_ENDPOINT_REPORT_GROUP: "api/report_group/",
  API_ENDPOINT_ADMIN_MESSAGE: "api/send_message_admins/",
  API_ENDPOINT_INVITE_FRIENDS: "api/send_friend_invitation/",
  API_ENDPOINT_TRENDING_ARTICLE: "api/add_trending/",
  API_ENDPOINT_GROUP_INVITATION: "api/send_group_invitation/",
  API_ENDPOINT_APPLY_TO_PUBLICATION: "api/apply_to_publish/",
  API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE: "api/add_remove_article_from_home/",
  API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE_PAPER: "api/get_paper_by_id/",
  API_ENDPOINT_INVITE_FRIENDS_ARTICLE: "api/send_article_invitation/",
  API_ENDPOINT_GET_PRIVATE_NOTE: "api/get_notes_by_userId/",
  API_ENDPOINT_ADD_ARTICLE_PRIVATE_NOTE: "api/notes/",
  API_GET_ARTICLE_SECTION_ID: "api/get_all_sectionId/",
  API_HOME_PAGE_COMMENT_PAGINATION: "api/comment_with_pagination/",
  API_ARTICLE_IMAGE_PREVIEW: "api/article_image/",
  API_RESET_PASSWORD: "api/reset_password/",
  API_FORGOT_PASSWORD: "api/forgot_password/",
  API_ENDPOINT_ADD_REMOVE_GROUP_PAGE_FROM_HOMEPAGE: "api/update_groups/",
  API_ENDPOINT_SAVE_USER_GROUP: "api/save_user_group/",
  API_ENDPOINT_REMOVE_SAVED_GROUP: "api/remove_saved_group/",
  API_ENDPOINT_LASTVISITEDARTICLES: "api/add_last_visited_articles/",
  API_ENDPOINT_ADDLASTVISITEDARTICLES: "api/add_last_visited_articles/",
  API_ENDPOINT_SAVECOMMENTS: "api/saveComment/",
  API_ENDPOINT_SEARCH_PARAGRAPH: "api/search_paragraph/7/?section_id=",
  API_ENDPOINT_Q_AND_A_LIST: "api/question_answer_by_articleId/",
  API_ENDPOINT_Q_AND_A_LIST_PAPER: "api/question_answer_by_paperId/",
  API_ENDPOINT_LIKE_UNLIKE_PDF_COMMENT: "api/likeUnlike_paper_comment/",
  API_ENDPOINT_LIKE_UNLIKE_PDF_QANDA: "api/like_unlike_paper_questions/",
  API_ENDPOINT_ADD_Q_AND_A: "api/add_question/",
  API_ENDPOINT_ADD_Q_AND_A_PAPER: "api/add_paper_question/",
  API_ENDPOINT_ADD_UPDATE_ANSWER: "api/addUpdate_answer/",
  API_ENDPOINT_ADD_UPDATE_PAPER_ANSWER: "api/addUpdate_paper_answer/",
  API_ENDPOINT_LINK_AND_UNLIKE_QUESTION: "api/like_unlike_questions/",
  API_ENDPOINT_DELETE_ANSWER: "api/reject_questions/",
  API_ENDPOINT_DELETE_PAPER_ANSWER: "api/reject_paper_questions/",
  API_ENDPOINT_PUSH_COMMENT: "api/add_push_comment/",
  API_ENDPOINT_DELETE_PUSH_COMMENT: "api/delete_push_comments/",
  API_ENDPOINT_CREATE_EVENT: "api/add_event/",
  API_ENDPOINT_ALL_EVENT: "api/get_event_list/",
  API_ENDPOINT_SEARCH_HUB: 'semantic-search-api/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
