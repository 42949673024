import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  jsonData;
  orderData: any[] = [];

  constructor() { }
  
  setJSONData(val: object) {
    this.jsonData = val;
  }

  getJSONData() {
    return this.jsonData;
  }
}
