import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "src/app/shared/services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "src/app/shared/services/notification.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";
import { Router } from "@angular/router";
import { element } from "protractor";
import { Platform } from "@angular/cdk/platform";
import { BrowserModule } from "@angular/platform-browser";
import { SlickCarouselComponent } from "ngx-slick-carousel";

declare let $: any;
@Component({
  selector: "app-books",
  templateUrl: "./books.component.html",
  styleUrls: ["./books.component.scss"],
})
export class BooksComponent implements OnInit {
  dataSource: any = [];
  groupDataSource: any;
  userId: any;
  error: any;
  trendingArticle: any;
  trendingData = [];
  groupData: any = [];
  unTrendingArtcle: any;
  unTrendingData = [];
  displayArticlePage: any;
  API_ENDPOINT: any;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          focusOnSelect: true,
        },
      },
    ],
  };

  slideConfig1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          focusOnSelect: true,
        },
      },
    ],
  };

  slides1 = [
    { img: "../../../assets/img/hero-hyperead.png", title: "The Iliad / Book" },
    {
      img: "../../../assets/img/hero-nietzsche.png",
      title: "Term and Conditions",
    },
    { img: "../../../assets/img/100nights.jpeg", title: "Documentation" },
    {
      img: "../../../assets/img/100nights.jpeg",
      title: "Crime and Punishment",
    },
    {
      img: "../../../assets/img/100nights.jpeg",
      title: "Crime and Punishment",
    },
  ];

  @ViewChild("slickModal", { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild("slickModaltab", { static: true })
  slickModaltab: SlickCarouselComponent;
  papersData: any;
  displayPaper: any;
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
  nexttab() {
    this.slickModaltab.slickNext();
  }
  prevtab() {
    this.slickModaltab.slickPrev();
  }
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    private router: Router,
    public platform: Platform
  ) {
    $("body").removeClass("loaded");
    this.userId = parseInt(localStorage.getItem("userId"));
    this.getAllArticles();

    this.sharedServiceService.footerHide = true;
    this.sharedServiceService.topicHide = true;
    this.sharedServiceService.logoHide = true;

    if (window.innerWidth <= 500 && window.innerHeight <= 900) {
      this.slideConfig.arrows = false;
    } else {
      this.slideConfig.arrows = true;
    }
  }

  ngOnInit() {
    this.API_ENDPOINT = "https://app.hyperead.org";
    this.sharedServiceService.isShowFooter = true;
    this.allGroupList();
    this.getAllPapers();
  }
  getAllPapers() {
    this.apiService.getAllPapers().subscribe(
      (data: any) => {
        this.papersData = data.data;
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  getAllArticles() {
    // this.spinner.show();
    this.apiService.getAllArticles().subscribe(
      (data: any) => {
        this.trendingData = [];
        this.unTrendingData = [];
        if (data) {
          this.dataSource = data;
          this.trendingArticle = this.dataSource.map((element) => {
            if (element.is_trending == true) {
              this.trendingData.push(element);
            } else {
              this.unTrendingData.push(element);
            }
          });
          this.trendingData = this.trendingData.sort((a, b) => b.id - a.id);
          if (this.trendingData && this.trendingData.length > 0) {
            for (let i = 0; i < this.trendingData.length; i++) {
              if (
                this.trendingData[i].article_like &&
                this.trendingData[i].article_like.length > 0
              ) {
                this.trendingData[i].is_like = false;
                this.trendingData[i].article_like.forEach((item) => {
                  if (item.user_id == this.userId) {
                    this.trendingData[i].is_like = true;
                  }
                });
              }
            }
          }
          if (this.unTrendingData && this.unTrendingData.length > 0) {
            for (let i = 0; i < this.unTrendingData.length; i++) {
              if (
                this.unTrendingData[i].article_like &&
                this.unTrendingData[i].article_like.length > 0
              ) {
                this.unTrendingData[i].is_like = false;
                this.unTrendingData[i].article_like.forEach((item) => {
                  if (item.user_id == this.userId) {
                    this.unTrendingData[i].is_like = true;
                  }
                });
              }
            }
          }
          $("body").addClass("loaded");
          // this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  allGroupList() {
    // this.spinner.show();
    this.apiService.getAllGroupsList().subscribe(
      (data) => {
        this.groupData = [];
        if (data) {
          this.groupDataSource = data;
          let groups = this.groupDataSource.map((element) => {
            if (element.is_displayed_on_home == true) {
              this.groupData.push(element);
            }
          });
          setTimeout(function () {
            $("body").addClass("loaded");
          }, 100);

          // this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  SaveArticle(id) {
    this.spinner.show();
    let responsJSON = {
      user: this.userId,
      article: id,
    };
    this.apiService.saveArticle(responsJSON).subscribe((data: any) => {
      if (data.message) {
        this.notifyService.showSuccess(data["message"]);
        this.spinner.hide();
        this.getAllArticles();
      } else {
        this.spinner.hide();
      }
    });
  }

  likeArticle(id, i) {
    this.spinner.show();
    this.dataSource.forEach((element) => {
      element.is_replied = true;
    });
    let responsJSON = {
      user: this.userId,
      article: id,
    };
    this.apiService.likeArticle(responsJSON).subscribe((data: any) => {
      if (data.message) {
        this.notifyService.showSuccess(data["message"]);
        this.getAllArticles();
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) {
    } else if (tabChangeEvent.index == 0) {
    }
  }

  deleteArtcle(id) {
    this.spinner.show();
    this.apiService.deleteArticle(id).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getAllArticles();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  trendingArticles(id) {
    this.spinner.show();
    this.apiService.trendingArticles(id).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getAllArticles();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  hideHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayArticlePage = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == true) {
        return (showArticle = false);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveArticle(id, requestedJson).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getAllArticles();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }
  hideHomePagePaper(id) {
    // this.spinner.show();
    let showPaper: any;
    this.displayPaper = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == true) {
        return (showPaper = false);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showPaper,
    };
    this.apiService.addRemovePaper(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(
            "Paper Remove Successfully from HomePage"
          );
          this.getAllPapers();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  hideGroupsHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayArticlePage = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == true) {
        return (showArticle = false);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveGroup(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess("Group status changed successfully");
          this.allGroupList();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  redirectToArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/article", id, Articlename]);
  }
  redirectToPaper(id) {
    this.router.navigate(["/pdf-viewer", id]);
    this.spinner.hide();
  }
  redirectToEditArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/add-article-editor", id, Articlename]);
  }

  redirectToReadingGroupPage(id, name) {
    let groupName = name.replace(/ +/g, "");
    this.router.navigate(["/reading-hub-page", id, groupName]);
  }

  shareArticle(itemdata) {
    let Articlename = itemdata.name.replace(/ +/g, "");
    if (itemdata.id && itemdata.name) {
      let image = "https://app.hyperead.org/" + itemdata.image;
      // window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url) + "&t=" + encodeURIComponent(document.title),
      //   'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(image) +
          "&quote=" +
          itemdata.description
      );
      return false;
    }
  }

  pushHomePage(id) {}
}
