import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { MatDialog } from '@angular/material';
import { ReadingHubComponent } from '../reading-hub/reading-hub.component';
import { NotificationService } from '../../shared/services/notification.service';
import { InviteFriendsDialogComponent } from '../members/invite-friends-dialog/invite-friends-dialog.component';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  dataSource: any;
  favoriteEvents: any;
  error: any;
  localStorageLogin: any;
  API_ENDPOINT: string;
  constructor(private apiService: ApiService,
    public commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public sharedServiceService: SharedServiceService,
    private notifyService: NotificationService,
    public dialog: MatDialog) {
  }
  ngOnInit() {
    this.API_ENDPOINT = 'https://app.hyperead.org';
    this.getAllSelectedArtical();
    this.getAllFavoriteEvents();
  }

  getAllSelectedArtical() {
    if(localStorage.getItem('isLoggedin')){
      this.spinner.show();
      this.apiService.getAllUserSelcetdGroupsList().subscribe(data => {
        if (data) {
          this.dataSource = data;
          this.sharedServiceService.groups = this.dataSource;
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
        this.localStorageLogin = 0
      });
    }
    
  }

  getAllFavoriteEvents() {
    if(localStorage.getItem('isLoggedin')){
      this.spinner.show();
      this.apiService.getAllFavoriteEvents().subscribe(data => {
        if (data) {
          this.favoriteEvents = data;
          this.sharedServiceService.favorite_events = this.favoriteEvents;
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
        this.localStorageLogin = 0
      });
    }
  }

  closeMenu() {
    var el: any = document.querySelector(".show_sidenav");
    if (el) {
      el.classList.remove('show_sidenav');
    }
  }

  openRedingHub() {
    const dialogRef = this.dialog.open(ReadingHubComponent, { panelClass: 'custom-modalbox' });
  }

  redirectToArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(['/group-page', id, Articlename]);
  }

  redirectToReadingGroupPage(id, name) {
    let groupName = name.replace(/ +/g, "");
    this.router.navigate(['/reading-hub-page', id, groupName]);
  }

  openInviteDialog() {
    const dialogRef = this.dialog.open(InviteFriendsDialogComponent, { panelClass: 'custom-modalbox' });
  }

  redirectToCreateEvent() {
    this.router.navigate(['/upcoming-reading-events']);
  }

  removeArtical(id) {
    this.spinner.show();
    this.apiService.deletedSelectedartical(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getAllSelectedArtical();
        this.spinner.hide();
        //this.router.navigate(['/home']);
      }
    })
  }
}
