import { Component, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData, GroupPageComponent } from '../group-pagecomponent';

@Component({
  selector: 'app-report-group-dialog',
  templateUrl: './report-group-dialog.component.html',
  styleUrls: ['./report-group-dialog.component.scss']
})
export class ReportGroupDialogComponent implements OnInit {

  isValid = false;
  id: any

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private notifyService: NotificationService,
    public dialogRef: MatDialogRef<GroupPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.id = this.data;
  }

  public reportGroupForm: FormGroup = this.formBuilder.group({
    reportMessage: ['', [Validators.required, Validators.maxLength(2000)]]
  });

  get rForm() {
    return this.reportGroupForm.controls;
  }

  ngOnInit() {
  }

  reportGroup() {
    if (this.reportGroupForm.invalid) {
      return this.isValid = true;
    } else {
      this.isValid = false;
      let message = {
        "message": this.rForm.reportMessage.value
      }
      this.spinner.show();
      this.apiService.reportGroup(this.id, message).subscribe((data: any) => {
        if (data) {
          this.notifyService.showSuccess(data['message']);
          this.spinner.hide();
        }
      });
      this.dialogRef.close();
    }
  }

}
