import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ApiService } from "src/app/shared/services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "src/app/shared/services/notification.service";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";
import { Router } from "@angular/router";
import { element } from "protractor";
import { Platform } from "@angular/cdk/platform";
import { SearchResultPopupComponent } from "src/app/shared/components/search-result-popup/search-result-popup.component";
import { BrowserModule } from "@angular/platform-browser";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { LOADIPHLPAPI } from "dns";
import { LoginComponent } from "src/app/login/login.component";

declare let $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  dataSource: any = [];
  groupDataSource: any;
  userId: any;
  error: any;
  trendingArticle: any;
  trendingData = [];
  groupData: any = [];
  unTrendingArtcle: any;
  unTrendingData = [];
  displayArticlePage: any;
  API_ENDPOINT: any;
  showPinnerForSearch1 = false;
  searchQuestion = "";
  allAnswers: any[];
  noQuery = false;
  noQuery3 = false;
  noQuery4 = false;
  searchQuery = false;
  allAnswersList = [];
  showPinner = false;
  showPinnerForSearch2 = false;
  searchText;
  value:any;
  search
  showPinnerForSearch3 = false;
  showPinnerForSearch4 = false;

  @ViewChild('recordPopup', { static: true }) recordPopup: TemplateRef<any>;

  questions = [
    {filename:'main', query:'What is global warming and how does it occur?'},
    {filename:'main', query:'What is the primary cause of global warming?'},
    {filename:'main', query:'What is the primary cause of global warming?'},
    {filename:'main', query:'What are the impacts of global warming?'},
    {filename:'main', query:'How global warming effect other environmental issues?'}
    // "What is global warming and how does it occur?", "What is the primary cause of global warming?", "What are the impacts of global warming?", "How global warming effect other environmental issues?"
  ]

  setQuestion:any;

  slideConfig = {
    slidesToShow: 11,
    slidesToScroll: 8,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    easing: 'easeOutElastic',
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
          focusOnSelect: true,
          dots: false,
        },
      },
    ],
  };

  slideConfig1 = {
    slidesToShow: 6,
    slidesToScroll: 5,
    autoplay: true,
    dots: true,
    arrows: true,
    prevArrow: false,
    easing: 'easeOutElastic',
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
          focusOnSelect: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          focusOnSelect: true,
          dots: false,
        },
      },
    ],
  };

  slides1 = [
    {
      img: "../../../assets/img/phone-hero.png",
      title: "Ask questions, and add your comments inside every book",
    },
    {
      img: "../../../assets/img/READING-HUB-HERO.png",
      title:
        "Join our reading hubs, a curated collaction of texts with reading guidence from experienced teachers. ",
    },
    {
      img: "../../../assets/img/book-political.png",
      title: "Read books in a social reading format",
    },
    {
      img: "../../../assets/img/READING-HUB-HERO.png",
      title: "Crime and Punishment",
    },
    {
      img: "../../../assets/img/READING-HUB-HERO.png",
      title: "Crime and Punishment",
    },
  ];

  @ViewChild("slickModal", { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild("slickModaltab", { static: true })
  slickModaltab: SlickCarouselComponent;
  papersData: any;
  displayPaper: any;
  displayGroupPage: any;
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
  nexttab() {
    this.slickModaltab.slickNext();
  }
  prevtab() {
    this.slickModaltab.slickPrev();
  }
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    public dialog: MatDialog,
    private router: Router,
    public platform: Platform,
    private elref: ElementRef
  ) {
    $("body").removeClass("loaded");
    this.userId = parseInt(localStorage.getItem("userId"));
    this.getAllArticles();

    this.sharedServiceService.footerHide = true;
    this.sharedServiceService.topicHide = true;
    this.sharedServiceService.logoHide = true;

    if (window.innerWidth <= 500 && window.innerHeight <= 900) {
      this.slideConfig.arrows = false;
    } else {
      this.slideConfig.arrows = true;
    }
  }

  ngOnInit() {
    this.API_ENDPOINT = "https://app.hyperead.org";
    this.sharedServiceService.isShowFooter = true;
    this.allGroupList();
    this.getAllPapers();
  }
  
  getAllPapers() {
    this.apiService.getAllPapers().subscribe(
      (data: any) => {
        this.papersData = data.data;
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  getSearchHubData(fileName) {
    
    let file_name = fileName;
    fileName = fileName.toLowerCase();
    if (fileName.includes(" ")) {
      fileName = fileName.split(" ").join("_");
    } else if (fileName.includes("-")) {
      fileName = fileName.split(" ").join("_");
    }
    this.noQuery = false;
    this.searchQuery = true;
    this.spinner.show();
    this.allAnswers = [];
    if (this.searchQuestion && fileName) {
      let requestJson = {
        query: this.searchQuestion,
        file_name: "main",
      };
      this.apiService.getSearchHubData(requestJson).subscribe(
        (data: any) => {
          this.allAnswers = data.answer_list;
          const dialogRef = this.dialog.open(SearchResultPopupComponent, {
            height: "90vh",
            width: "85vw",
            maxWidth: "85vw",
            data: {
              allAnswers: data.answer_list,
              query: this.searchQuestion,
              collection: file_name,
            },
          });
          // dialogRef.afterClosed().subscribe((result) => {
          //   this.searchQuestion = "";
          // });
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.notifyService.showError("No results found");
        }
      );
    } else {
      if (this.searchQuestion == "") {
        this.noQuery = true;
      } else {
        this.notifyService.showError("No results found");
      }
      this.spinner.hide();
    }
  }

  getAllArticles() {
    // this.spinner.show();
    this.apiService.getAllArticles().subscribe(
      (data: any) => {
        this.trendingData = [];
        this.unTrendingData = [];
        if (data) {
          this.dataSource = data;
          this.trendingArticle = this.dataSource.map((element) => {
            if (element.is_trending == true) {
              this.trendingData.push(element);
            } else {
              this.unTrendingData.push(element);
            }
          });
          this.trendingData = this.trendingData.sort((a, b) => b.id - a.id);
          if (this.trendingData && this.trendingData.length > 0) {
            for (let i = 0; i < this.trendingData.length; i++) {
              if (
                this.trendingData[i].article_like &&
                this.trendingData[i].article_like.length > 0
              ) {
                this.trendingData[i].is_like = false;
                this.trendingData[i].article_like.forEach((item) => {
                  if (item.user_id == this.userId) {
                    this.trendingData[i].is_like = true;
                  }
                });
              }
            }
          }
          if (this.unTrendingData && this.unTrendingData.length > 0) {
            for (let i = 0; i < this.unTrendingData.length; i++) {
              if (
                this.unTrendingData[i].article_like &&
                this.unTrendingData[i].article_like.length > 0
              ) {
                this.unTrendingData[i].is_like = false;
                this.unTrendingData[i].article_like.forEach((item) => {
                  if (item.user_id == this.userId) {
                    this.unTrendingData[i].is_like = true;
                  }
                });
              }
            }
          }
          $("body").addClass("loaded");
          // this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }


  


  allGroupList() {
    // this.spinner.show();
    this.apiService.getAllGroupsList().subscribe(
      (data) => {
        this.groupData = [];
        if (data) {
          this.groupDataSource = data;
          let groups = this.groupDataSource.map((element) => {
            if (element.is_displayed_on_home == true) {
              this.groupData.push(element);
            }
          });
          setTimeout(function () {
            $("body").addClass("loaded");
          }, 100);

          // this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  SaveArticle(id) {
    this.spinner.show();
    let responsJSON = {
      user: this.userId,
      article: id,
    };
    this.apiService.saveArticle(responsJSON).subscribe((data: any) => {
      if (data.message) {
        this.notifyService.showSuccess(data["message"]);
        this.spinner.hide();
        this.getAllArticles();
      } else {
        this.spinner.hide();
      }
    });
  }

  likeArticle(id, i) {
    this.spinner.show();
    this.dataSource.forEach((element) => {
      element.is_replied = true;
    });
    let responsJSON = {
      user: this.userId,
      article: id,
    };
    this.apiService.likeArticle(responsJSON).subscribe((data: any) => {
      if (data.message) {
        this.notifyService.showSuccess(data["message"]);
        this.getAllArticles();
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 1) {
    } else if (tabChangeEvent.index == 0) {
    }
  }

  deleteArtcle(id) {
    this.spinner.show();
    this.apiService.deleteArticle(id).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getAllArticles();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  trendingArticles(id) {
    this.spinner.show();
    this.apiService.trendingArticles(id).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getAllArticles();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  hideHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayArticlePage = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == true) {
        return (showArticle = false);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveArticle(id, requestedJson).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.getAllArticles();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  hideHomePagePaper(id) {
    // this.spinner.show();
    let showPaper: any;
    this.displayPaper = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == true) {
        return (showPaper = false);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showPaper,
    };
    this.apiService.addRemovePaper(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(
            "Paper Remove Successfully from HomePage"
          );
          this.getAllPapers();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  hideGroupsHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayArticlePage = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == true) {
        return (showArticle = false);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveGroup(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess("Group status changed successfully");
          this.allGroupList();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  redirectToArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/article", id, Articlename]);
  }
  redirectToPaper(id) {
    this.router.navigate(["/pdf-viewer", id]);
    this.spinner.hide();
  }
  redirectToEditArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/add-article-editor", id, Articlename]);
  }

  redirectToReadingGroupPage(id, name) {
    let groupName = name.replace(/ +/g, "");
    this.router.navigate(["/reading-hub-page", id, groupName]);
  }

  shareArticle(itemdata) {
    let Articlename = itemdata.name.replace(/ +/g, "");
    if (itemdata.id && itemdata.name) {
      let image = "https://app.hyperead.org/" + itemdata.image;
      // window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url) + "&t=" + encodeURIComponent(document.title),
      //   'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(image) +
          "&quote=" +
          itemdata.description
      );
      return false;
    }
  }

  

  pushHomePage(id) {
    this.spinner.show();
    let showArticle: any;
    this.displayGroupPage = this.dataSource.map((element) => {
      if (element.is_displayed_on_home == false) {
        return (showArticle = true);
      }
    });
    let requestedJson = {
      is_displayed_on_home: showArticle,
    };
    this.apiService.addRemoveGroup(id, requestedJson).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess("Group status changed successfully");
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  getData(item){
    this.setQuestion = item
  }

  getSearchHubData2(query, fileName) {
    
    
    this.allAnswersList = [];
    let requestJson = {
      query: query,
      file_name: fileName,
    };
    this.apiService.getSearchHubData(requestJson).subscribe(
      (data: any) => {
        this.dialog.open(this.recordPopup,{
          width: '80%',
          maxHeight: 'calc(100vh - 90px)',
          height : 'auto',
        });
        this.allAnswersList = data.answer_list;
        this.showPinnerForSearch1 = false;
      },
      (error) => {
        this.showPinnerForSearch1 = false;
      }
    );
  }

  searchQuery2(value) {
    console.log("value", value);
    
    if(value == "" || value == undefined){
      this.noQuery = true;
    }else{
      this.showPinnerForSearch1 = true;
      this.noQuery = false;
      this.getSearchHubData2(value, "main" )
    }
  }

  getSearchHubData3(query, fileName) {
    
    this.showPinnerForSearch3 = true;
    this.allAnswersList = [];
    let requestJson = {
      query: query,
      file_name: fileName,
    };
    this.apiService.getSearchHubData(requestJson).subscribe(
      (data: any) => {
        this.dialog.open(this.recordPopup,{
          width: '80%',
          maxHeight: 'calc(100vh - 90px)',
          height : 'auto',
        });
        this.allAnswersList = data.answer_list;
        this.showPinnerForSearch3 = false;
      },
      (error) => {
        this.showPinnerForSearch3 = false;
      }
    );
  }

  searchQuery3(value) {
    
    if(value == "" || value == undefined){
      this.noQuery3 = true;
      this.showPinnerForSearch3 = false;
    }else{
      this.noQuery3 = false;
      this.getSearchHubData3(value, "main" )
    }
  }

  getSearchHubData4(query, fileName) {
    
    this.showPinnerForSearch4 = true;
    this.allAnswersList = [];
    let requestJson = {
      query: query,
      file_name: fileName,
    };
    this.apiService.getSearchHubData(requestJson).subscribe(
      (data: any) => {
        this.dialog.open(this.recordPopup,{
          width: '80%',
          maxHeight: 'calc(100vh - 90px)',
          height : 'auto',
        });
        this.allAnswersList = data.answer_list;
        this.showPinnerForSearch4 = false;
      },
      (error) => {
        this.showPinnerForSearch4 = false;
      }
    );
  }

  searchQuery4(value) {
    
    if(value == "" || value == undefined){
      this.noQuery4 = true;
      this.showPinnerForSearch4 = false;
    }else{
      this.spinner.show();
      this.noQuery4 = false;
      this.getSearchHubData4(value, "main" )
    }
  }

  highlightedText(context: string, answer: string) {
    
    if (!answer && !context) {
      return context;
    }
    return context.replace(new RegExp(answer, "gi"), (match) => {
      return (
        '<span class="highlightedAnswer font-weight-bold">' + match + "</span>"
      );
    });
  }

  gotToParagraph(aid, aname, pid) {
    
    if (aid) {
      this.apiService.getArticleID(aid).subscribe(
        async (data: any) => {
          if (data) {
            if (aname) {
              this.router.navigate(["/article", aid, aname]);
            }
            if (pid) {
              this.sharedServiceService.scrollSectionId = pid;
            }
          }
        },
        (error) => {
          this.error = error;
          this.notifyService.showError("Article Not found.");
        }
      );
    } else {
      this.notifyService.showError("Article Not found.");
    }
  }
}
