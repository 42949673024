import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  public profileUpdate: FormGroup = this.formBuilder.group({
    firstName: new FormControl('', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]),
    LastName: new FormControl('', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]),
    about: new FormControl('', [Validators.maxLength(254), Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]),
  })

  public profileImage: FormGroup = this.formBuilder.group({
    image: new FormControl(''),
  })
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get fval() {
    return this.profileUpdate.controls;
  }
  dataSource: any;
  userID: any;
  ptofileImage: File = null;
  previewUrlLogo: any = null;
  error: any;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    public sharedServiceService: SharedServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService) {
  }

  ngOnInit() {
    this.getProfileDetails();
  }

  getProfileDetails() {
    this.spinner.show();
    this.userID = localStorage.getItem('userId')
    this.apiService.getUserDetails(this.userID).subscribe(data => {
      if (data) {
        this.dataSource = data;
        this.profileUpdate.controls['firstName'].setValue(this.dataSource['first_name']);
        this.profileUpdate.controls['LastName'].setValue(this.dataSource['last_name']);
        if (this.dataSource.customuser['about']) {
          this.profileUpdate.controls['about'].setValue(this.dataSource.customuser['about']);
        }
        this.spinner.hide();
      }
    });
  }

  uploadFileClick() {
    document.getElementById("profileImageUpdate").click();
  }

  profileLogo(fileInput: any) {
    if (fileInput.target.files[0].type === "image/jpeg" || fileInput.target.files[0].type === "image/png") {
      this.ptofileImage = <File>fileInput.target.files[0];

      var mimeTypes = this.ptofileImage.type;
      if (mimeTypes.match(/image\/*/) == null) {
        return;
      }

      var read = new FileReader();
      read.readAsDataURL(this.ptofileImage);
      read.onload = (e: any) => {
        this.previewUrlLogo = e.target.result
      }
    } else {
      this.notifyService.showError("Please select JPEG or PNG image")
    }
  }

  onProfileUpdate() {
    if (this.profileUpdate.invalid) {
      return;
    }
    this.spinner.show();
    let formData: FormData = new FormData();
    formData.append('first_name', this.profileUpdate.controls['firstName'].value);
    formData.append('last_name', this.profileUpdate.controls['LastName'].value);
    formData.append('about', this.profileUpdate.controls['about'].value);

    if (this.ptofileImage) {
      formData.append('avatar', this.ptofileImage);
    }
    if (this.profileUpdate.valid) {
      this.apiService.updateUserDetails(this.dataSource.customuser.id, formData).subscribe(data => {
        if (data) {
          this.notifyService.showSuccess('Profile Updated successfully.');
          this.sharedServiceService.avatar = data['avatar']
          localStorage.setItem('avatar', data['avatar']);
          this.ngOnInit();
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
  }

  cancelBtn() {
    this.route.navigate(['/home'])
  }

  removeProfile(image) {
    let formData: FormData = new FormData();
    image = '';
    formData.append('avatar', image);
    formData.append('first_name', this.profileUpdate.controls['firstName'].value);
    formData.append('last_name', this.profileUpdate.controls['LastName'].value);
    formData.append('about', this.profileUpdate.controls['about'].value);
    this.spinner.show();
    this.apiService.updateUserDetails(this.dataSource.customuser.id, formData).subscribe(data => {
      if (data) {
        localStorage.removeItem('avatar');
        this.notifyService.showSuccess("Profile removed successfully.")
        this.sharedServiceService.avatar = '';
        this.dataSource.customuser.avatar = '';
        this.previewUrlLogo = null;
        this.ngOnInit();
        this.spinner.hide();
      }
    });
  }

}
