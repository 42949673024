import { Component, OnInit } from "@angular/core";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from "src/app/shared/services/common.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { ReadingHubComponent } from "../reading-hub/reading-hub.component";
import { NotificationService } from "../../shared/services/notification.service";
import { ApiService } from "src/app/shared/services/api.service";

declare let $: any;
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  footerHide = false;
  isloginFooter = false;
  isLogin: any;
  isMobile = false;
  isOpen = false;
  dataSource: any;
  panelOpenState = false;
  constructor(
    public sharedServiceService: SharedServiceService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    public commonService: CommonService,
    private router: Router,
    private notifyService: NotificationService,
    public dialog: MatDialog
  ) {
    // if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //  this.isMobile = true;
    //  }else{
    //   this.isMobile = false;
    //  }
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (
      macosPlatforms.indexOf(platform) !== -1 ||
      iosPlatforms.indexOf(platform) !== -1 ||
      windowsPlatforms.indexOf(platform) !== -1
    ) {
      this.isMobile = false;
      if (this.isMobile == false) {
        if (
          /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }
    }
  }

  ngOnInit() {
    this.footerchanges();
  }

  setDiv() {
    if (localStorage.getItem("isLoggedin")) {
      this.apiService.getAllUserSelcetdGroupsList().subscribe(
        (data) => {
          if (data) {
            this.isOpen = true;
            this.dataSource = data;
          }
        },
        (error) => {}
      );
    }
  }

  footerchanges() {
    this.spinner.show();
    this.isLogin = localStorage.getItem("isLoggedin");
    if (this.isLogin) {
      this.isloginFooter = true;
      this.footerHide = true;
      this.spinner.hide();
    } else {
      this.isloginFooter = false;
      this.footerHide = true;
      this.spinner.hide();
    }
  }

  closeMenu() {
    this.isOpen = false;
    var el: any = document.querySelector(".show_sidenav");
    if (el) {
      el.classList.remove("show_sidenav");
    }
  }

  redirectToArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(["/group-page", id, Articlename]);
  }

  redirectToReadingGroupPage(id, name) {
    this.isOpen = false;
    $("body").removeClass("loaded");
    let groupName = name.replace(/ +/g, "");
    this.router.navigate(["/reading-hub-page", id, groupName]);
    $("body").addClass("loaded");
  }

  removeArtical(id) {
    this.apiService.deletedSelectedartical(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data["message"]);
        this.isOpen = false;
        this.router.navigate(["/home"]);
      }
    });
  }

  openRedingHub() {
    const dialogRef = this.dialog.open(ReadingHubComponent, {
      panelClass: "custom-modalbox",
    });
  }

  closeSlider() {
    this.isOpen = false;
  }
}
