import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from './shared/services/shared-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from '@angular/common';
declare let $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService, 
    public sharedServiceService: SharedServiceService, 
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private location: PlatformLocation) {
      $('body').removeClass('loaded');
      this.rltAndLtr();
    location.onPopState(() => {
      let loginUrl = window.location.pathname
      if (loginUrl == '/login') {
        this.sharedServiceService.headerdata = false
        localStorage.clear();
      }
    });
  }

  ngOnInit() {
    setTimeout(x=>{ $('body').addClass('loaded');},1000)
    this.sharedServiceService.footerHide = true;  
    if (localStorage.getItem('isLoggedin') === 'true') {
      this.sharedServiceService.headerdata = true;
    } else {
      this.sharedServiceService.headerdata = false;
    }
  }

  
  
  
  rltAndLtr() {
    const dom: any = document.querySelector('body');
    this.translate.addLangs(['en', 'he']);
    // console.log(localStorage.getItem('lang'));
    // if(localStorage.getItem('lang') == 'en'){
    //   this.translate.use('en');
    //   dom.classList.add('ltr');
    //   dom.classList.remove('rtl');
    // } else {
    //   this.translate.use('he');
    //   dom.classList.add('rtl');
    //   dom.classList.remove('ltr');
    // }
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dom.classList.add('ltr');
    dom.classList.remove('rtl');
  }
}