import { ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, Renderer, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/api.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
declare let $: any;
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @ViewChild('drawer', { static: true }) public drawer: MatDrawer;
  nonWhitespaceRegExp: RegExp = new RegExp("\\S");
  public qandaForm: FormGroup = this.formBuilder.group({
    question: ['', [Validators.pattern(".*\\S.*[a-zA-z0-9 ]"), Validators.required]],
  });
  public answerForm: FormGroup = this.formBuilder.group({
    answer: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
  });
  pdfSrc: any;
  API_ENDPOINT: string;
  changeLang: any;
  sideNavMenu: boolean = true;
  id: any;
  pdfZoom: any = 1;
  menuButton: boolean = true;
  userId: any;
  href: string;
  isSubmitted = false;
  getPdfData = [];
  showFiller = false;
  error: any;
  pdfComment: any;
  pdfPaperAllComments: any
  annotationsMenu: boolean;
  commentForm: boolean;
  sourceHide: boolean;
  isAnnotations = false;
  replyPrivateIndex: number;
  relatedId: string;
  isShowPassage: boolean;
  selectedPdf: any[];
  annotationComentEdit: boolean;
  currentAnnotationCID: any;
  reply_comment: any;
  AddComment: boolean;
  closeReplyBox: any;
  isVisibleZoom: boolean;
  sectionId: any;
  qandaListData: any;
  get qForm() {
    return this.qandaForm.controls;
  }
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public sharedServiceService: SharedServiceService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
    public _ngZone: NgZone,
    private routes: Router,
    public dialog: MatDialog) {
    $('body').removeClass('loaded');
    this.route.params.subscribe(
      params => {
        this.id = params['id'];
      });
    this.href = this.router.url;
    this.sharedServiceService.footerHide = false;
    this.sharedServiceService.topicHide = false;
    this.sharedServiceService.logoHide = false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  ngOnInit() {
    this.sharedServiceService.setDrawer(this.drawer);
    this.userId = localStorage.getItem('userId');
    this.getPdfPaper(this.id);
    this.getPdfPaperComment(this.id);
    this.getQandAListData();
    this.sideNavMenu = true;
  }
  getPdfPaper(id) {
    this.API_ENDPOINT = 'https://app.hyperead.org';
    this.apiService.getPdfID(id).subscribe(async (data: any) => {
      this.getPdfData.push(data);
      this.pdfSrc = this.API_ENDPOINT + data.file;
      this.spinner.hide();
      $('body').addClass('loaded');

    }, error => {
      this.error = error;
      this.notifyService.showError('PDF Paper Not found.');
      this.router.navigate(['/home']);
    });
  }
  getPdfPaperComment(id) {
    this.apiService.getPdfPaperComment(id).subscribe(async (data: any) => {
      this.pdfPaperAllComments = data.data;
      if (this.pdfPaperAllComments.length === 0) {
        this.AddComment = true;
      }
    }, error => {
      this.error = error;
      this.notifyService.showError('No comment Available on PDF Paper');
      this.router.navigate(['/home']);
    });
  }
  pdfZoomControl() {
    this.pdfZoom = this.pdfZoom + 0.25
  }
  pdfZoomOutControl() {
    this.pdfZoom = this.pdfZoom - 0.25
  }
  postComment() {
    if (this.pdfComment == '') {
      return;
    } else {
      if (this.pdfComment.startsWith(" ")) {
        return;
      }
      let requestedJson = {
        "text": this.pdfComment,
        "user": this.sharedServiceService.loggedUser,
        "paper": this.id,
      }
      this.spinner.show();
      if (this.annotationComentEdit) {
        let editarticle = {
          "text": this.pdfComment,
          "paper": this.id,
        }
        this.apiService.paperCommentsUpdate(this.currentAnnotationCID, editarticle).subscribe((data: any) => {
          if (data) {
            this.getPdfPaperComment(this.id);
            this.pdfComment = '';
            this.spinner.hide();
            this.annotationComentEdit = false;
            this.currentAnnotationCID = '';
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
          this.pdfComment = '';
          this.annotationComentEdit = false;
          this.currentAnnotationCID = '';
        });
      } else {
        this.apiService.addPaperComments(requestedJson).subscribe(data => {
          if (data) {
            this.getPdfPaperComment(this.id);
            this.selectedPdf = [];
            this.pdfComment = '';
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
        });
      }
    }
  }
  saveComments(id, i) {
    this.spinner.show();
    let requestJson = {
      "text": this.reply_comment,
      "paper_comment": id,
      "user": this.userId,
    }
    this.apiService.paperCommentsReply(requestJson).subscribe((data: any) => {
      if (data) {
        this.pdfPaperAllComments[i].paper_comment_reply = false;
        this.getPdfPaperComment(this.id);
        this.spinner.hide();
        this.reply_comment = '';
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }
  editAnnotationCoomment(i) {
    this.pdfComment = this.pdfPaperAllComments[i].text;
    this.annotationComentEdit = true;
    this.currentAnnotationCID = this.pdfPaperAllComments[i].id;
  }
  backClicked() {
    this.routes.navigate(['/home']);
  }
  toggleDrawer() {
    $('.tableContent').removeClass('tableContent');
    $('.highlight').removeClass('highlight');
    $('.paraHighlight').removeClass('paraHighlight');
    this.sharedServiceService.toggle();
    this.annotationsMenu = false;
    this.commentForm = false;
    this.sourceHide = false;
    this.menuButton = true;
  }
  replyConversionComment(i) {
    let reply = this.pdfPaperAllComments[i].paper_comment_reply;
    this.replyPrivateIndex = i;
    this.pdfPaperAllComments.map(x => {
      x.is_replied = false;
      return x;
    });
    this.closeReplyBox = 1;

    this.reply_comment = '';
  }
  closeReplyConversionComment(i) {
    let reply = [];
    this.closeReplyBox = reply;
  }
  submitQandA() {
    this.spinner.show();
    if (this.qandaForm.invalid) {
      this.isSubmitted = true
      return false;
    }
    let responsJSON = {
      "questionBy_user": this.userId,
      "paper": this.id,
      "question": this.qandaForm.value.question
    }
    this.apiService.addQandAPaper(responsJSON).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.ngOnInit();
        this.getQandAListData();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
    this.spinner.hide();
  }
  getQandAListData() {
    this.apiService.getQandAListPaper(this.id).subscribe((res: any) => {
      if (res.data) {
        this.qandaListData = res.data;
        setTimeout(function () {
          $('body').addClass('loaded');
        }, 1000);
      }
    })
  }
  openAnnotations() {
    this.isAnnotations = true;
  }
  closeDrawer() {
    this.sharedServiceService.close();
  }
  closerelated() {
    this.relatedId = "";
    this.isShowPassage = false;
    this.ngOnInit();
  }
  linkandunlikeData(id) {
    this.apiService.likeUnlikePdfComment(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getPdfPaperComment(this.id);
        this.ngOnInit();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }
  linkandunlikeQandA(id) {
    this.apiService.likeUnlikePdfQandA(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getPdfPaperComment(this.id);
        this.ngOnInit();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }
  closeAnnotations() {
    this.isAnnotations = false;
    this.commentForm = false;
    // this.commentAddedForm = false;
  }
  postAnswer(id) {
    this.spinner.show();
    if (this.answerForm.invalid) {
      return false;
    }
    let responsJSON = {
      "answer": this.answerForm.value.answer
    }
    this.apiService.postPaperAnswerData(id, responsJSON).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.ngOnInit();
        this.getQandAListData();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
    this.spinner.hide();
  }
  rejectQuestionData(id) {
    this.apiService.rejectPaperQuestion(id).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getQandAListData();
        this.ngOnInit();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    })

  }
  closeList() {
    this.pdfPaperAllComments = false;
    this.sideNavMenu = true;
    this.menuButton = true;
  }
}
