import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SharedServiceService } from '../shared/services/shared-service.service';
import { NotificationService } from '../shared/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { SlickCarouselComponent } from 'ngx-slick-carousel';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup = this.formBuilder.group({
        email: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });

    loginBtn: boolean = false;
    error: any;
    dialogId: any;

    get fval() {
        return this.loginForm.controls;
    }

    constructor(public router: Router,
        public sharedServiceService: SharedServiceService,
        private api: ApiService,
        private formBuilder: FormBuilder,
        private notifyService: NotificationService,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog) { }

    ngOnInit() {
    }

    onLoggedin() {
        this.loginBtn = true;
        const requestJson = {
            "username": this.loginForm.controls.email.value,
            "password": this.loginForm.controls.password.value
        }
        if (this.loginForm.valid) {
            this.spinner.show();
            this.api.login(requestJson).subscribe((response: any) => {
                if (response.error) {
                    localStorage.clear();
                    this.notifyService.showError(response['error']);
                    this.loginForm.controls.password.setValue('');
                    this.spinner.hide();
                } else {
                    this.sharedServiceService.headerdata = true
                    this.sharedServiceService.withoutLoginHeaderData = false
                    localStorage.setItem('isLoggedin', 'true');
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('fullName', response.first_name + ' ' + response.last_name);
                    localStorage.setItem('role', response.is_superuser);
                    localStorage.setItem('userId', response.id);
                    localStorage.setItem('is_superuser', response.is_superuser);
                    localStorage.setItem('avatar', response.avatar);
                    this.sharedServiceService.avatar = response.avatar;
                    this.router.navigateByUrl('/home');

                    this.sharedServiceService.isAdimin = localStorage.getItem("is_superuser");
                    location.reload();
                    this.spinner.hide();

                }
            }, error => {
                this.error = error;
                this.spinner.hide();
            });
        }
    }

    forgetPassword() {
        const dialogRef = this.dialog.open(ForgetPasswordComponent, { panelClass: 'reset-popup' });
    }




}
