import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../shared/services/notification.service';
import { MatDialogRef } from '@angular/material';
import { LoginComponent } from '../login/login.component';

let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
    public forgetPasswordForm: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });

    submitted = false;
    error: any;

    constructor(public router: Router,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private apiService: ApiService,
        private notifyService: NotificationService,
        public dialogRef: MatDialogRef<LoginComponent>) { }

    get form() {
        return this.forgetPasswordForm.controls;
    }

    ngOnInit() { }

    onForgetPasswordSubmit() {
        if (this.forgetPasswordForm.invalid) {
            return this.submitted = true;
        } else {
            this.submitted = false;
            const requestJson = {
                "email": this.form.email.value
            }
            this.spinner.show();
            this.apiService.forgotPassword(requestJson).subscribe((response: any) => {
                if (response.message) {
                    this.notifyService.showSuccess(response['message']);
                    this.dialogRef.close();
                    this.spinner.hide();
                }
                else {
                    this.notifyService.showError(response['error']);
                    this.spinner.hide();
                }
            });

        }
    }
}

