import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './layout/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material/material-module';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MembersComponent, ManageMembersListComponent } from './layout/members/members.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { NewTopicComponent, addNewTopicEditor } from './layout/new-topic/new-topic.component';
import { MyProfileComponent } from './layout/my-profile/my-profile.component';
import { ApiService } from './shared/services/api.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddArticleComponent, CreateArticleDiaload, SearchBookPublicLibrary } from './layout/add-article/add-article.component';
import { ArticleComponent } from './layout/article/article.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { FilterPipePipe } from './shared/pipes/filter-pipe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { PublicCommentsComponent } from './layout/public-comments/public-comments.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GroupPageComponent } from './layout/group-page/group-pagecomponent';
import { GroupMembersDialogComponent } from './layout/group-page/group-members-dialog/group-members-dialog.component';
// import { CKEditorModule } from 'ngx-ckeditor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AddArticleInfoPageComponent } from './layout/add-article-info-page/add-article-info-page.component';
import { SharedServiceService } from './shared/services/shared-service.service';
import { CommonService } from './shared/services/common.service';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './layout/banner/banner.component';
import { DatePipe } from '@angular/common';
import { AuthGuard } from './shared/auth/auth.guard';
import { ToasterService } from './shared/services/toaster.service';
import { MyCommentComponent } from './layout/my-comment/my-comment.component';
import { NgpSortModule } from "ngp-sort-pipe";
import { SafeHtmlPipePipe } from './shared/pipes/safe-html-pipe.pipe';
import { ReportGroupDialogComponent } from './layout/group-page/report-group-dialog/report-group-dialog.component';
import { AdminMessageDialogComponent } from './layout/group-page/admin-message-dialog/admin-message-dialog.component';
import { InviteFriendsDialogComponent } from './layout/members/invite-friends-dialog/invite-friends-dialog.component';
import { ReadingHubComponent } from './layout/reading-hub/reading-hub.component';
import { MatMenuModule } from '@angular/material/menu';
import { InviteFriendsComponent } from './layout/article/invite-friends/invite-friends.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReadingHubPageComponent } from './layout/reading-hub-page/reading-hub-page.component';
import { ReadingHubGroupsComponent } from './layout/reading-hub-groups/reading-hub-groups.component';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LastVisitedComponent } from './layout/last-visited/last-visited.component';
import { AddPdfComponent, CreatePDFDiaload } from './layout/add-pdf/add-pdf.component';
import { AddPdfInfoPageComponent } from './layout/add-pdf-info-page/add-pdf-info-page.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { UpcomingReadingEventsComponent } from './layout/upcoming-reading-events/upcoming-reading-events.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { BooksComponent } from './layout/books/books.component';
import { SelectedReadingHubsComponent } from './layout/selected-reading-hubs/selected-reading-hubs.component';
import { ReadingHubsComponent } from './layout/reading-hubs/reading-hubs.component';
import { SearchComponent } from './layout/search/search.component';
import { SearchHubComponent } from './layout/search-hub/search-hub.component';
import { ComponentsModule } from './shared/components/components.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SearchPipe } from './shared/pipes/search.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MembersComponent,
    SideMenuComponent,
    NewTopicComponent,
    addNewTopicEditor,
    MyProfileComponent,
    AddArticleComponent,
    ArticleComponent,
    FilterPipePipe,
    SignupComponent,
    LoginComponent,
    PublicCommentsComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    GroupPageComponent,
    GroupMembersDialogComponent,
    CreateArticleDiaload,
    CreatePDFDiaload,
    AddArticleInfoPageComponent,
    SearchBookPublicLibrary,
    BannerComponent,
    ManageMembersListComponent,
    MyCommentComponent,
    SafeHtmlPipePipe,
    ReportGroupDialogComponent,
    AdminMessageDialogComponent,
    InviteFriendsDialogComponent,
    ReadingHubComponent,
    InviteFriendsComponent,
    ReadingHubPageComponent,
    ReadingHubGroupsComponent,
    LastVisitedComponent,
    AddPdfComponent,
    AddPdfInfoPageComponent,
    PdfViewerComponent,
    UpcomingReadingEventsComponent,
    BooksComponent,
    SelectedReadingHubsComponent,
    ReadingHubsComponent,
    SearchComponent,
    SearchHubComponent,
    SearchPipe,
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    LanguageTranslationModule,
    JwSocialButtonsModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ToastrModule.forRoot(),
    CommonModule,
    NgxSpinnerModule,
    NgpSortModule,
    MatMenuModule,
    NgxPaginationModule,
    SlickCarouselModule,
    ComponentsModule,
    MatProgressSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [ApiService, SharedServiceService, CommonService, DatePipe, AuthGuard, ToasterService],
  bootstrap: [AppComponent],
  entryComponents: [
    ManageMembersListComponent,
    CreateArticleDiaload,
    CreatePDFDiaload,
    SearchBookPublicLibrary,
    addNewTopicEditor,
    ReportGroupDialogComponent,
    AdminMessageDialogComponent,
    GroupMembersDialogComponent,
    InviteFriendsDialogComponent,
    ReadingHubComponent,
    InviteFriendsComponent
  ]
})
export class AppModule { }
