import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedServiceService } from '../../shared/services/shared-service.service';

const formData: FormData = new FormData();

export interface Affiliation {
}
let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

@Component({
  selector: 'app-new-topic',
  templateUrl: './new-topic.component.html',
  styleUrls: ['./new-topic.component.scss']
})
export class NewTopicComponent implements OnInit {

  public createTopicForn: FormGroup = this.formBuilder.group({
    is_private: ['', Validators.required],
    topicName: ['', Validators.required],
    description: ['', Validators.required],
    readers_email: ['', Validators.pattern(reg)],
    experts_email: ['', Validators.pattern(reg)],
    affiliatios: [''],
    terms_and_conditions: [false, Validators.required],
    logo: ['', Validators.required],
  });

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  affiliatios: any = [];
  access: boolean = false;
  checked = false;
  experts_email: any;
  error: any;
  imageFileData: File = null;
  previewUrlLogo: any = null;
  imageFileData1: File = null;
  previewUrlLogo1: any = null;
  addTopicresponse: any
  tID: any;
  editTopic: boolean = false;
  dataSource: any

  get tform() {
    return this.createTopicForn.controls;
  }

  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    public router: Router,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    private routes: ActivatedRoute,
    public SharedServiceService: SharedServiceService) {
    this.routes.params.subscribe(
      params => {
        this.tID = +params['id'];
      });
  }

  ngOnInit() {
    this.editTopic = false;
    if (this.tID) {
      this.apiService.getGroupIDList(this.tID).subscribe(data => {
        if (data) {
          this.editTopic = true;
          this.dataSource = data;
          setTimeout(() => {
            this.createTopicForn.controls['topicName'].setValue(data['name']);
            this.createTopicForn.controls['description'].setValue(data['description']);
            this.createTopicForn.controls['is_private'].setValue(data['is_private']);
            this.affiliatios = data['addTag'];
          }, 100);
        }
      });
    }
  }

  logoImage(fileInput: any) {
    if (fileInput.target.files[0].type === "image/jpeg" || fileInput.target.files[0].type === "image/png") {
      this.imageFileData = <File>fileInput.target.files[0];

      var mimeTypes = this.imageFileData.type;
      if (mimeTypes.match(/image\/*/) == null) {
        return;
      }

      var read = new FileReader();
      read.readAsDataURL(this.imageFileData);
      read.onload = (x: any) => {
        this.previewUrlLogo = x.target.result;
      }
    } else {
      this.notifyService.showError("Please select JPEG or PNG image")
    }
  }

  coverPhoto(fileInput1: any) {
    if (fileInput1.target.files[0].type === "image/jpeg" || fileInput1.target.files[0].type === "image/png") {
      this.imageFileData1 = <File>fileInput1.target.files[0];

      var mimeTypes = this.imageFileData1.type;
      if (mimeTypes.match(/image\/*/) == null) {
        return;
      }

      var read = new FileReader();
      read.readAsDataURL(this.imageFileData1);
      read.onload = (x: any) => {
        this.previewUrlLogo1 = x.target.result;
      }
    } else {
      this.notifyService.showError("Please select JPEG or PNG image")
    }
  }

  uploadLogoClick() {
    document.getElementById("uploadLogoPicker").click();
  }

  uploadLogoClick1() {
    document.getElementById("uploadLogoPicker1").click();
  }

  createTopic() {
    let formData: FormData = new FormData();
    this.access = true;
    let adminId: any;
    adminId = [localStorage.getItem('userId')];
    formData.append('is_private', this.createTopicForn.controls['is_private'].value ? 'true' : 'false');
    formData.append('name', this.createTopicForn.controls['topicName'].value);
    formData.append('description', this.createTopicForn.controls['description'].value);
    formData.append('owner', localStorage.getItem('userId'));
    formData.append('admins', adminId);
    if (this.previewUrlLogo) {
      formData.append('logo', this.imageFileData);
    }
    if (this.previewUrlLogo1) {
      formData.append('cover_photo', this.imageFileData1);
    }
    if (this.affiliatios && this.affiliatios.length > 0) {
      this.affiliatios.forEach(element => {
        formData.append('addTag', element);
      });
    }

    if (this.createTopicForn.value.terms_and_conditions) {
      if (this.editTopic) {
        this.spinner.show();
        this.apiService.editTopic(this.tID, formData).subscribe((response) => {
          if (response) {
            this.notifyService.showSuccess('Group Edit successfully.');
            this.spinner.hide();
            let Articlename = response['name'].replace(/ +/g, "");
            this.router.navigate(['/group-page', response['id'], Articlename]);
          } else {
            this.spinner.hide();
          }
        }, error => {
          this.error = error
          this.spinner.hide();
        });
      } else {
        this.spinner.show();
        if (this.previewUrlLogo && this.previewUrlLogo1) {
          this.apiService.createTopic(formData).subscribe((response) => {
            if (response) {
              this.addTopicresponse = response;
              this.notifyService.showSuccess("Topic created successfully");
              setTimeout(() => {
                this.spinner.hide();
              }, 1000);
              this.router.navigateByUrl('/home');
            }
          }, error => {
            this.error = error;
            this.spinner.hide();
            // this.notifyService.showError(this.error.error.name);
            this.notifyService.showError("Add Tags " + this.error.error.add_tag[0]);
          });
        } else {
          this.notifyService.showError("Please upload Logo");
          this.spinner.hide()
        }
      }
    }
  }

  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;

    if ((value || '').trim()) {
      this.affiliatios.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(affiliation: Affiliation): void {
    const index = this.affiliatios.indexOf(affiliation);

    if (index >= 0) {
      this.affiliatios.splice(index, 1);
    }
  }

  close() {
    this.router.navigate(["/home"])
  }

  sendExportEmail() {
    if (this.createTopicForn.controls.experts_email.valid) {
      if (this.createTopicForn.controls.experts_email.value) {
        formData.append('experts', this.createTopicForn.controls.experts_email.value)
        formData.append('topic', this.createTopicForn.controls.topicName.value)
      }
    }
    if (this.createTopicForn.controls.experts_email.value) {
      if (this.createTopicForn.controls.experts_email.valid === true) {
        this.spinner.show();
        this.apiService.sendExpertEmail(formData).subscribe((response: any) => {
          if (response.massage) {
            this.notifyService.showSuccess(response['massage']);
            this.createTopicForn.controls.experts_email.setValue('');
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
        });
      } else {
        this.notifyService.showError("Please enter valid Email");
      }
    } else {
      this.spinner.hide();
      this.notifyService.showError("Please enter Email");
    }
  }

  sendReaderEmail() {
    if (this.createTopicForn.controls.readers_email.valid) {
      if (this.createTopicForn.controls.readers_email.value) {
        formData.append('members', this.createTopicForn.controls.readers_email.value)
        formData.append('topic', this.createTopicForn.controls.topicName.value)
      }
    }

    if (this.createTopicForn.controls.readers_email.value) {
      if (this.createTopicForn.controls.readers_email.valid === true) {
        this.spinner.show();
        this.apiService.sendExpertEmail(formData).subscribe((res: any) => {
          if (res.massage) {
            this.notifyService.showSuccess(res['massage']);
            this.createTopicForn.controls.readers_email.setValue('');
            this.spinner.hide();
          }
        }, error => {
          this.error = error;
          this.spinner.hide();
        });
      } else {
        this.notifyService.showError("Please enter valid Email");
      }
    } else {
      this.spinner.hide();
      this.notifyService.showError("Please enter Email");
    }
  }

  backClicked() {
    this._location.back();
  }
}

@Component({
  selector: 'app-new-topic-editor',
  templateUrl: './new-topic-editor.html',
  styleUrls: ['./new-topic.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class addNewTopicEditor {
}