
import { env } from 'process';
import { environment } from '../environments/environment';

export class config {

    // API END POINTS
    static API_ENDPOINT = environment.API_ENDPOINT;
    static API_ENDPOINT_NEW = environment.API_ENDPOINT_NEW;
    static API_ENDPOINT_LOGIN = environment.API_ENDPOINT_LOGIN;
    static API_ENDPOINT_SIGNUP = environment.API_ENDPOINT_SIGNUP;
    static API_ENDPOINT_GETALLUSER = environment.API_ENDPOINT_GETALLUSER;
    static API_ENDPOINT_TOPICS = environment.API_ENDPOINT_TOPICS;
    static API_ENDPOINT_EDIT_TOPICS = environment.API_ENDPOINT_EDIT_TOPICS;
    static API_ENDPOINT_DELETE_TOPICS = environment.API_ENDPOINT_DELETE_TOPICS;
    static API_ENDPOINT_DELETE_EVENT = environment.API_ENDPOINT_DELETE_EVENT;
    static API_ENDPOINT_FAVORITE_EVENT = environment.API_ENDPOINT_FAVORITE_EVENT;
    static API_ENDPOINT_ALLARTICLES = environment.API_ENDPOINT_ALLARTICLES;
    static API_ENDPOINT_ALLPDFS = environment.API_ENDPOINT_ALLPDFS;
    static API_ENDPOINT_ALLPAPERHOMEPAGE = environment.API_ENDPOINT_ALLPAPERHOMEPAGE;
    static API_ENDPOINT_GETPDFID = environment.API_ENDPOINT_GETPDFID;
    static API_ENDPOINT_GETPDFPAPERCOMMENT = environment.API_ENDPOINT_GETPDFPAPERCOMMENT;
    static API_ENDPOINT_ALLCOMMENST = environment.API_ENDPOINT_ALLCOMMENST;
    static API_ENDPOINT_REPLYPAPERCOMMENT = environment.API_ENDPOINT_REPLYPAPERCOMMENT;
    static API_ENDPOINT_ADDCOMMEMTONPAPER = environment.API_ENDPOINT_ADDCOMMEMTONPAPER;
    static API_ENDPOINT_ALLCOMMENSTBYSECTIONID = environment.API_ENDPOINT_ALLCOMMENSTBYSECTIONID;
    static API_ENDPOINT_SENDEMAIL = environment.API_ENDPOINT_SENDEMAIL;
    static API_ENDPOINT_BOOKS = environment.API_ENDPOINT_BOOKS
    static API_ENDPOINT_USERS = environment.API_ENDPOINT_USERS
    static API_ENDPOINT_CUSTOMERUSERS = environment.API_ENDPOINT_CUSTOMERUSERS
    static API_ENDPOINT_SAVEARTICLE = environment.API_ENDPOINT_SAVEARTICLE
    static API_ENDPOINT_LIKEARTICLE = environment.API_ENDPOINT_LIKEARTICLE
    static API_ENDPOINT_SENDINVITATION = environment.API_ENDPOINT_SENDINVITATION
    static API_ENDPOINT_PDFTOHTML = environment.API_ENDPOINT_PDFTOHTML
    static API_ENDPOINT_WITHOUTSLASH = environment.API_ENDPOINT_WITHOUTSLASH
    static API_ENDPOINT_DELETECOMMENT = environment.API_ENDPOINT_DELETECOMMENT
    static API_ENDPOINT_DELETEARTICLE = environment.API_ENDPOINT_DELETEARTICLE
    static API_ENDPOINT_SEARCHALLARTICLE = environment.API_ENDPOINT_SEARCHALLARTICLE
    static API_ENDPOINT_DELETEREPLYCOMMENT = environment.API_ENDPOINT_DELETEREPLYCOMMENT
    static API_ENDPOINT_ALLCOMMENSTBYUSER = environment.API_ENDPOINT_ALLCOMMENSTBYUSER
    static API_ENDPOINT_EPUBTOHTML = environment.API_ENDPOINT_EPUBTOHTML
    static API_ENDPOINT_DOCXTOHTML = environment.API_ENDPOINT_DOCXTOHTML
    static API_ENDPOINT_ALL_TOPICS = environment.API_ENDPOINT_ALL_TOPICS
    static API_ENDPOINT_HIGHLIGHT = environment.API_ENDPOINT_HIGHLIGHT
    static API_ENDPOINT_DELETEHIGHLIGHT = environment.API_ENDPOINT_DELETEHIGHLIGHT
    static API_ENDPOINT_PUBLIC_LIBRARY_BOOK = environment.API_ENDPOINT_PUBLIC_LIBRARY_BOOK
    static API_ENDPOINT_REPORT_GROUP = environment.API_ENDPOINT_REPORT_GROUP
    static API_ENDPOINT_ADMIN_MESSAGE = environment.API_ENDPOINT_ADMIN_MESSAGE
    static API_ENDPOINT_INVITE_FRIENDS = environment.API_ENDPOINT_INVITE_FRIENDS
    static API_ENDPOINT_TRENDING_ARTICLE = environment.API_ENDPOINT_TRENDING_ARTICLE
    static API_ENDPOINT_GROUP_INVITATION = environment.API_ENDPOINT_GROUP_INVITATION
    static API_ENDPOINT_APPLY_TO_PUBLICATION = environment.API_ENDPOINT_APPLY_TO_PUBLICATION
    static API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE = environment.API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE
    static API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE_PAPER = environment.API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE_PAPER
    static API_ENDPOINT_INVITE_FRIENDS_ARTICLE = environment.API_ENDPOINT_INVITE_FRIENDS_ARTICLE
    static API_ENDPOINT_ADD_ARTICLE_PRIVATE_NOTE = environment.API_ENDPOINT_ADD_ARTICLE_PRIVATE_NOTE
    static API_ENDPOINT_GET_PRIVATE_NOTE = environment.API_ENDPOINT_GET_PRIVATE_NOTE
    static API_GET_ARTICLE_SECTION_ID = environment.API_GET_ARTICLE_SECTION_ID
    static API_HOME_PAGE_COMMENT_PAGINATION = environment.API_HOME_PAGE_COMMENT_PAGINATION
    static API_ARTICLE_IMAGE_PREVIEW = environment.API_ARTICLE_IMAGE_PREVIEW
    static API_FORGOT_PASSWORD = environment.API_FORGOT_PASSWORD
    static API_RESET_PASSWORD = environment.API_RESET_PASSWORD
    static API_ENDPOINT_ADD_REMOVE_GROUP_PAGE_FROM_HOMEPAGE = environment.API_ENDPOINT_ADD_REMOVE_GROUP_PAGE_FROM_HOMEPAGE
    static API_ENDPOINT_SAVE_USER_GROUP = environment.API_ENDPOINT_SAVE_USER_GROUP
    static API_ENDPOINT_GET_FAVORITE_EVENT = environment.API_ENDPOINT_GET_FAVORITE_EVENT
    static API_ENDPOINT_REMOVE_SAVED_GROUP = environment.API_ENDPOINT_REMOVE_SAVED_GROUP
    static API_ENDPOINT_LASTVISITEDARTICLES = environment.API_ENDPOINT_LASTVISITEDARTICLES
    static API_ENDPOINT_ADDLASTVISITEDARTICLES = environment.API_ENDPOINT_ADDLASTVISITEDARTICLES
    static API_ENDPOINT_SAVECOMMENTS = environment.API_ENDPOINT_SAVECOMMENTS
    static API_ENDPOINT_SEARCH_PARAGRAPH = environment.API_ENDPOINT_SEARCH_PARAGRAPH
    static API_ENDPOINT_Q_AND_A_LIST = environment.API_ENDPOINT_Q_AND_A_LIST
    static API_ENDPOINT_Q_AND_A_LIST_PAPER = environment.API_ENDPOINT_Q_AND_A_LIST_PAPER;
    static API_ENDPOINT_ADD_Q_AND_A = environment.API_ENDPOINT_ADD_Q_AND_A
    static API_ENDPOINT_ADD_Q_AND_A_PAPER = environment.API_ENDPOINT_ADD_Q_AND_A_PAPER;
    static API_ENDPOINT_ADD_UPDATE_ANSWER = environment.API_ENDPOINT_ADD_UPDATE_ANSWER
    static API_ENDPOINT_ADD_UPDATE_PAPER_ANSWER = environment.API_ENDPOINT_ADD_UPDATE_PAPER_ANSWER;
    static API_ENDPOINT_LINK_AND_UNLIKE_QUESTION = environment.API_ENDPOINT_LINK_AND_UNLIKE_QUESTION
    static API_ENDPOINT_LIKE_UNLIKE_PDF_COMMENT = environment.API_ENDPOINT_LIKE_UNLIKE_PDF_COMMENT;
    static API_ENDPOINT_LIKE_UNLIKE_PDF_QANDA = environment.API_ENDPOINT_LIKE_UNLIKE_PDF_QANDA;
    static API_ENDPOINT_DELETE_ANSWER = environment.API_ENDPOINT_DELETE_ANSWER
    static API_ENDPOINT_DELETE_PAPER_ANSWER = environment.API_ENDPOINT_DELETE_PAPER_ANSWER;
    static API_ENDPOINT_PUSH_COMMENT = environment.API_ENDPOINT_PUSH_COMMENT
    static API_ENDPOINT_DELETE_PUSH_COMMENT = environment.API_ENDPOINT_DELETE_PUSH_COMMENT
    static API_ENDPOINT_CREATE_EVENT = environment.API_ENDPOINT_CREATE_EVENT
    static API_ENDPOINT_ALL_EVENT = environment.API_ENDPOINT_ALL_EVENT;
    static API_ENDPOINT_SEARCH_HUB = environment.API_ENDPOINT_SEARCH_HUB;
    // DEFAULTS
    static IS_PRODUCTION = environment.production;
    static REQUEST_POST = 'post';

    static DEFAULT_USER_IMAGE = 'assets/img/default-image.png';
    static DEFAULT_ARTICLE_IMAGE = 'assets/img/default_article.png';
    static DEFAULT_PDF_IMAGE = 'assets/img/default_article.png';
    static DEFAULT_PDFUSER_IMAGE = 'assets/img/pdf-comment.png';
}