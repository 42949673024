import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification.service';
export interface Affiliation {
}
let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
@Component({
  selector: 'app-add-pdf-info-page',
  templateUrl: './add-pdf-info-page.component.html',
  styleUrls: ['./add-pdf-info-page.component.scss']
})
export class AddPdfInfoPageComponent implements OnInit {
  dataSource: any;
  ownerID: any;
  selectedFile: File

  fileData: File = null;
  imageFileData: File = null;

  public createPdf: FormGroup = this.formBuilder.group({
    group: ['', [Validators.required]],
    title: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
    Description: ['', [Validators.required, Validators.maxLength(1000), Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
    image: ['', Validators.required],
    logo: ['', Validators.required],
    author_name: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
    publish_date: ['', Validators.required],
    Public: ['', Validators.required],
    PdfLink: ['', [Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
    publisher: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    isbn: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
    owner: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
    affiliatios: ['', Validators.required],
    terms_and_conditions: ['', Validators.required]
  })
  PdfID: any;
  datePipe: any;
  access: boolean = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  affiliatios: any = [];
  checked: boolean = true;
  previewUrl: any = null;
  previewUrlLogo: any = null;
  aID: any
  error: any;
  editPdf: boolean = false;
  allGroups: any;
  audioFileData: File;
  previewUrlAudio: any;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    public sharedServiceService: SharedServiceService,
    private route: Router,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    private routes: ActivatedRoute) {
    this.routes.params.subscribe(
      params => {
        this.aID = +params['id'];
      });
  }

  get createPdfForm() {
    return this.createPdf.controls;
  }

  ngOnInit() {
    this.editPdf = false
    if (this.aID) {
      this.apiService.getPdfID(this.aID).subscribe(data => {
        if (data) {
          this.editPdf = true
          this.dataSource = data;
          this.createPdf.controls['group'].setValue(this.dataSource['group']);
          this.createPdf.controls['title'].setValue(this.dataSource['title']);
          this.createPdf.controls['Description'].setValue(this.dataSource['description']);
          this.createPdf.controls['author_name'].setValue(this.dataSource['author_name']);
          this.createPdf.controls['publish_date'].setValue(this.dataSource['publish_date']);
          if (this.dataSource['public_library']) {
            this.checked = false;
            this.createPdf.controls['Public'].setValue(this.checked);
          } else {
            this.checked = true;
            this.createPdf.controls['Public'].setValue(this.checked);
          }
          this.createPdf.controls['publisher'].setValue(this.dataSource['publisher']);
          this.createPdf.controls['PdfLink'].setValue(this.dataSource['web_url']);
          this.createPdf.controls['isbn'].setValue(this.dataSource['isbn']);
          this.affiliatios = this.dataSource['add_tag'];
        }
      });
    }

    this.ownerID = localStorage.getItem('userId')
    this.apiService.getAllGroupsList().subscribe(data => {
      if (data) {
        this.allGroups = data
      }
    });
    if (this.sharedServiceService.editor) {
      this.sharedServiceService.PdfText = this.sharedServiceService.editor;
    };
  }

  OnDateChange(event) {
  }

  uploadFileClick() {
    document.getElementById("uploadFilePicker").click();
  }

  uploadLogoClick() {
    document.getElementById("uploadLogoPicker").click();
  }

  uploadFileChange(fileInputs: any) {
    if (fileInputs.target.files[0].type === "image/jpeg" || fileInputs.target.files[0].type === "image/png") {
      this.fileData = <File>fileInputs.target.files[0];

      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (e: any) => {
        this.previewUrl = e.target.result;
      }
    } else {
      this.notifyService.showError("Please select JPEG or PNG image")
    }
  }

  logoImage(fileInput: any) {
    if (fileInput.target.files[0].type === "image/jpeg" || fileInput.target.files[0].type === "image/png") {
      this.imageFileData = <File>fileInput.target.files[0];

      var mimeTypes = this.imageFileData.type;
      if (mimeTypes.match(/image\/*/) == null) {
        return;
      }

      var read = new FileReader();
      read.readAsDataURL(this.imageFileData);
      read.onload = (x: any) => {
        this.previewUrlLogo = x.target.result;
      }
    } else {
      this.notifyService.showError("Please select JPEG or PNG image")
    }
  }

  removeColumn(id) {
    document.getElementById(id);
  }

  onCreatePdf() {
    if (this.createPdfForm.invalid) {
      return;
    }

    var datePipe = new DatePipe("en-US");
    this.access = true;
    let formData: FormData = new FormData();

    if (this.sharedServiceService.PdfFileUpload) {
      formData.append('file', this.sharedServiceService.PdfFileUpload)
    }
    if (this.sharedServiceService.PdfText) {
      formData.append('parsed_text', this.sharedServiceService.PdfText)
    }
    if (this.dataSource && this.dataSource['add_tag']) {
      formData.append('add_tag', this.affiliatios);
    } else {
      formData.append('add_tag', this.affiliatios);
    }
    formData.append('title', this.createPdf.controls['title'].value);
    formData.append('description', this.createPdf.controls['Description'].value);
    formData.append('author_name', this.createPdf.controls['author_name'].value);
    formData.append('publish_date', datePipe.transform(this.createPdf.controls['publish_date'].value, 'yyyy-MM-dd'));
    formData.append('public_library', this.createPdf.controls.Public.value ? 'false' : 'true');
    formData.append('web_url', this.createPdf.controls['PdfLink'].value);
    formData.append('publisher', this.createPdf.controls['publisher'].value);
    formData.append('isbn', this.createPdf.controls['isbn'].value);
    formData.append('owner', this.ownerID);
    formData.append('group', this.createPdf.controls['group'].value);

    if (this.previewUrlLogo) {
      formData.append('author_image', this.imageFileData);
    }
    if (this.previewUrl) {
      formData.append('cover_image', this.fileData);
    }
    if (this.createPdf.value.terms_and_conditions) {
      this.spinner.show();
      if (this.editPdf) {
        this.apiService.editPdf(this.aID, formData).subscribe((response) => {
          if (response) {
            this.notifyService.showSuccess('Edit PDF paper successfully.');
            this.spinner.hide();
            this.route.navigate(['pdf-viewer', response['id']]);
            this.spinner.hide();

          } else {
            this.spinner.hide();
          }
        }, error => {
          this.error = error
          this.spinner.hide();
        });
      } else {
        if (this.createPdfForm.image.value && this.createPdfForm.logo.value) {
          this.apiService.createPdf(formData).subscribe((response: any) => {
            if (!response.message) {
              this.notifyService.showError(response['message']);
              this.spinner.hide();
              this.route.navigate(['add-pdf']);
            } else {
              this.notifyService.showSuccess('PDF Paper created successfully.');
              this.spinner.hide();
              this.route.navigate(['pdf-viewer', response.data['id']]);
              this.spinner.hide();

            }
          }, error => {
            this.error = error;
            this.spinner.hide();
            this.notifyService.showError("Add tags " + this.error.error.add_tag[0]);
          });
        } else {
          this.notifyService.showError("Please upload Image and Logo");
          this.spinner.hide();
        }
      }
    }
  }

  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;

    if ((value || '').trim()) {
      this.affiliatios.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(affiliation: Affiliation): void {
    let index = this.affiliatios.indexOf(affiliation);

    if (index >= 0) {
      this.affiliatios.splice(index, 1);
    }
  }

  backClicked() {
    this._location.back();
  }
}