import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  map,
  catchError,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";
import { config } from "../../config";
import { debounceTime } from "rxjs/operators";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/switchMap";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  getItem() {
    return localStorage.getItem("token");
  }

  isUploadImage: boolean = false;

  constructor(private http: HttpClient) {}

  //* get header
  getHeader() {
    let header = new HttpHeaders();
    header = header.set("Content-Type", "application/json");
    header = header.set("Authorization", `Token ${this.getItem()}`);
    return { headers: header };
  }
  getHeaderWithoutAuthentication() {
    let header = new HttpHeaders();
    header = header.set("Content-Type", "application/json");
    // header = header.set('Authorization', `Token ${this.getItem()}`)
    return { headers: header };
  }

  //* get token
  getToken() {
    let header = new HttpHeaders();
    header = header.set("Authorization", `Token ${this.getItem()}`);
    return { headers: header };
  }

  //* Dell E Image API key
  getDellEKey() {
    let header = new HttpHeaders();
    header = header.set(
      "Authorization",
      `Bearer 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`
    );
    return { headers: header };
  }

  //* create endpoint url
  getAPIURL(url) {
    return config.API_ENDPOINT + url;
  }

  getAPIURLNew(url) {
    return config.API_ENDPOINT_NEW + url;
  }

  getAllUsers() {
    return this.http
      .get(this.getAPIURL(config.API_ENDPOINT_CUSTOMERUSERS), this.getHeader())
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAllEvents() {
    return this.http
      .get(this.getAPIURL(config.API_ENDPOINT_ALL_EVENT), this.getHeader())
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getLastVisitedArticles() {
    return this.http
      .get(
        this.getAPIURL(config.API_ENDPOINT_LASTVISITEDARTICLES),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  addLastVisitedArticles(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_ADDLASTVISITEDARTICLES),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get all groups/topic lists
  getAllGroupsList() {
    return this.http.get(this.getAPIURL(config.API_ENDPOINT_ALL_TOPICS)).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // get grou by user selcted
  getAllUserSelcetdGroupsList() {
    return this.http
      .get(
        this.getAPIURL(config.API_ENDPOINT_SAVE_USER_GROUP),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAllFavoriteEvents() {
    return this.http
      .get(
        this.getAPIURL(config.API_ENDPOINT_GET_FAVORITE_EVENT),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAllArticles() {
    return this.http.get(this.getAPIURL(config.API_ENDPOINT_ALLARTICLES)).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getAllPapers() {
    return this.http
      .get(this.getAPIURL(config.API_ENDPOINT_ALLPAPERHOMEPAGE))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getArticleID(id: number) {
    return this.http
      .get(this.getAPIURL(`${config.API_ENDPOINT_ALLARTICLES}${id}`))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getPdfID(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_GETPDFID}${id}`),
        this.getHeaderWithoutAuthentication()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getPdfPaperComment(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_GETPDFPAPERCOMMENT}${id}`),
        this.getHeaderWithoutAuthentication()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getArticleRelatedID(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_SEARCH_PARAGRAPH}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  createTopic(requestJson) {
    return this.http
      .post(this.getAPIURL(config.API_ENDPOINT_TOPICS), requestJson)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  editTopic(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_EDIT_TOPICS}${id}/`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get based on ID groups/topic lists
  getGroupIDList(id: number) {
    return this.http
      .get(this.getAPIURL(`${config.API_ENDPOINT_TOPICS}${id}`))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  login(requestJson) {
    return this.http
      .post(this.getAPIURL(config.API_ENDPOINT_LOGIN), requestJson)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  signUp(requestJson) {
    return this.http
      .post(this.getAPIURL(config.API_ENDPOINT_SIGNUP), requestJson)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  createArticle(requestJson) {
    return this.http
      .post(this.getAPIURL(config.API_ENDPOINT_ALLARTICLES), requestJson)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  createEvent(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_CREATE_EVENT),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  createPdf(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_ALLPDFS),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  editArticle(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_ALLARTICLES}${id}/`),
        requestJson
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  editPdf(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_ALLPDFS}${id}/`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // forgotPassword(requestJson) {
  //   return this.http.post(config.API_ENDPOINT + 'api/groups', requestJson, this.getHeader())
  //     .pipe(map((res => {
  //       return res;
  //     })))
  // }

  // resetPassword(requestJson) {
  //   return this.http.post(config.API_ENDPOINT + 'api/groups', requestJson, this.getHeader())
  //     .pipe(map((res => {
  //       return res;
  //     })))
  // }

  // Add comments
  addArticleComments(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENST}addComments/?`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  addPaperComments(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ADDCOMMEMTONPAPER}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get all comments for all articles
  getAllArticleComments() {
    return this.http
      .get(this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENST}`))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get based on ID groups/topic lists
  getArticleComments(id: number) {
    return this.http
      .get(this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENST}${id}/comments`))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // delete comments based on ID
  deleteArticleComments(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETECOMMENT}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get based on ID groups/topic lists
  getArticleCommentsBySectionID(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENSTBYSECTIONID}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //SEND EMAIL
  sendExpertEmail(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_SENDEMAIL}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get book from out public library
  getBooksPublicLibrary() {
    return this.http
      .get(this.getAPIURL(`${config.API_ENDPOINT_BOOKS}`), this.getHeader())
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get book based on ID from out public library
  getBooksPublicLibraryID(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_PUBLIC_LIBRARY_BOOK}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get user details
  getUserDetails(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_USERS}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get book based on ID from out public library
  updateUserDetails(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_CUSTOMERUSERS}${id}/`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  saveArticle(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_SAVEARTICLE}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  likeArticle(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_LIKEARTICLE}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get based on ID groups/topic lists
  commentsReply(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENST}${id}/replies/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  paperCommentsReply(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_REPLYPAPERCOMMENT}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //Comment like
  commentsLike(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENST}like/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //Save Comments
  SaveComments(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_SAVECOMMENTS}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //Get all saved Comments
  GetAllSaveComments() {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_SAVECOMMENTS}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //Invite friend mail
  sendInvitation(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_SENDINVITATION}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // convert pdf to html
  convertPdftoHtml(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_PDFTOHTML}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //convert epub to html
  converteEpubtoHtml(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_EPUBTOHTML}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //convert docx to html
  converteDocxtoHtml(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_DOCXTOHTML}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //convert doc to html
  converteDoctoHtml(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_DOCXTOHTML}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // delete Article
  deleteArticle(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETEARTICLE}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // update comments
  commentsUpdate(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENST}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  paperCommentsUpdate(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_ADDCOMMEMTONPAPER}${id}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => this.searchAllArticle(term))
    );
  }

  searchAllArticle(term) {
    if (term) {
      return this.http
        .get(
          this.getAPIURL(`${config.API_ENDPOINT_SEARCHALLARTICLE}${term}`),
          this.getHeader()
        )
        .pipe(
          map((res: any) => {
            return res;
          })
        );
    }
  }

  // delete reply comment
  deleteReplyComment(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETEREPLYCOMMENT}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // get user details
  getAllCommentByUser(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_ALLCOMMENSTBYUSER}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  highlightComment(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_HIGHLIGHT}${id}/highlights/`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getHighlightComment(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_HIGHLIGHT}${id}/highlights/`),
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // delete highlight comment
  deleteSaveedHighlightComment(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETEHIGHLIGHT}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // delete Group
  deleteGroup(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETE_TOPICS}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // delete Event
  deleteEvent(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETE_EVENT}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // delete Event
  favoriteEvent(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_FAVORITE_EVENT}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // report Group
  reportGroup(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_REPORT_GROUP}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // admin Messsage
  adminMessage(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ADMIN_MESSAGE}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // invite Friends
  inviteFriends(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_INVITE_FRIENDS),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // add joint selected artical
  userjoint(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_SAVE_USER_GROUP),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  // selected delete artical
  deletedSelectedartical(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_REMOVE_SAVED_GROUP}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //trending Articles
  trendingArticles(id: number) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_TRENDING_ARTICLE}${id}/`),
        {},
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //group Invitation
  groupInvitation(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_GROUP_INVITATION}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //apply New Publication
  applyToPublication(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_APPLY_TO_PUBLICATION}`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //add and remove article page from homepage
  addRemoveArticle(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(
          `${config.API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE}${id}/`
        ),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  //add and remove paper page from homepage
  addRemovePaper(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(
          `${config.API_ENDPOINT_ADD_REMOVE_PAGE_FROM_HOMEPAGE_PAPER}${id}/`
        ),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //article Invitation
  InviteForArticle(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_INVITE_FRIENDS_ARTICLE}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //add Article private notes
  getAllPrivateNote(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_GET_PRIVATE_NOTE}${id}/`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //add Article private notes
  addPrivateNote(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_ADD_ARTICLE_PRIVATE_NOTE),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //edit Article private note
  editPrivateNote(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_ADD_ARTICLE_PRIVATE_NOTE}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //delete Article private note
  deletePrivateNote(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_ADD_ARTICLE_PRIVATE_NOTE}${id}/`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //get Article section id
  getSectionId(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_GET_ARTICLE_SECTION_ID}${id}/`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //home page convrtation pagination
  homePagination() {
    return this.http
      .get(
        this.getAPIURL(config.API_HOME_PAGE_COMMENT_PAGINATION),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //article upload image preview
  imagePreview() {
    return this.http
      .get(this.getAPIURL(config.API_ARTICLE_IMAGE_PREVIEW), this.getHeader())
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //forgot password
  forgotPassword(requestJson) {
    return this.http
      .post(this.getAPIURL(config.API_FORGOT_PASSWORD), requestJson)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //forgot password
  resetPassword(requestJson) {
    return this.http
      .post(this.getAPIURL(config.API_RESET_PASSWORD), requestJson)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  //add and remove group page from homepage
  addRemoveGroup(id: number, requestJson) {
    return this.http
      .post(
        this.getAPIURL(
          `${config.API_ENDPOINT_ADD_REMOVE_GROUP_PAGE_FROM_HOMEPAGE}${id}/`
        ),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getQandAList(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_Q_AND_A_LIST}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getQandAListPaper(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_Q_AND_A_LIST_PAPER}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  addQandA(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_ADD_Q_AND_A),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  addQandAPaper(requestJson) {
    return this.http
      .post(
        this.getAPIURL(config.API_ENDPOINT_ADD_Q_AND_A_PAPER),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  postAnswerData(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_ADD_UPDATE_ANSWER}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  postPaperAnswerData(id: number, requestJson) {
    return this.http
      .put(
        this.getAPIURL(`${config.API_ENDPOINT_ADD_UPDATE_PAPER_ANSWER}${id}/`),
        requestJson,
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  likeandunlikeQuestion(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_LINK_AND_UNLIKE_QUESTION}${id}/`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  likeUnlikePdfComment(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_LIKE_UNLIKE_PDF_COMMENT}${id}/`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  likeUnlikePdfQandA(id: number) {
    return this.http
      .get(
        this.getAPIURL(`${config.API_ENDPOINT_LIKE_UNLIKE_PDF_QANDA}${id}/`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  rejectQuestion(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETE_ANSWER}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  rejectPaperQuestion(id: number) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETE_PAPER_ANSWER}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  pushaddedcomment(requestJson) {
    return this.http
      .post(
        this.getAPIURL(`${config.API_ENDPOINT_PUSH_COMMENT}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  pushdeletecomment(id) {
    return this.http
      .delete(
        this.getAPIURL(`${config.API_ENDPOINT_DELETE_PUSH_COMMENT}${id}`),
        this.getHeader()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getSearchHubData(requestJson) {
    return this.http
      .post(
        this.getAPIURLNew(`${config.API_ENDPOINT_SEARCH_HUB}`),
        requestJson,
        this.getToken()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // dallEImageGenration(requestJson) {
  //   return this.http
  //     .post(
  //       "https://api.openai.com/v1/images/generations",
  //       requestJson,
  //       this.getDellEKey()
  //     )
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }
}
