import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service'

@Injectable()
export class AuthGuard implements CanActivate{
    constructor(private router: Router, private ApiService: ApiService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        if (localStorage.getItem('token') != null && localStorage.getItem('token') !== 'undefined') {
            if (route.url[0].path == 'login' || route.url[0].path == 'signup' || route.url[0].path == 'forget-password' || route.url[0].path == 'banner' ) {
              this.router.navigate(['/home']);
              return false;
            }
            // logged in so return true
            return true;
        }
        if (route.url[0].path == 'login' || route.url[0].path == 'signup' || route.url[0].path == 'forget-password' || route.url[0].path == 'banner') {
            return true;
        }
          // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
} 
