import { Injectable } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { config } from 'src/app/config';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  articleText: any;
  PdfText: any;
  articleFileUpload: any;
  PdfFileUpload: any;
  searchPublicLibraryBooks: any;
  isAdimin: any;
  headerdata: boolean = false;
  withoutLoginHeaderData: boolean = true;
  htmlconvert: any;
  editor: any;
  editor2: any;
  OnlyViewMeetingRequest: any;
  baseUrl;
  baseUrl1;
  defaultImg: string;
  defaultArticleImg: any;
  defaultPDFImg: any;
  avatar: string
  articleID: any
  groups: any
  favorite_events: any;
  selectedParagraphId: any
  paragraphID: any;
  footerHide = true;
  publicHomeComment: boolean = false;
  topicHide = true;
  bookName: any;
  addArticleSubmitBtn: boolean = false;
  is_superuser: any;
  loggedUser: any;
  isShowFooter = false;
  logoHide = true;
  defaultPdfCommentImg: any;
  scrollSectionId = "";
  constructor(private router: Router, private title: Title) {
    this.baseUrl = config.API_ENDPOINT;
    this.baseUrl1 = config.API_ENDPOINT_WITHOUTSLASH;
    this.defaultImg = config.DEFAULT_USER_IMAGE;
    this.defaultPdfCommentImg = config.DEFAULT_PDFUSER_IMAGE;
    this.defaultArticleImg = config.DEFAULT_ARTICLE_IMAGE
    this.defaultPDFImg = config.DEFAULT_PDF_IMAGE;
    this.isAdimin = localStorage.getItem("is_superuser");
    this.is_superuser = localStorage.getItem("is_superuser");
    this.avatar = localStorage.getItem('avatar');
    this.loggedUser = localStorage.getItem('userId');
  }

  initializeCkeditor() {
    ClassicEditor
      .create(document.querySelector('#editor1'), {
        language: 'en',
        additionalLanguages: 'all',
        // minHeight: '100px',
        toolbar: [],
        removePlugins: ['ImageUpload, MediaEmbed'],
        mediaEmbed: {
          previewsInData: true
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: 'manual',
              defaultValue: true,
              callback: function (url) {
                return url.startswith('https://');
              },
              // label: 'Open in a new tab',
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            }
          }
        },
        placeholder: 'Add your comment…'
      })
      .then(editor => {
        editor.config.height = 100;
        this.editor = editor;
      })
      .catch(err => {
        console.error(err.stack);
      });
  }

  initializeCkeditor2() {
    ClassicEditor
      .create(document.querySelector('#editor2'), {
        language: 'en',
        additionalLanguages: 'all',
        minHeight: '800px',
        toolbar: ['heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', '|',
          'ImageUpload', 'mediaEmbed', 'insertTable', '|', 'Blockquote', 'undo', 'redo'],
        removePlugins: ['ImageUpload, MediaEmbed'],
        mediaEmbed: {
          previewsInData: true
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: 'manual',
              defaultValue: true,
              callback: function (url) {
                return url.startswith('https://');
              },
              label: 'Open in a new tab',
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            }
          }
        },
        placeholder: 'Add your comment…'
      })
      .then(editor => {
        editor.config.minHeight = 800;
        editor.config.height = 800;
        this.editor2 = editor;
      })
      .catch(err => {
        console.error(err.stack);
      });
  }

  initializeCkeditorDynamic(id) {
    ClassicEditor.create(document.querySelector('#' + id), {
      language: 'en',
      additionalLanguages: 'all',
      minHeight: '800px',
      removePlugins: ['ImageUpload, MediaEmbed'],
      mediaEmbed: {
        previewsInData: true
      },
      link: {
        decorators: {
          openInNewTab: {
            mode: 'manual',
            defaultValue: true,
            callback: function (url) {
              return url.startswith('https://');
            },
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      },
      placeholder: 'Add your comment…'
    })
      .then(editor => {
        editor.config.minHeight = 800;
        editor.config.height = 800;
        this.editor = editor;
      })
      .catch(err => {
        console.error(err.stack);
      });
    //  CKEDITOR.dialog.add( 'testOnly', function( editor ) {})
  }

  initializeCkeditorArticleComments(id) {
    ClassicEditor.create(document.querySelector('#' + id), {
      language: 'en',
      additionalLanguages: 'all',
      minHeight: '800px',
      removePlugins: ['ImageUpload, MediaEmbed'],
      mediaEmbed: {
        previewsInData: true
      },
      link: {
        decorators: {
          openInNewTab: {
            mode: 'manual',
            defaultValue: true,
            callback: function (url) {
              return url.startswith('https://');
            },
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      },
      placeholder: 'Add your comment…'
    })
      .then(editor => {
        editor.config.minHeight = 800;
        editor.config.height = 800;
        this.editor = editor;
      })
      .catch(err => {
        console.error(err.stack);
      });
    //  CKEDITOR.dialog.add( 'testOnly', function( editor ) {})
  }

  private drawer: MatDrawer;

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(): void {
    this.drawer.toggle();
  }

  close(): void {
    this.drawer.close();
  }

}

export class MetaTag {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}