import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { BooksComponent } from './layout/books/books.component';
import { ReadingHubsComponent } from './layout/reading-hubs/reading-hubs.component';
import { BannerComponent } from './layout/banner/banner.component';
import { SearchHubComponent } from './layout/search-hub/search-hub.component';


import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MembersComponent } from './layout/members/members.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { NewTopicComponent } from './layout/new-topic/new-topic.component';
import { MyProfileComponent } from './layout/my-profile/my-profile.component';
import { AddArticleComponent } from './layout/add-article/add-article.component';
import { ArticleComponent } from './layout/article/article.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { PublicCommentsComponent } from './layout/public-comments/public-comments.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GroupPageComponent } from './layout/group-page/group-pagecomponent';
import { AddArticleInfoPageComponent } from './layout/add-article-info-page/add-article-info-page.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { MyCommentComponent } from './layout/my-comment/my-comment.component';
import { ReadingHubPageComponent } from './layout/reading-hub-page/reading-hub-page.component'
import { ReadingHubGroupsComponent } from './layout/reading-hub-groups/reading-hub-groups.component'
import { AddPdfComponent } from './layout/add-pdf/add-pdf.component';
import { AddPdfInfoPageComponent } from './layout/add-pdf-info-page/add-pdf-info-page.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { UpcomingReadingEventsComponent } from './layout/upcoming-reading-events/upcoming-reading-events.component'


const routes: Routes = [
  { path: '', redirectTo: '/banner', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent },
  { path: 'books', component: BooksComponent, canActivate: [AuthGuard] },
  { path: 'reading-hubs', component: ReadingHubsComponent, canActivate: [AuthGuard] },
  { path: 'search-hub', component: SearchHubComponent, canActivate: [AuthGuard] },
  { path: 'banner', component: BannerComponent },
  { path: 'header', component: HeaderComponent, canActivate: [AuthGuard] },
  { path: 'footer', component: FooterComponent, canActivate: [AuthGuard] },
  { path: 'members', component: MembersComponent, canActivate: [AuthGuard] },
  { path: 'menu', component: SideMenuComponent, canActivate: [AuthGuard] },
  { path: 'add-new-topic', component: NewTopicComponent },
  { path: 'new-topic-editor/:id', component: NewTopicComponent },
  { path: 'group-page/:id/:name', component: GroupPageComponent },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'add-article', component: AddArticleComponent },
  { path: 'article/:id/:name', component: ArticleComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'topic-page-public-comments', component: PublicCommentsComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'add-article-info-page', component: AddArticleInfoPageComponent },
  { path: 'add-article-editor/:id/:name', component: AddArticleInfoPageComponent, },
  { path: 'my-comments', component: MyCommentComponent, canActivate: [AuthGuard] },
  { path: 'reading-hub-page/:id/:name', component: ReadingHubPageComponent },
  { path: 'reading-hub-gropus', component: ReadingHubGroupsComponent },
  { path: 'add-pdf', component: AddPdfComponent, canActivate: [AuthGuard] },
  { path: 'add-pdf-info-page', component: AddPdfInfoPageComponent, canActivate: [AuthGuard] },
  { path: 'pdf-viewer/:id', component: PdfViewerComponent },
  { path: 'upcoming-reading-events', component: UpcomingReadingEventsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'

    

  })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
