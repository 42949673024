import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData, GroupPageComponent } from '../../group-page/group-pagecomponent';
import { SharedServiceService } from '../../../shared/services/shared-service.service';

let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

@Component({
  selector: 'app-invite-friends-dialog',
  templateUrl: './invite-friends-dialog.component.html',
  styleUrls: ['./invite-friends-dialog.component.scss']
})
export class InviteFriendsDialogComponent implements OnInit {

  isValid = false;
  id: any
  dataSource: any;
  error: any;
  copyLink = window.location.href;

  @ViewChild('inputUrl', { static: true }) inputUrl: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    public dialogRef: MatDialogRef<GroupPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.id = this.data;
    this.getData(this.data);
  }

  public inviteFriendsForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
  });
  public copyForm: FormGroup = this.formBuilder.group({
    copy: ['']
  });

  get iForm() {
    return this.inviteFriendsForm.controls;
  }

  ngOnInit() {
    this.copyForm.controls['copy'].setValue(this.copyLink);
  }

  getData(id) {
    if (id) {
      this.spinner.show();
      this.apiService.getGroupIDList(id).subscribe(data => {
        if (data) {
          this.dataSource = data;
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
    else {
    }
  }

  copy(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0)
  }

  InviteFriends() {
    if (this.id) {
      if (this.inviteFriendsForm.invalid) {
        return this.isValid = true;
      } else {
        this.isValid = false;
        let message = {
          "invite_email": this.iForm.email.value
        }
        this.spinner.show();
        this.apiService.groupInvitation(this.id, message).subscribe((data: any) => {
          if (data) {
            this.notifyService.showSuccess(data['message']);
            this.spinner.hide();
          }
        });
        this.dialogRef.close();
      }
    } else {
      if (this.inviteFriendsForm.invalid) {
        return this.isValid = true;
      } else {
        this.isValid = false;
        let message = {
          "invite_email": this.iForm.email.value
        }
        this.spinner.show();
        this.apiService.inviteFriends(message).subscribe((data: any) => {
          if (data) {
            this.notifyService.showSuccess(data['message']);
            this.spinner.hide();
          }
        });
        this.dialogRef.close();
      }
    }
  }

}
