import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from '../shared/services/common.service';
import { NotificationService } from '../shared/services/notification.service';

let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_]*$/;
@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
    public signUpForm: FormGroup = this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
        lastName: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]],
        email: ['', [Validators.required, Validators.pattern(reg)]],
        password: ['', [Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")]]

    });
    submitted: boolean = false;

    get fval() {
        return this.signUpForm.controls;
    }

    constructor(public router: Router, private apiService: ApiService, private formBuilder: FormBuilder, public commonService: CommonService,
        private notifyService: NotificationService) { }

    ngOnInit() {
    }

    onSignUp() {
        this.submitted = true;

        if (this.signUpForm.invalid) {
            return;
        }

        

        const requestJson = {
            "first_name": this.signUpForm.controls.firstName.value,
            "last_name": this.signUpForm.controls.lastName.value,
            "email": this.signUpForm.controls.email.value,
            "password": this.signUpForm.controls.password.value,
            "password2": this.signUpForm.controls.password.value,
        }
        this.apiService.signUp(requestJson).subscribe((data: any) => {
            if (data.Email) {
                this.notifyService.showError(data['Email']);
                this.router.navigateByUrl('/login');
            } else {
                this.notifyService.showSuccess(data['response'])
                this.router.navigateByUrl('/login');
            }
            
        });
    }
}