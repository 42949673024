import { Component, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../shared/services/api.service';
import { NotificationService } from '../../shared/services/notification.service';
import { SharedServiceService } from '../../shared/services/shared-service.service';
import { MatDialogRef } from '@angular/material';
import { SideMenuComponent } from '../side-menu/side-menu.component';

let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

@Component({
  selector: 'app-reading-hub',
  templateUrl: './reading-hub.component.html',
  styleUrls: ['./reading-hub.component.scss']
})
export class ReadingHubComponent implements OnInit {

  isValid = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    public dialogRef: MatDialogRef<SideMenuComponent>,
  ) { }

  public invitationForm: FormGroup = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    organisation: [''],
    email: ['', [Validators.required, Validators.pattern(reg)]]
  });

  get iForm() {
    return this.invitationForm.controls;
  }

  ngOnInit() {
  }

  submitRequest() {
    if (this.invitationForm.invalid) {
      return this.isValid = true;
    } else {
      this.isValid = false;
      let requestJson = {
        "firstName": this.iForm.firstName.value,
        "lastName": this.iForm.lastName.value,
        "organisation": this.iForm.organisation.value,
        "email": this.iForm.email.value
      }
      this.spinner.show();
      this.apiService.applyToPublication(requestJson).subscribe((data: any) => {
        if (data) {
          this.notifyService.showSuccess(data['message']);
          this.spinner.hide();
        }
      });
      this.dialogRef.close();
    }
  }

}
