import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser'
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedServiceService } from 'src/app/shared/services/shared-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare let $: any;

@Component({
  selector: 'app-public-comments',
  templateUrl: './public-comments.component.html',
  styleUrls: ['./public-comments.component.scss']
})

export class PublicCommentsComponent implements OnInit {
  dataSource: any = [];
  public articleTab = 0;
  public articlePrivateReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ['', Validators.required],
    is_private: [true],
  });
  public articlePublicReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ['', Validators.required],
    is_private: [true],
  });
  commentLike: any;
  userId: any;
  error: any;
  count: any = [];
  editcomments: boolean = false;
  homePageArticle: any;
  selectedAricle: any;
  filterAllPost = [];
  privateComment = [];
  publicComment = [];
  page: number = 1;

  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    public sharedServiceService: SharedServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef) {
    this.editcomments = false;
    this.userId = localStorage.getItem('userId');
    this.getAllComments();
  }

  public publicCommentForm: FormGroup = this.formBuilder.group({
    comment: ['', [Validators.required, Validators.maxLength(256)]],
  });

  get pForm() {
    return this.publicCommentForm.controls;
  }

  ngOnInit() {
    this.getAllArticles();
  }

  getAllArticles() {

    this.apiService.getAllArticles().subscribe((data: any) => {
      if (data) {
        this.homePageArticle = data;
      }
    },
      error => {
        this.error = error;
        this.spinner.hide();
      });
  }

  selected(event) {
    let target = event.source.selected._element.nativeElement;
    this.selectedAricle = {
      value: event.value,
      text: target.innerText.trim()
    };
  }

  getAllComments() {
    this.spinner.show();
    this.apiService.getAllArticleComments().subscribe(data => {
      this.privateComment = [];
      this.publicComment = [];
      if (data) {
        this.dataSource = data;
        this.filterAllPost = this.dataSource.map(element => {
          if (element.is_post == true) {
            this.publicComment.push(element);
          } else {
            this.privateComment.push(element);
          }
        });

        if (this.privateComment && this.privateComment.length > 0) {
          for (let i = 0; i < this.privateComment.length; i++) {
            if (this.privateComment[i].Comment_likes && this.privateComment[i].Comment_likes.length > 0) {
              this.privateComment[i].is_like = false
              this.privateComment[i].Comment_likes.forEach(item => {
                if (item.user_id == this.userId) {
                  this.privateComment[i].is_like = true
                }
              });
            }
          }
        }
        if (this.publicComment && this.publicComment.length > 0) {
          for (let i = 0; i < this.publicComment.length; i++) {
            if (this.publicComment[i].Comment_likes && this.publicComment[i].Comment_likes.length > 0) {
              this.publicComment[i].is_like = false
              this.publicComment[i].Comment_likes.forEach(item => {
                if (item.user_id == this.userId) {
                  this.publicComment[i].is_like = true
                }
              });
            }
          }
        }
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide();
    });
  }

  deleteComment(id) {
    this.spinner.show()
    if (id) {
      this.apiService.deleteArticleComments(id).subscribe((data: any) => {
        if (data) {
          this.notifyService.showSuccess(data['message']);
          this.getAllComments();
          setTimeout(() => {
            this.spinner.hide();
          }, 1000);
        }
      })
    }
  }

  saveComments(commentID: any) {
    if (this.userId == null) {
      this.notifyService.showError("Sign in to follow the comment");
      return
    }
    let req = {
      user: this.userId,
      comment: commentID
    }
    this.apiService.SaveComments(req).subscribe((data: any) => {
      if (data) {
        this.notifyService.showSuccess(data['message']);
        this.getAllComments();
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }
    })

  }

  replyPrivate(i) {
    if (this.userId == null) {
      this.notifyService.showError("Sign in for comment reply");
      return
    }
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.privateComment[i].is_replied;
    this.privateComment.map(x => {
      x.is_replied = false;
      return x;
    });
    this.privateComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor2_' + i);
    }
  }

  replyPublic(i) {
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.publicComment[i].is_replied;
    this.publicComment.map(x => {
      x.is_replied = false;
      return x;
    });
    this.publicComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor1_' + i);
    }
  }

  updatePrivateComments(i, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.privateComment[i].is_replied
    this.privateComment.map(x => {
      x.is_replied = false;
      return x;
    });
    this.privateComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor2_' + i);
      if (text) {
        this.articlePrivateReplyForm.controls['articleReply'].setValue(text);
      }
    }
  }

  updatepPublicComments(i, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x => x.id == i));
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.publicComment[i].is_replied
    this.publicComment.map(x => {
      x.is_replied = false;
      return x;
    });
    this.publicComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic('editor1_' + i);
      if (text) {
        this.articlePublicReplyForm.controls['articleReply'].setValue(text);
      }
    }
  }

  replyComments(id, i) {
    this.spinner.show();
    let responseJson = {
      "text": this.sharedServiceService.editor.getData(),
      "comment": id,
      "user": this.userId,
    }
    let editarticle = {
      "text": this.sharedServiceService.editor.getData(),
      "section_id": this.privateComment[i].section_id,
      "article": this.privateComment[i].article.id,
      "Comment_likes": [],
    }
    if (this.editcomments) {
      this.apiService.commentsUpdate(id, editarticle).subscribe((data: any) => {
        if (data) {
          this.privateComment[i].is_replied = false;
          this.sharedServiceService.editor.setData('');
          this.editcomments = false;
          this.count = [];
          this.getAllComments();
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    } else {
      this.apiService.commentsReply(id, responseJson).subscribe((data: any) => {
        if (data) {
          this.privateComment[i].is_replied = false;
          this.sharedServiceService.editor.setData('');
          this.count = [];
          this.getAllComments();
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
  }

  closeBtn(i) {
    this.publicComment[i].is_replied = false;
  }
  closeButton(i) {
    this.privateComment[i].is_replied = false;
  }

  likeComment(id) {
    if (this.userId == null) {
      this.notifyService.showError("Sign in for comment like");
      return
    }
    let responsJSON = {
      "user": this.userId,
      "comment": id
    }
    this.apiService.commentsLike(responsJSON).subscribe((data: any) => {
      if (data) {
        this.commentLike = data;
        this.notifyService.showSuccess(data['message']);
        this.getAllComments();
      }
    });
  }

  redirectToArticle(aId, pId, name) {
    let Articlename = name.replace(/ +/g, "");
    this.router.navigate(['/article', aId, Articlename]);
    setTimeout(() => {
      $('#' + pId).addClass('paraHighlight');
      var elmnt = document.getElementById(pId);
      elmnt.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest"
      });
    }, 5000);
  }

  deleteReplyComment(id) {
    this.spinner.show();
    this.apiService.deleteReplyComment(id).subscribe((res: any) => {
      if (res) {
        this.notifyService.showSuccess(res['message']);
        this.getAllComments();
        this.spinner.hide();
      }
    }, error => {
      this.error = error;
      this.spinner.hide()
    })
  }

  postCommnet() {
    if (this.publicCommentForm.invalid) {
      return
    } else {
      let requestedJson = {
        "text": this.pForm.comment.value,
        "is_private": false,
        "is_post": true,
        "owner": this.sharedServiceService.loggedUser,
        "article": this.selectedAricle.value,
      }
      this.spinner.show();
      this.apiService.addArticleComments(requestedJson).subscribe(data => {
        if (data) {
          this.publicCommentForm.reset();
          this.getAllComments();
          this.selectedAricle = [];
          this.spinner.hide();
        }
      }, error => {
        this.error = error;
        this.spinner.hide();
      });
    }
  }

  onChange(event) {
    this.page = event;
  }

}

