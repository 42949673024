import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-group-members-dialog',
  templateUrl: './group-members-dialog.component.html',
  styleUrls: ['./group-members-dialog.component.scss']
})
export class GroupMembersDialogComponent implements OnInit {

  id: any = 22;
  dataSource: any;
  
  constructor(private route: ActivatedRoute, private apiService: ApiService){
    // this.route.params.subscribe(
    //   params => {
    //     this.id = +params['id'];
    //     console.log("this.id", this.id)
    //     this.getGroupDetails(this.id);
    //   });
  }

  ngOnInit() {
  }

  getGroupDetails() {
    this.apiService.getAllUsers().subscribe(data => {
      if (data) {
        this.dataSource = data;
      }
    });
  }

}
