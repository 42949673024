import {
  Component,
  OnInit,
  ElementRef,
  NgZone,
  Renderer2,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
  Input,
  HostListener,
} from "@angular/core";
import { ApiService } from "src/app/shared/services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SharedServiceService } from "src/app/shared/services/shared-service.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MatDrawer } from "@angular/material/sidenav";
import { MatDialog } from "@angular/material";
import { InviteFriendsComponent } from "./invite-friends/invite-friends.component";
import { Meta, Title } from "@angular/platform-browser";
import { config } from "../../config";
import { spawn } from "child_process";
import { Renderer } from "@angular/core";
import { Identifiers } from "@angular/compiler/src/render3/r3_identifiers";
import { SearchResultPopupComponent } from "src/app/shared/components/search-result-popup/search-result-popup.component";

declare let $: any;
@Component({
  selector: "app-article",
  templateUrl: "./article.component.html",
  styleUrls: ["./article.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleComponent implements OnInit {
  isCommnetBox: any;
  changeLang: any;
  @ViewChild("drawer", { static: true }) public drawer: MatDrawer;
  public articleCommentsForm: FormGroup = this.formBuilder.group({
    articleComment: ["", Validators.required],
    articleCommentSmall: [
      "",
      [Validators.required, Validators.pattern(/^\S.*$/)],
    ],
    is_private: [true],
  });

  public articleCommentsReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ["", Validators.required],
    is_private: [""],
    image: [""],
    video_link: [""],
    video_title: [""],
  });
  public qandaForm: FormGroup = this.formBuilder.group({
    question: ["", Validators.required],
  });
  public publicCommentForm: FormGroup = this.formBuilder.group({
    comment: ["", [Validators.required, Validators.maxLength(256)]],
  });
  public answerForm: FormGroup = this.formBuilder.group({
    answer: ["", Validators.required],
  });
  public articlePublicReplyForm: FormGroup = this.formBuilder.group({
    articleReply: ["", Validators.required],
    is_private: [true],
  });
  articleLocalStorage: Storage;
  selectedCommentId: any;
  currentArticalName: any;
  commentsOfAllArticales: any;
  searchQuery = false;
  groupName = "";

  get pForm() {
    return this.publicCommentForm.controls;
  }
  get qForm() {
    return this.qandaForm.controls;
  }
  editor: any;
  dataSource: any;
  id: any;
  commentForm: boolean = false;
  menuButton: boolean = true;
  annotationsMenu: boolean = false;
  sourceHide: boolean = false;
  public articleTab = 0;
  public articleTab1 = 0;
  paraData: any = [];
  paraData2: any = [];
  commentReplyForm: boolean = false;
  commentLike: any;
  publicCommentReplyForm: boolean = false;
  paraId: any;
  error: any;
  count: any = [];
  userId: any = NaN;
  sideNavMenu: boolean = true;
  allhighlightText: any;
  checked: boolean = true;
  href: string;
  commentsList = false;
  commentsListArray: any = [];
  allParseText: any = [];
  allTags: any = [];
  editcomments: boolean = false;
  ParaSectionId: any;
  selectedAricle: any;
  getArticleData = [];
  dialogId: any;
  filterAllPost = [];
  privateComment = [];
  publicComment = [];
  articleLink: any;
  highlightTab: boolean = false;
  commentTab = false;
  selectedIndex: any;
  privateNote: any = [];
  editNotes: any = [];
  sectionId: any = [];
  isPublicComment = true;
  isPrivateNote = false;
  maxFont = 1.9375;
  minFont = 0.8125;
  isMaxFont = false;
  isMinFont = false;
  sid: any;
  page: number = 1;
  commentsCount: any;
  isVisibleZoom = false;
  url: any;
  text: any;
  imageUrl: any;
  navUrl: string;
  shareUrl: string;
  isAnnotations = false;
  allComments = [];
  replyPrivateIndex: number;
  reply_comment: string;
  articleComment: string;
  currentAnnotationCID: any;
  annotationComentEdit: boolean = false;
  isProvateNote = false;
  related_paragraphes = [];
  isrelatedParagraphes = false;
  qandaListData = [];
  isSubmitted = false;
  isShowPassage = false;
  relatedId: any;
  selectData: any;
  dataId;
  commentAddedForm = false;
  textAra = false;
  paradata: any;
  isMobile = false;
  pushcomments = [];
  showbutton = false;
  sectionids = [];
  tabData: any;
  audioTooltip = "";
  link_comment = "";
  topSearchText = "";
  imageForm = false;
  imagePrompt = "";
  genratedImages = [];
  @ViewChild("sub_menu", { static: false }) sub_menu?: ElementRef<HTMLElement>;
  @HostListener("window:scroll", ["$event"])
  scrollHandler(event) {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    if (document.getElementById("c") != null) {
      document.getElementById("c").style.width = scrolled + "%";
    }
  }
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    public sharedServiceService: SharedServiceService,
    private el: ElementRef,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
    public _ngZone: NgZone,
    private routes: Router,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private renderer: Renderer
  ) {
    $("body").removeClass("loaded");
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.currentArticalName = params["name"];
    });
    this.href = this.router.url;
    this.sharedServiceService.footerHide = false;
    this.sharedServiceService.topicHide = false;
    this.sharedServiceService.logoHide = false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   this.isMobile = true;
    // } else {
    //   this.isMobile = false;
    // }
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (
      macosPlatforms.indexOf(platform) !== -1 ||
      iosPlatforms.indexOf(platform) !== -1 ||
      windowsPlatforms.indexOf(platform) !== -1
    ) {
      this.isMobile = false;
      if (this.isMobile == false) {
        if (
          /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }
    }
  }

  ngOnInit() {
    this.sharedServiceService.setDrawer(this.drawer);
    this.userId = localStorage.getItem("userId");
    this.articleLocalStorage = localStorage;
    this.getArticle(this.id);
    this.getPrivateNote();
    this.getQandAListData();
    this.commentForm = false;
    this.commentReplyForm = false;
    this.menuButton = true;
    this.sideNavMenu = true;
    this.checked = true;
    let textid = "";
    window["getSelected"] = (data, id) => {
      var element = document.createElement("button");
      let commentTitle = document.createTextNode("REMOVE");
      element.appendChild(commentTitle);
      element.className = "setName-remove";
      element.id = "remove"; // And the name too?
      element.setAttribute("onclick", "removepushcomment(" + id + ")");
      if (id !== textid) {
        data.appendChild(element);
      } else {
        var removeid = document.getElementById("remove");
        data.removeChild(removeid);
        id = "";
      }
      textid = id;
    };

    window["removepushcomment"] = (idofcomment) => {
      $("body").removeClass("loaded");
      this.apiService.pushdeletecomment(idofcomment).subscribe(
        (res: any) => {
          if (res) {
            this.notifyService.showSuccess(res["message"]);
            this.ngOnInit();
            this.createDynamicbutton();
            this.getArticle(this.id);
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
      $("body").addClass("loaded");
    };
    this.getAllComments();
  }
  getArticle(id) {
    let self = this;
    this.apiService.getArticleID(id).subscribe(
      async (data: any) => {
        this.getGroupName(data.group);
        this.getArticleData = [];
        this.privateComment = [];
        this.publicComment = [];
        this.allComments = [];
        if (data) {
          let request = {
            user: parseInt(this.userId),
            article: parseInt(id),
          };
          this.apiService
            .addLastVisitedArticles(request)
            .subscribe(async (data: any) => {});
          let sectionsId;
          this.dataSource = data;

          if (this.dataSource.audio) {
            this.audioTooltip = "No audio for this text";
          }

          let title = this.dataSource.name;
          let description = "hello";
          this.url = "https://app.hyperead.org/" + this.href;
          this.text = this.dataSource.name;
          this.imageUrl =
            this.sharedServiceService.baseUrl + this.dataSource.logo;
          this.getArticleData.push(data);
          this.getHighlightComment();
          this.getArticleSectionId();
          this.getPushcomment();
          if (this.dataSource) {
            this.filterAllPost = this.dataSource["comment"].map((element) => {
              this.allComments.push(element);
              if (element.is_post == true) {
                this.publicComment.push(element);
              } else {
                this.privateComment.push(element);
              }
            });
            let tableContents: any = "";
            let el = document.createElement("html");
            el.innerHTML = this.dataSource.parsed_text;
            this.allTags = el.querySelectorAll("p,h1,h2,h3,ol,ul,figure");
            for (let i = 0; i < this.allTags.length; i++) {
              let comment: any = "";
              //
              if (
                this.dataSource["Push_Comment"] &&
                this.dataSource["Push_Comment"].length > 0
              ) {
                this.dataSource["Push_Comment"]
                  .sort(function (a, b) {
                    return a["id"] - b["id"];
                  })
                  .map((element) => {
                    this.tabData = "";
                    if (this.allTags[i].childNodes.length >= 0) {
                      if (
                        this.allTags[i].getAttribute("id") == element.section_id
                      ) {
                        if (this.userId === this.dataSource.owner) {
                          const highlightElement =
                            document.createElement("button");
                          highlightElement.value = element.comment[0].id;
                          highlightElement.id = "data";
                          highlightElement.className = "setName";
                          const commentIcon = document.createElement("span");
                          commentIcon.setAttribute(
                            "class",
                            "material-icons icons-align"
                          );
                          const commentIconTitle =
                            document.createTextNode("more_vert");
                          commentIcon.appendChild(commentIconTitle);
                          let id = element.id;
                          highlightElement.setAttribute(
                            "onclick",
                            "getSelected(this," + id + ")"
                          );
                          highlightElement.appendChild(commentIcon);
                          this.tabData =
                            "<p>" +
                            element.comment[0].text +
                            "</p>" +
                            '<p style="color:#5F6C7B;font-size:21px;display: flex;">' +
                            "<span>" +
                            element.comment[0].full_name +
                            "</span>" +
                            highlightElement.outerHTML +
                            "</p>";
                        } else {
                          this.tabData =
                            "<p>" +
                            element.comment[0].text +
                            "</p>" +
                            '<p style="color:#5F6C7B;font-size:21px;display: flex;">' +
                            "<span>" +
                            element.comment[0].full_name +
                            "</span>" +
                            "</p>";
                        }
                        comment =
                          '<div class="addtext">' +
                          this.tabData +
                          "</div>" +
                          "<div>" +
                          "<span>" +
                          this.allTags[i].outerHTML +
                          "</span>" +
                          "</div>";
                      }
                    }
                  });
                if (!comment) {
                  comment =
                    "<div>" +
                    "<span>" +
                    this.allTags[i].outerHTML +
                    "</span>" +
                    "</div>";
                }
              } else {
                comment =
                  "<div>" +
                  "<span>" +
                  this.allTags[i].outerHTML +
                  "</span>" +
                  "</div>";
              }

              this.pushcomments.push(comment);
              this.paraData.push(
                this.allTags[i].outerHTML.replace("id", "pid")
              );
            }

            this.pushcomments = this.pushcomments.filter(
              (el, i, a) => i === a.indexOf(el)
            );

            this.dataSource.parsed_text = this.pushcomments;
            for (let i = 0; i < this.allTags.length; i++) {
              if (
                this.allTags[i].tagName == "H1" ||
                this.allTags[i].tagName == "H2" ||
                this.allTags[i].tagName == "H3"
              ) {
                let tmp = document.createElement("div");
                tmp.appendChild(this.allTags[i]);
                tableContents += tmp.innerHTML.replace("id", "ids");
              }
            }
            setTimeout(function () {
              $("#tableContents").html($(tableContents));
              self.redirectToHeader();
            }, 100);

            let commentSectionIds = [];
            for (let i = 0; i < this.dataSource["comment"].length; i++) {
              setTimeout(() => {
                sectionsId = $("#articleText").find(
                  "#" + this.dataSource["comment"][i].section_id
                );
                this.commentsCount = this.dataSource["comment"].filter(
                  (item) => item.section_id === sectionsId[0].id
                ).length;
                let publishComment = $(
                  '<button class="commentCountBtn" id="c' +
                    this.dataSource["comment"][i].section_id +
                    '"><span class="material-icons icons-align comment-icons-align" id="cmd' +
                    this.dataSource["comment"][i].section_id +
                    '">school</span><span class="icons-align count-iccon-style" id="count' +
                    this.dataSource["comment"][i].section_id +
                    '">' +
                    this.commentsCount +
                    "</span></button>"
                );
                if (
                  !commentSectionIds.includes(
                    this.dataSource["comment"][i].section_id
                  )
                ) {
                  commentSectionIds.push(
                    this.dataSource["comment"][i].section_id
                  );
                  $(publishComment).insertAfter(sectionsId);
                  $(publishComment).on("click", function (event) {
                    self.commentsListArray = [];
                    self.count = [];
                    self.removeSelectedText();
                    localStorage.setItem("pId", event.currentTarget.id);
                    $("#articleText").addClass("articleText");
                    let sectionid = event.currentTarget.id.split("c");
                    $("#" + sectionid[1]).addClass("paraHighlight");
                    self.paragraphListComments(event.currentTarget.id);
                    $("#" + event.currentTarget.id).addClass("paraHighlight");
                    var elmnt = document.getElementById(event.currentTarget.id);
                    elmnt.scrollIntoView({
                      behavior: "auto",
                      block: "nearest",
                      inline: "nearest",
                    });
                  });
                }
              }, 1000);
              if (
                this.dataSource["comment"][i].likedBy &&
                this.dataSource["comment"][i].likedBy.length > 0
              ) {
                this.dataSource["comment"][i].is_like = false;
                this.dataSource["comment"][i].likedBy.forEach((item) => {
                  if (item.owner.id == this.userId) {
                    this.dataSource["comment"][i].is_like = true;
                  }
                });
              }
            }
            setTimeout(function () {
              $("body").addClass("loaded");
              $("#articleText").html(self.dataSource.parsed_text);
            }, 100);
          }
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.notifyService.showError("Article Not found.");
        this.router.navigate(["/home"]);
      }
    );
  }

  getPushcomment() {
    let el = document.createElement("html");
    el.innerHTML = this.dataSource.parsed_text;
    this.allTags = el.querySelectorAll("p,h1,h2,h3k,ol,ul,figure");
    for (
      var i = 0;
      typeof this.allTags[i] != "undefined";
      this.sectionids.push(this.allTags[i++].getAttribute("id"))
    );
  }
  backClicked() {
    this.routes.navigate(["/home"]);
  }
  replyConversionComment(i) {
    let reply = !this.allComments[i].is_replied;
    this.replyPrivateIndex = i;
    this.allComments.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.allComments[i].is_replied = reply;
    this.reply_comment = "";
  }

  saveComments(id, i) {
    this.spinner.show();
    let requestJson = {
      text: this.reply_comment,
      comment: id,
      user: this.userId,
    };
    this.apiService.commentsReply(id, requestJson).subscribe(
      (data: any) => {
        if (data) {
          this.allComments[i].is_replied = false;
          this.getArticle(this.id);
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  editAnnotationCoomment(i) {
    this.articleComment = this.allComments[i].text;
    this.annotationComentEdit = true;
    this.currentAnnotationCID = this.allComments[i].id;
  }

  deleteAnnotationComment(i) {
    let commentID = this.allComments[i].id;
    this.deleteComment(commentID);
    this.getArticle(this.id);
  }
  postComment() {
    if (this.articleComment == "") {
      return;
    } else {
      if (this.articleComment.startsWith(" ")) {
        return;
      }
      let requestedJson = {
        text: this.articleComment,
        is_private: false,
        is_post: true,
        owner: this.sharedServiceService.loggedUser,
        article: this.id,
      };
      this.spinner.show();
      if (this.annotationComentEdit) {
        let editarticle = {
          text: this.articleComment,
          article: this.id,
          Comment_likes: [],
        };
        this.apiService
          .commentsUpdate(this.currentAnnotationCID, editarticle)
          .subscribe(
            (data: any) => {
              if (data) {
                this.getArticle(this.id);
                this.articleComment = "";
                this.spinner.hide();
                this.annotationComentEdit = false;
                this.currentAnnotationCID = "";
              }
            },
            (error) => {
              this.error = error;
              this.spinner.hide();
              this.articleComment = "";
              this.annotationComentEdit = false;
              this.currentAnnotationCID = "";
            }
          );
      } else {
        this.apiService.addArticleComments(requestedJson).subscribe(
          (data) => {
            if (data) {
              this.getArticle(this.id);
              this.selectedAricle = [];
              this.articleComment = "";
              this.spinner.hide();
            }
          },
          (error) => {
            this.error = error;
            this.spinner.hide();
          }
        );
      }
    }
  }

  likeCommentByAnnotaions(id) {
    this.spinner.show();
    let responsJSON = {
      user: this.userId,
      comment: id,
    };
    this.apiService.commentsLike(responsJSON).subscribe(
      (data: any) => {
        if (data) {
          this.commentLike = data;
          this.notifyService.showSuccess(data["message"]);
          this.ngOnInit();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  shareArticle(id, name) {
    let Articlename = name.replace(/ +/g, "");
    if (id && name) {
      var url = this.url;
      var img = config.API_ENDPOINT + this.dataSource.image;
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(img) +
          "&t=" +
          encodeURIComponent(document.title) +
          "&quote=" +
          this.dataSource.description,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
      return false;
    }
  }

  ngAfterViewInit() {
    this.createDynamicbutton();
    this.sharedServiceService.initializeCkeditor2();
    this.checked = true;
    if (this.sharedServiceService.scrollSectionId != "") {
      let sId = this.sharedServiceService.scrollSectionId;
      setTimeout(() => {
        $("#" + sId).addClass("paraHighlight");
        var elmnt = document.getElementById(sId);
        if (elmnt != null) {
          elmnt.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "nearest",
          });
          this.sharedServiceService.scrollSectionId = "";
        } else {
          this.notifyService.showError("Section Not found.");
        }
      }, 5000);
    }
  }

  selected(event) {
    let target = event.source.selected._element.nativeElement;
    this.selectedAricle = {
      value: event.value,
      text: target.innerText.trim(),
    };
  }

  createDynamicbutton() {
    const self = this;
    let htmlContent;
    let textSelection;
    let isSelected: boolean = false;
    let paraDataID;
    $(document).ready(function () {
      setTimeout(() => {
        $("#articleText")
          .find("h3, p, ol, ul, img, oembed")
          .on("click", function (e) {
            let cmdID = e.target.id.split("cmd");
            let commentID = e.target.id.split("count");
            if (
              e.target.id == "commentTitle" ||
              e.target.id == "askTitle" ||
              e.target.id == "highlightTitle" ||
              e.target.id == "sourceTitle" ||
              e.target.id == "cmd" + cmdID[1] ||
              e.target.id == "count" + commentID[1]
            ) {
              return;
            }
            if (!isSelected) {
              paraDataID = $(this).attr("data-name");
              if (typeof paraDataID == "undefined" || !paraDataID) {
                paraDataID = $(this).find("a").attr("data-name");
              }
              if (typeof paraDataID == "undefined" || !paraDataID) {
                paraDataID = $(this).find("li").attr("data-name");
              }
              self.clickButtonEvent(paraDataID, e);
              self.setIDEvent(paraDataID, htmlContent);
            } else {
              isSelected = false;
            }
            return;
          });
        $("#articleText")
          .find("h3, p, ol, ul, img, oembed")
          .on("mouseup", function (e) {
            if (window.getSelection().toString()) {
              isSelected = true;
              paraDataID = $(this).attr("data-name");
              if (typeof paraDataID == "undefined" || !paraDataID) {
                paraDataID = $(this).find("a").attr("data-name");
              }
              if (typeof paraDataID == "undefined" && !paraDataID) {
                paraDataID = $(this).find("li").attr("data-name");
              }
              if (window.getSelection) {
                textSelection = window.getSelection();
              } else if (document.getSelection) {
                textSelection = document.getSelection();
              }
              if (textSelection.anchorNode != null) {
                self.removeSelectedText();
                let highlighedText = textSelection.getRangeAt(0);
                let div = document.createElement("span");

                let content = highlighedText.extractContents();
                let span = document.createElement("span");
                span.setAttribute("class", "selectedText");
                span.appendChild(content);
                htmlContent = span.innerHTML;
                highlighedText.insertNode(span);
                let popDiv = document.createElement("span");
                popDiv.setAttribute("class", "popDiv bookmark");
                let highlightElementFirst = document.createElement("button");
                let highlightElementSecond = document.createElement("button");
                let highlightElementThird = document.createElement("button");
                let commentTitle = document.createTextNode("Comment");
                let askTitle = document.createTextNode("ASK");
                let highlightTitle = document.createTextNode("Bookmark");

                highlightElementFirst.appendChild(commentTitle);
                highlightElementSecond.appendChild(askTitle);
                highlightElementThird.appendChild(highlightTitle);

                highlightElementFirst.className = "white highlight_menu";
                highlightElementSecond.className = "white highlight_menu";
                highlightElementThird.className = "white highlight_menu";

                highlightElementFirst.id = "commentTitle";
                highlightElementSecond.id = "askTitle";
                highlightElementThird.id = "highlightTitle";

                let commentIcon = document.createElement("span");
                commentIcon.setAttribute("class", "material-icons icons-align");
                let commentIconTitle = document.createTextNode("comment");

                let askIcon = document.createElement("span");
                askIcon.setAttribute("class", "material-icons icons-align");
                let askIconTitle = document.createTextNode("record_voice_over");

                let bookamrtIcon = document.createElement("span");
                bookamrtIcon.setAttribute(
                  "class",
                  "material-icons icons-align"
                );
                let bookmarkIconTitle =
                  document.createTextNode("bookmark_border");

                commentIcon.appendChild(commentIconTitle);
                askIcon.appendChild(askIconTitle);
                bookamrtIcon.appendChild(bookmarkIconTitle);

                highlightElementFirst.appendChild(commentIcon);
                highlightElementSecond.appendChild(askIcon);
                highlightElementThird.appendChild(bookamrtIcon);
                self.setIDEvent(paraDataID, htmlContent);
                highlightElementFirst.addEventListener(
                  "click",
                  function (event) {
                    self.commentButtonClick(paraDataID, htmlContent);
                  }
                );

                highlightElementSecond.addEventListener(
                  "click",
                  function (event) {}
                );

                highlightElementThird.addEventListener(
                  "click",
                  function (event) {
                    self.highlightText(paraDataID, htmlContent);
                  }
                );

                popDiv.appendChild(highlightElementFirst);
                popDiv.appendChild(highlightElementSecond);
                popDiv.appendChild(highlightElementThird);
                if (span.innerHTML.length > 0) {
                  span.append(popDiv);
                  $("#" + paraDataID).addClass("highlighted");
                }
                //Remove Selection: To avoid extra text selection in IE
                if (window.getSelection) {
                  if (window.getSelection().empty) {
                    // Chrome
                    window.getSelection().empty();
                  } else if (window.getSelection().removeAllRanges) {
                    // Firefox
                    window.getSelection().removeAllRanges();
                  }
                } else if (document.getSelection) {
                  // IE?
                  document.getSelection().empty();
                }
              } else {
                isSelected = false;
                return false;
              }
            } else {
              isSelected = false;
            }
          });
        isSelected = false;
      }, 7000);
    });
  }
  setIDEvent(id, htmlContent) {
    this.dataId = id;
    this.paradata = htmlContent;
  }

  clickButtonEvent(paraDataID, e) {
    let htmlContent;
    let textSelection;
    this.sid = paraDataID;
    localStorage.setItem("pId", paraDataID);
    const self = this;
    let paraID = $("#articleText").find("#" + paraDataID);
    if (this.commentForm) {
      this.commentForm = true;
      this.imageForm = false;
      this.genratedImages = [];
    }
    this.commentForm = true;
    this.imageForm = false;
    this.genratedImages = [];
    if (this.commentForm) {
      document.querySelectorAll(".ck-editor").forEach((el) => el.remove());
      this.paraId = paraID[0].id;
      this.dataId = paraID[0].id;
      $(".comment-box").css({ top: $("#" + this.paraId).offset().top + 150 });
      $("#articleText").addClass("highlighted");
      setTimeout((x) => {
        this.sharedServiceService.initializeCkeditor();
      }, 100);
    }
    self.removeSelectedText();
    if (window.getSelection) {
      textSelection = window.getSelection();
    } else if (document.getSelection) {
      textSelection = document.getSelection();
    }
    let highlighedText = textSelection.getRangeAt(0);
    let content = highlighedText.extractContents(),
      span = document.createElement("SPAN");
    span.appendChild(content);
    htmlContent = span.innerHTML;
    highlighedText.insertNode(span);

    htmlContent = span.innerHTML;
    let popDiv = document.createElement("span");
    popDiv.setAttribute("class", "popDiv source");

    let highlightElementSecond = document.createElement("button");
    let highlightElementThird = document.createElement("button");

    let askTitle = document.createTextNode("ASK");
    let sourceTitle = document.createTextNode("Source");

    highlightElementSecond.appendChild(askTitle);
    highlightElementThird.appendChild(sourceTitle);

    highlightElementSecond.className = "white highlight_menu askbtn";
    highlightElementThird.className = "white highlight_menu sourcebtn";

    highlightElementSecond.id = "askTitle";
    highlightElementThird.id = "sourceTitle";

    let askIcon = document.createElement("span");
    askIcon.setAttribute("class", "material-icons icons-align");
    let askIconTitle = document.createTextNode("record_voice_over");

    let sourceIcon = document.createElement("span");
    sourceIcon.setAttribute("class", "material-icons icons-align");
    let sourceIconTitle = document.createTextNode("menu_book");
    askIcon.appendChild(askIconTitle);
    sourceIcon.appendChild(sourceIconTitle);

    highlightElementSecond.appendChild(askIcon);
    highlightElementThird.appendChild(sourceIcon);

    highlightElementSecond.addEventListener("click", function (event) {});

    highlightElementThird.addEventListener("click", function (event) {
      self.sourceButton();
    });

    popDiv.appendChild(highlightElementSecond);
    popDiv.appendChild(highlightElementThird);

    $(paraID[0]).addClass("highlighted");
    $(paraID[0]).append(popDiv);
  }

  commentButtonClick(id, htmlContent) {
    document.querySelectorAll(".ck-editor").forEach((el) => el.remove());
    this.commentForm = true;
    this.imageForm = false;
    this.genratedImages = [];
    if (this.commentForm) {
      this.paraId = id;
      this.commentForm = true;
      this.imageForm = false;
      this.genratedImages = [];
    }
    $(".comment-box").css({ top: $("#" + this.paraId).offset().top + 150 });
    $("#articleText").addClass("p_margin");
    if (id === this.dataId) {
      setTimeout((x) => {
        this.sharedServiceService.initializeCkeditor();
      }, 100);
      this.removeSelectedText();
    }
    this.commentAddedForm = false;
    this.selectData = htmlContent;

    this.paraId = id;
    this.sideNavMenu = false;
    this.menuButton = true;
    this.sourceHide = false;
    this.commentsList = false;
    // let addBnt = document.querySelector('.public-comment-comments');
    // addBnt.classList.add("annotations-sources-main-animation");
    this.checked = true;
    this.changeDetector.detectChanges();

    let redirctToArticle = $("#articleText").find("#" + this.paraId);
    redirctToArticle.addClass("paraHighlight");
    redirctToArticle[0].scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "nearest",
    });
  }

  highlightText(id, highlightText) {
    let self = this;
    this.paraId = id;
    this.spinner.show();
    let responseJson = {
      text: highlightText,
      section_id: this.paraId,
      is_private: true,
      owner: this.userId,
      article: this.id,
    };
    this.apiService
      .highlightComment(this.dataSource.id, responseJson)
      .subscribe(
        (data) => {
          if (data) {
            this.commentForm = false;
            this.sideNavMenu = true;
            this.menuButton = true;
            this.commentAddedForm = false;
            this.notifyService.showSuccess(
              "Highlighted text saved Successfully."
            );
            this.getHighlightComment();
            setTimeout(() => {
              self.closeButton();
            }, 200);
            highlightText = "";
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
  }

  editPrivateNote(i, notes) {
    this.selectedIndex = i;
    this.editNotes = notes;
    let index = this.count.findIndex((x) => x.id == i);
    if (index === -1) {
      this.count.push({ id: i });
    }
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorArticleComments(
        "editor4_" + i
      );
      if (notes.text) {
        this.articleCommentsForm.controls["articleComment"].setValue(
          notes.text
        );
      }
    }
  }

  closePrivateNote() {
    this.selectedIndex = null;
  }

  onChange() {
    if (this.articleCommentsForm.controls["is_private"].value == false) {
      this.isPublicComment = false;
      this.isPrivateNote = true;
    } else {
      this.isPublicComment = true;
      this.isPrivateNote = false;
    }
  }
  onChangetoggle() {
    this.isProvateNote = !this.isProvateNote;
  }
  openAnnotations() {
    this.isAnnotations = true;
  }

  closeAnnotations() {
    this.isAnnotations = false;
    this.commentForm = false;
    this.commentAddedForm = false;
  }

  addComments() {
    let self = this;
    let commentText;
    if (this.sharedServiceService.editor.getData()) {
      commentText = this.sharedServiceService.editor.getData();
    } else {
      commentText = this.articleCommentsForm.controls.articleComment.value;
    }
    this.spinner.show();
    let responseJson = {
      text: commentText,
      Highlighted_text: this.selectData,
      is_private: this.articleCommentsForm.controls.is_private.value
        ? "false"
        : "true",
      owner: this.userId,
      article: this.id,
      highlight: 0,
      section_id: this.paraId,
      permission_status: 0,
      video_link: "",
      video_title: "",
      linked_comment: this.selectedCommentId,
    };
    if (commentText !== "") {
      this.apiService.addArticleComments(responseJson).subscribe(
        (data) => {
          if (data) {
            setTimeout(() => {
              this.articleCommentsForm.reset();
              this.sharedServiceService.editor.setData("");
              self.closeButton();
              this.commentForm = false;
            }, 200);
            this.ngOnInit();
            this.createDynamicbutton();
            this.checked = true;
            this.commentForm = false;
            this.link_comment = "";
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    } else {
      this.notifyService.showError("Cannot upload blank document");
      this.spinner.hide();
    }
  }

  addPrivateNote() {
    let self = this;
    let commentText;
    if (this.sharedServiceService.editor.getData()) {
      commentText = this.sharedServiceService.editor.getData();
    } else {
      commentText = this.articleCommentsForm.controls.articleCommentSmall.value;
    }

    let requestedJson = {
      text: commentText,
      Highlighted_text: this.selectData,
      is_private: this.articleCommentsForm.controls.is_private.value
        ? true
        : false,
      article: this.id,
      user: this.userId,
      section_id: this.paraId,
    };
    this.spinner.show();
    if (
      this.sharedServiceService.editor.getData() ||
      this.articleCommentsForm.controls.articleCommentSmall.value
    ) {
      this.apiService.addPrivateNote(requestedJson).subscribe(
        (data) => {
          if (data) {
            setTimeout(() => {
              this.articleCommentsForm.reset();
              this.sharedServiceService.editor.setData("");
              self.closeButton();
              this.commentForm = false;
            }, 200);
            this.ngOnInit();
            this.createDynamicbutton();
            this.getPrivateNote();
            this.commentForm = false;
            this.spinner.hide();
            this.checked = true;
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    } else {
      this.notifyService.showError("Cannot upload blank document");
      this.spinner.hide();
    }
  }

  deleteComment(id) {
    this.spinner.show();
    if (id) {
      this.apiService.deleteArticleComments(id).subscribe(
        (data: any) => {
          if (data) {
            this.notifyService.showSuccess(data["message"]);
            this.closeButton();
            this.ngOnInit();
            this.createDynamicbutton();
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    }
  }

  updatePrivateNote() {
    let commentText;
    this.spinner.show();
    if (this.sharedServiceService.editor.getData()) {
      commentText = this.sharedServiceService.editor.getData();
    } else {
      commentText = this.articleCommentsForm.controls.articleCommentSmall.value;
    }
    let requestedJson = {
      text: commentText,
      article: this.editNotes.article,
    };
    if (this.editNotes.id) {
      this.apiService
        .editPrivateNote(this.editNotes.id, requestedJson)
        .subscribe(
          (data) => {
            if (data) {
              this.notifyService.showSuccess(data["message"]);
              this.selectedIndex = null;
              this.articleCommentsForm.reset();
              this.getPrivateNote();
              this.spinner.hide();
            }
          },
          (error) => {
            this.error = error;
            this.spinner.hide();
          }
        );
    }
  }

  sourceButton() {
    let self = this;
    this.sourceHide = true;
    this.annotationsMenu = false;
    this.menuButton = false;
    this.commentForm = false;
    this.commentsList = false;
    this.sharedServiceService.close();
    setTimeout(() => {
      $("#main-content")
        .find(".source-Btn")
        .addClass("annotations-sources-main-animation");
      self.removeSelectedText();
    }, 200);
  }

  annotationsButton() {
    localStorage.removeItem("pId");
    let self = this;
    this.sourceHide = false;
    this.annotationsMenu = true;
    this.menuButton = false;
    this.commentForm = false;
    this.commentsList = false;
    this.sharedServiceService.close();
    setTimeout(() => {
      $("#main-content")
        .find(".annotations-btn")
        .addClass("annotations-sources-main-animation");
      self.removeSelectedText();
    }, 200);
  }

  annotationsButton2() {
    localStorage.removeItem("pId");
    this.annotationsMenu = false;
    this.menuButton = false;
    this.commentForm = false;
    this.commentsList = false;
    this.commentTab = false;
    this.sourceHide = false;
    this.highlightTab = true;
    this.sharedServiceService.close();
  }

  annotationsButton3() {
    localStorage.removeItem("pId");
    this.annotationsMenu = false;
    this.menuButton = false;
    this.commentForm = false;
    this.commentsList = false;
    this.commentTab = false;
    this.sourceHide = false;
    this.commentTab = true;
    this.sharedServiceService.close();
  }

  closeBtn(j) {
    this.privateComment[j].is_replied = false;
  }

  closePublicBtn(i) {
    this.publicComment[i].is_replied = false;
    this.articlePublicReplyForm.reset();
  }

  replyBtn(id, i) {
    let index = this.count.findIndex((x) => x.id == i);
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.privateComment[i].is_replied;
    this.privateComment.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.privateComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic("editor3_" + i);
    }
  }

  replyPublic(i) {
    this.articlePublicReplyForm.reset();
    let index = this.count.findIndex((x) => x.id == i);
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.publicComment[i].is_replied;
    this.publicComment.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.publicComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic("editor1_" + i);
    }
  }

  replyPublicComments(id, i) {
    this.spinner.show();
    let self = this;
    let responseJson = {
      text: this.sharedServiceService.editor.getData(),
      comment: id,
      user: this.userId,
    };
    let editarticle = {
      text: this.sharedServiceService.editor.getData(),
      section_id: this.publicComment[i].section_id,
      article: this.id,
      Comment_likes: [],
    };
    if (this.editcomments) {
      this.apiService.commentsUpdate(id, editarticle).subscribe(
        (data: any) => {
          if (data) {
            self.closeButton();
            this.publicComment[i].is_replied = false;
            if (this.commentsListArray && this.commentsListArray.length > 0) {
              this.commentsListArray[i].is_replied = false;
              this.count = [];
            }
            this.articlePublicReplyForm.reset();
            this.count = [];
            this.editcomments = false;
            this.spinner.hide();
            this.ngOnInit();
            this.createDynamicbutton();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    } else {
      this.apiService.commentsReply(id, responseJson).subscribe(
        (data: any) => {
          if (data) {
            self.closeButton();
            this.publicComment[i].is_replied = false;
            this.articlePublicReplyForm.reset();
            this.count = [];
            this.spinner.hide();
            this.ngOnInit();
            this.createDynamicbutton();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    }
  }
  replyComments(id, i, sid) {
    let self = this;
    let responseJson = {
      text: this.sharedServiceService.editor.getData(),
      comment: id,
      user: this.userId,
    };
    let editarticle = {
      text: this.sharedServiceService.editor.getData(),
      section_id: sid,
      article: this.id,
      Comment_likes: [],
    };
    this.spinner.show();
    if (this.editcomments) {
      this.apiService.commentsUpdate(id, editarticle).subscribe(
        (data: any) => {
          if (data) {
            this.dataSource["comment"][i].is_replied = false;
            if (this.commentsListArray && this.commentsListArray.length > 0) {
              this.count = [];
            }
            this.count = [];
            this.editcomments = false;
            this.ngOnInit();
            this.spinner.hide();
            this.createDynamicbutton();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    } else {
      this.apiService.commentsReply(id, responseJson).subscribe(
        (data: any) => {
          if (data) {
            self.closeButton();
            this.dataSource["comment"][i].is_replied = false;
            this.count = [];
            this.ngOnInit();
            this.spinner.hide();
            this.createDynamicbutton();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    }
  }

  likeComment(id) {
    this.spinner.show();
    let responsJSON = {
      user: this.userId,
      comment: id,
    };
    this.apiService.commentsLike(responsJSON).subscribe(
      (data: any) => {
        if (data) {
          this.commentLike = data;
          this.notifyService.showSuccess(data["message"]);
          this.ngOnInit();
          this.createDynamicbutton();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  submitQandA() {
    this.spinner.show();
    if (this.qandaForm.invalid) {
      return false;
    }
    let responsJSON = {
      questionBy_user: this.userId,
      article: this.id,
      question: this.qandaForm.value.question,
    };
    this.apiService.addQandA(responsJSON).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(data["message"]);
          this.ngOnInit();
          this.getQandAListData();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }

  postAnswer(id) {
    this.spinner.show();
    if (this.answerForm.invalid) {
      return false;
    }
    let responsJSON = {
      answer: this.answerForm.value.answer,
    };
    this.apiService.postAnswerData(id, responsJSON).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(data["message"]);
          this.ngOnInit();
          this.getQandAListData();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
    this.spinner.hide();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.commentForm = false;
    let self = this;
    this.count = [];
    if (tabChangeEvent.index == 0) {
      setTimeout(() => {
        self.closeButton();
      }, 200);
    } else if (tabChangeEvent.index == 1) {
      this.sharedServiceService.close();
      $(".highlight").removeClass("highlight");
      $(".paraHighlight").removeClass("paraHighlight");
    }
  }

  redirectToComment() {
    let tabCount = 2;
    this.articleTab = (this.articleTab + 1) % tabCount;
  }

  redirectToArticle(id) {
    const tabCount = 2;
    this.articleTab = (this.articleTab - 1) % tabCount;
    setTimeout(() => {
      $(".highlight").removeClass("highlight");
      $(".paraHighlight").removeClass("paraHighlight");
      let redirctToArticle = $("#articleText").find("#" + id);
      redirctToArticle.addClass("paraHighlight");
      redirctToArticle[0].scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "nearest",
      });
    }, 1000);
  }

  redirectToPara1(id) {
    if (id) {
      setTimeout(() => {
        this.removeSelectedText();
        let redirctToArticle = $("#articleText").find("#" + id);
        redirctToArticle.addClass("highlight");
        redirctToArticle[0].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }, 1000);
    }
    this.closeButton();
  }

  deleteReply(id) {
    this.spinner.show();
    this.apiService.deleteReplyComment(id).subscribe(
      (res: any) => {
        if (res) {
          this.notifyService.showSuccess(res["message"]);
          this.ngOnInit();
          this.createDynamicbutton();
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  closeAnnotationBtn() {
    let self = this;
    this.annotationsMenu = false;
    this.menuButton = true;
    this.commentsList = false;
    self.removeSelectedText();
  }

  closeSourceBtn() {
    let self = this;
    this.sourceHide = false;
    this.menuButton = true;
    this.annotationsMenu = false;
    this.commentsList = false;
    self.removeSelectedText();
  }

  deleteArtcle(id) {
    this.spinner.show();
    this.apiService.deleteArticle(id).subscribe(
      (data: any) => {
        if (data.message) {
          this.notifyService.showSuccess(data["message"]);
          this.router.navigate(["/home"]);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }
  closeList() {
    this.commentsList = false;
    this.sideNavMenu = true;
    this.menuButton = true;
  }
  getHighlightComment() {
    this.dataSource.id;
    this.apiService.getHighlightComment(this.dataSource.id).subscribe(
      (highlight: any) => {
        if (highlight) {
          this.allhighlightText = highlight;
          setTimeout(function () {
            $("body").addClass("loaded");
          }, 1000);
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  deleteHighlightComment(id) {
    this.spinner.show();
    this.apiService.deleteSaveedHighlightComment(id).subscribe(
      (res: any) => {
        if (res.message) {
          this.notifyService.showSuccess(res["message"]);
          this.getHighlightComment();
          this.spinner.hide();
          this.createDynamicbutton();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  highlightSavedTextClick(id, text) {
    setTimeout(() => {
      $("#articleText").removeHighlight();
      let highlightedClass = $("#articleText").find("#" + id);
      highlightedClass.highlight(text);
      setTimeout(() => {
        let highlightedtezt = $("#articleText").find(".highlight");
        highlightedtezt[0].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }, 1000);
    }, 1000);
  }
  highlightSavedTextRelatedClick(id, text) {
    setTimeout(() => {
      let highlightedClass = $("#articleText").find("#" + id);
      highlightedClass.highlight(text);
      highlightedClass.addClass("paraHighlight");
      highlightedClass[0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 1000);
  }
  getRelatedParagraph(id, text) {
    
    this.related_paragraphes = [];
    this.apiService.getArticleRelatedID(id).subscribe((res: any) => {
      if (res) {
        res.forEach((x) => {
          if (id === x.data_name) {
            this.relatedId = x.data_name;
            this.isShowPassage = true;
            this.isrelatedParagraphes = !this.isrelatedParagraphes;
            x.related_paragraph.forEach((obj) => {
              obj = {
                id: x.data_name,
                par: obj.related_paragraph,
                section_id: obj.section_id,
                section_number: obj.paragraph_id,
              };
              this.related_paragraphes.push(obj);
              setTimeout(function () {
                $("body").addClass("loaded");
              }, 1000);
            });
          }
        });
      }
    });
  }
  closerelated() {
    this.relatedId = "";
    this.isShowPassage = false;
    this.ngOnInit();
  }
  getQandAListData() {
    this.apiService.getQandAList(this.id).subscribe((res: any) => {
      if (res.data) {
        this.qandaListData = res.data;
        setTimeout(function () {
          $("body").addClass("loaded");
        }, 1000);
      }
    });
  }
  // Table of content click on Header redirect to Paragraph Header
  redirectToHeader() {
    let paraDataID;
    $("#tableContents")
      .find("h1, h2, h3")
      .on("click", function (e) {
        paraDataID = $(this).attr("data-name");
        if (typeof paraDataID == "undefined" || !paraDataID) {
          paraDataID = $(this).find("a").attr("data-name");
        }
        $("#headerTableComment").addClass("tableContent");
        $("#headerTableComment").removeClass(" mat-drawer-opened");
        let hadertTitle = $("#articleText").find("#" + paraDataID);
        hadertTitle[0].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
        hadertTitle.scrollTop();
      });
  }
  linkandunlikeData(id) {
    this.apiService.likeandunlikeQuestion(id).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(data["message"]);
          this.getQandAListData();
          this.ngOnInit();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }
  //reject question
  rejectQuestionData(id) {
    this.apiService.rejectQuestion(id).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(data["message"]);
          this.getQandAListData();
          this.ngOnInit();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }
  // Annotation click on Comments redirect to Particular Paragraph
  redirectToPara(id) {
    let self = this;
    setTimeout(() => {
      self.removeSelectedText();
      let redirctToArticle = $("#articleText").find("#" + id);
      redirctToArticle.addClass("paraHighlight");
      redirctToArticle[0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 1000);
  }
  //Opene left side menu
  toggleDrawer() {
    $(".tableContent").removeClass("tableContent");
    $(".highlight").removeClass("highlight");
    $(".paraHighlight").removeClass("paraHighlight");
    this.sharedServiceService.toggle();
    this.annotationsMenu = false;
    this.commentForm = false;
    this.sourceHide = false;
    this.menuButton = true;
  }
  //Close left side menu
  closeDrawer() {
    this.sharedServiceService.close();
  }
  paragraphListComments(sectionCommentId) {
    this.commentsList = true;
    let sectionsid = sectionCommentId.split("c");
    this.ParaSectionId = sectionsid;
    this.commentAddedForm = false;
    this.menuButton = false;
    this.commentForm = false;
    this.annotationsMenu = false;
    $("#main-content")
      .find(".comment-box-main")
      .addClass("annotations-sources-main-animation");
    this.dataSource["comment"].forEach((element) => {
      if (sectionsid[1] === element.section_id) {
        this.commentsListArray.push(element);
      }
    });
  }
  editCommentsList(k, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x) => x.id == k);
    if (index === -1) {
      this.count.push({ id: k });
    }
    let reply = !this.commentsListArray[k].is_replied;
    this.commentsListArray.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.commentsListArray[k].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorArticleComments(
        "editor2_" + k
      );
      this.articleCommentsReplyForm.controls["articleReply"].setValue(text);
    }
  }
  commentReplyBtn(k) {
    this.articleCommentsReplyForm.controls["articleReply"].setValue("");
    let index = this.count.findIndex((x) => x.id == k);
    if (index === -1) {
      this.count.push({ id: k });
    }
    let reply = !this.commentsListArray[k].is_replied;
    this.commentsListArray.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.commentsListArray[k].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorArticleComments(
        "editor2_" + k
      );
    }
  }
  commentReplyBtnClose(k) {
    this.commentsListArray[k].is_replied = false;
  }

  commentPushBtn(k) {
    let responsJSON = {
      comment: k.id,
      section_id: k.section_id,
      article: this.id,
    };
    this.apiService.pushaddedcomment(responsJSON).subscribe(
      (data: any) => {
        if (data) {
          this.notifyService.showSuccess(data["message"]);
          this.ngOnInit();
          this.commentsList = false;
        }
      },
      (error) => {
        this.error = error;
        this.commentsList = false;
        this.spinner.hide();
      }
    );
  }
  closeButton() {
    let pid = localStorage.getItem("pId");
    $("#articleText").removeClass("p_margin");
    let self = this;
    this.commentForm = false;
    this.menuButton = true;
    this.sideNavMenu = true;
    this.annotationsMenu = false;
    this.commentsList = false;
    this.sourceHide = false;
    this.highlightTab = false;
    this.commentTab = false;
    this.isPublicComment = true;
    this.isPrivateNote = false;
    this.articleCommentsForm.controls["is_private"].setValue(true);
    setTimeout(() => {
      $(".public-comment-comments").removeClass(
        "annotations-sources-main-animation"
      );
      $(".source-Btn").removeClass("annotations-sources-main-animation");
      $(".annotations-btn").removeClass("annotations-sources-main-animation");
      $(".comment-box-main").removeClass("annotations-sources-main-animation");
      self.removeSelectedText();
      this.commentsListArray = [];
    }, 200);
    if (pid) {
      var elmnt = document.getElementById(pid);
      setTimeout(() => {
        elmnt.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "start",
        });
      }, 1000);
    }
    if (pid) {
      var elmnt = document.getElementById(pid);
      setTimeout(() => {
        elmnt.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "nearest",
        });
      }, 1000);
    }
    this.sid = "";
  }
  removeSelectedText() {
    $(".paraHighlight").removeClass("paraHighlight");
    $("#articleText").find("span.bookmark").remove();
    $("#articleText").find("span.source").remove();
    $(".highlighted").removeClass("highlighted");
    $(".selectedText").removeClass("selectedText");
    $(".highlight").removeClass("highlight");
  }
  editComments(i, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x) => x.id == i);
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.privateComment[i].is_replied;
    this.privateComment.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.privateComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic("editor3_" + i);
      if (text) {
        this.articleCommentsReplyForm.controls["articleReply"].setValue(text);
      }
    }
  }
  updatepPublicComments(i, text) {
    this.editcomments = true;
    let index = this.count.findIndex((x) => x.id == i);
    if (index === -1) {
      this.count.push({ id: i });
    }
    let reply = !this.publicComment[i].is_replied;
    this.publicComment.map((x) => {
      x.is_replied = false;
      return x;
    });
    this.publicComment[i].is_replied = reply;
    if (index === -1) {
      this.sharedServiceService.initializeCkeditorDynamic("editor1_" + i);
      if (text) {
        this.articlePublicReplyForm.controls["articleReply"].setValue(text);
      }
    }
  }
  openCommentBox() {
    setTimeout((x) => {
      this.sharedServiceService.initializeCkeditor();
    }, 1000);
    this.commentsList = false;
    this.commentAddedForm = true;
    window.scroll({
      behavior: "smooth",
      top: 0,
      left: 0,
    });
    //   $(".comment-box").css({ top: $('#' + this.ParaSectionId[1]).offset().top + 150 });
  }
  closeCommentButton() {
    this.commentForm = false;
  }
  publishComments() {
    this.spinner.show();
    let commentText;
    if (this.sharedServiceService.editor2.getData()) {
      commentText = this.sharedServiceService.editor2.getData();
    } else {
      commentText = this.articleCommentsForm.controls.articleCommentSmall.value;
    }
    let responseJson = {
      text: commentText,
      owner: this.userId,
      article: this.id,
      highlight: 0,
      section_id: this.ParaSectionId[1],
      permission_status: 0,
      video_link: "",
      video_title: "",
    };
    if (this.sharedServiceService.editor2.getData()) {
      this.apiService.addArticleComments(responseJson).subscribe(
        (data) => {
          if (data) {
            this.sharedServiceService.editor2.setData("");
            this.closeButton();
            this.ngOnInit();
            this.createDynamicbutton();
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    } else {
      this.notifyService.showError("Cannot upload blank document");
      this.spinner.hide();
    }
  }
  openInviteDialog(id) {
    this.dialogId = id;
    const dialogRef = this.dialog.open(InviteFriendsComponent, {
      panelClass: "custom-modalbox",
      data: this.dialogId,
    });
  }
  postCommnet() {
    if (this.publicCommentForm.invalid) {
      return;
    } else {
      let requestedJson = {
        text: this.pForm.comment.value,
        is_private: true,
        is_post: true,
        owner: this.sharedServiceService.loggedUser,
        article: this.selectedAricle.value,
      };
      this.apiService.addArticleComments(requestedJson).subscribe(
        (data) => {
          if (data) {
            this.publicCommentForm.reset();
            this.getArticle(this.id);
            this.ngOnInit();
            this.selectedAricle = [];
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    }
  }
  getBookLink() {
    if (this.dataSource.web_url !== "None") {
      let url: any;
      url = this.dataSource.web_url;
      window.open(url);
    } else {
      this.notifyService.showError("full book url not uploaded");
    }
  }
  getPrivateNote() {
    this.apiService.getAllPrivateNote(this.id).subscribe(
      (data) => {
        if (data) {
          this.privateNote = data;
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }
  deletePrivateNote(id) {
    this.spinner.show();
    if (id) {
      this.apiService.deletePrivateNote(id).subscribe(
        (data: any) => {
          if (data) {
            this.notifyService.showSuccess(data["message"]);
            this.getPrivateNote();
            this.spinner.hide();
          }
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    }
  }
  getPushComment() {}
  getArticleSectionId() {
    if (this.dataSource.id) {
      this.apiService.getSectionId(this.dataSource.id).subscribe(
        (data: any) => {
          this.sectionId = data;
        },
        (error) => {
          this.error = error;
          this.spinner.hide();
        }
      );
    }
  }
  IncreaseFontSize() {
    for (let i = 0; i < this.sectionId.length; i++) {
      let FontSize: any = document.getElementById(this.sectionId[i].id).style
        .fontSize;
      if (!FontSize) {
        FontSize = 1;
      } else {
        FontSize = parseFloat(FontSize);
      }
      document.getElementById(this.sectionId[i].id).style.fontSize =
        FontSize + 0.0625 + "rem";
      if (FontSize == this.maxFont) {
        this.isMaxFont = true;
      } else {
        this.isMinFont = false;
      }
    }
  }
  DecreaseFontSize() {
    for (let i = 0; i < this.sectionId.length; i++) {
      let FontSize: any = document.getElementById(this.sectionId[i].id).style
        .fontSize;
      if (!FontSize) {
        FontSize = 1;
      } else {
        FontSize = parseFloat(FontSize);
      }
      document.getElementById(this.sectionId[i].id).style.fontSize =
        FontSize - 0.0625 + "rem";
      if (FontSize == this.minFont) {
        this.isMinFont = true;
      } else {
        this.isMaxFont = false;
      }
    }
  }
  setLeftSide() {
    for (let i = 0; i < this.sectionId.length; i++) {
      let leftsize = document.getElementById(this.sectionId[i].id);
      $(leftsize).css({ "margin-right": "10px", "text-align": "left" });
    }
  }
  setRighttSide() {
    for (let i = 0; i < this.sectionId.length; i++) {
      let leftsize = document.getElementById(this.sectionId[i].id);
      $(leftsize).css({ "margin-left": "10px", "text-align": "right" });
    }
  }
  onChange1(event) {
    this.page = event;
  }
  zoomPopup() {
    this.isVisibleZoom = true;
  }
  closeZoomPopup() {
    this.isVisibleZoom = false;
  }

  commentLink(id) {
    let selectedComment = this.commentsOfAllArticales.filter((x) => x.id == id);
    this.selectedCommentId = selectedComment[0].id;
  }

  linkToComment(aId, pId, name) {
    let Articlename = name.replace(/ +/g, "");
    if (this.currentArticalName == Articlename) {
      $("#" + pId).addClass("paraHighlight");
      var elmnt = document.getElementById(pId);
      elmnt.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    } else {
      this.router.navigate(["/article", aId, Articlename]);
      this.sharedServiceService.scrollSectionId = pId;
    }
  }

  getAllComments() {
    this.spinner.show();
    this.apiService.getAllArticleComments().subscribe(
      (data) => {
        this.commentsOfAllArticales = [];
        if (data) {
          this.commentsOfAllArticales = data;
          this.spinner.hide();
        }
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  getSearchHubData(query) {
    let fileName = this.groupName;
    fileName = fileName.toLowerCase();
    if (fileName.includes(" ")) {
      fileName = fileName.split(" ").join("_");
    } else if (fileName.includes("-")) {
      fileName = fileName.split("-").join("_");
    }
    this.searchQuery = true;
    this.spinner.show();
    if (query && fileName) {
      let requestJson = {
        query: query,
        file_name: fileName,
        
      };
      
      this.apiService.getSearchHubData(requestJson).subscribe(
        (data: any) => {
          const dialogRef = this.dialog.open(SearchResultPopupComponent, {
            height: "90vh",
            width: "85vw",
            maxWidth: "85vw",
            data: {
              allAnswers: data.answer_list,
              query: query,
              collection: this.groupName,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {});
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.notifyService.showError("No results found");
        }
      );
    } else {
      this.notifyService.showError("No results found");
      this.spinner.hide();
    }
  }

  getGroupName(id) {
    this.spinner.show();
    this.apiService.getGroupIDList(id).subscribe(
      (data: any) => {
        if (data) {
          console.log("==>>> ", data);
          this.groupName = data.name;
        }
        this.spinner.hide();
      },
      (error) => {
        this.error = error;
        this.spinner.hide();
      }
    );
  }

  // genrateDellEImage() {
  //   this.spinner.show();
  //   this.searchQuery = true;
  //   let requestJson = {
  //     prompt: this.imagePrompt,
  //     n: 2,
  //     size: "256x256",
  //   };
  //   this.apiService.dallEImageGenration(requestJson).subscribe(
  //     (data: any) => {
  //       this.genratedImages = data.data;
  //       this.spinner.hide();
  //     },
  //     (error) => {
  //       this.spinner.hide();
  //       this.notifyService.showError("No results found");
  //     }
  //   );
  // }
}
