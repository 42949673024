import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MustMatch } from '../shared/helpers/must-match.validator';
import { PasswordValidation } from '../shared/helpers/password-validator';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public resetPasswordForm: FormGroup = this.formBuilder.group({
        password: ['', Validators.required],
        retypePassword: ['', Validators.required]
    }, {
        validator: PasswordValidation.MatchPassword
    })
    submitted = false;

    constructor(public router: Router, private api: ApiService, private formBuilder: FormBuilder) { }

    get form() {
        return this.resetPasswordForm.controls;
    }

    ngOnInit() {
    }

    onResetPassword() {
        if (this.resetPasswordForm.invalid) {
            return this.submitted = true;
        } else {
            this.submitted = false;
            const requestJson = {
                "password": this.form.password.value,
                "retypePassword": this.form.retypePassword.value
            }
            return
            this.api.resetPassword(requestJson).subscribe((response) => {
                if (!response) {
                    this.router.navigateByUrl('/login');
                } else {
                }
            });
        }
    }
}
